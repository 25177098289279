import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NotFoundText from '../../components/text/NotFound';
import { actions as accountActions } from '../../redux/AccountRedux';
import { actions as productActions } from '../../redux/ProductRedux';
import ProductListItem from '../category/product-list-item';

class ProductListing extends Component {
  constructor(props) {
    super(props);

    this.state = { limit: 16, hasMoreItems: true, init: false, bestSellers: [], pageCount: 0 };
  }

  componentDidMount() {
    this.props.getAccountWishList();
    this.props.getBestSellers({ page: 1, page_size: 16 });
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.bestSellers.length && !state.init) {
      const bestSellers = props.bestSellers.map((item) => item.ProductCode);
      return { bestSellersCode: bestSellers, init: true };
    }
    if (state.bestSellersCode) {
      const bestSellers = [];
      state.bestSellersCode.forEach((code) => {
        const res = props.allProducts.find((product) => product.code === code);
        bestSellers.push(res);
      });
      return { bestSellers };
    }
    if (props.meta.total) {
      return { pageCount: Math.ceil(props.meta.total / 4) };
    }
    return null;
  };

  handlePageClick = (data) => {
    this.props.getBestSellers({ page: data.selected + 1, page_size: 16 });
  };

  render() {
    const { symbol } = this.props;
    const data =
      this.state.bestSellers && this.state.bestSellers.length
        ? this.state.bestSellers
            .filter((row) => row)
            .map((row) => ({
              name: row.name,
              slug: row.slug,
              rating: 5,
              variants: row.images?.map((item) => ({
                images: item.listing_url,
              })),
              discount: row.price.sale_off,
              price: row.price?.selling_price || 0,
              old_price: row.price?.price || 0,
              category: row.category.name,
              unit: row?.unit?.primary,
              code: row.code,
              image: row.image ? row.image.listing_url : '',
              image_name: row.image ? row.image.name : '',
              image_alt: row.image ? row.image.alt : '',
            }))
        : [];

    return (
      <div>
        <div className="product-wrapper-grid">
          <div className="container-fluid">
            {data?.length > 0 ? (
              <InfiniteScroll
                dataLength={this.state.limit} //This is important field to render the next data
                next={this.fetchMoreItems}
                hasMore={this.state.hasMoreItems}
                // loader={<div className="loading-cls" />}
                endMessage={
                  <p className="seen-cls seen-it-cls">
                    <b>Yay! You have seen it all</b>
                  </p>
                }>
                <div className="row">
                  {data.slice(0, this.state.limit).map((product, index) => (
                    <div
                      className={`${
                        this.props.colSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : `col-lg-${this.props.colSize}`
                      }`}
                      key={index}>
                      <ProductListItem product={product} symbol={symbol} key={index} />
                    </div>
                  ))}
                </div>
                <div className={'pagination-box'} style={{ marginTop: '30px' }}>
                  <ReactPaginate
                    previousLabel={'←'}
                    nextLabel={'→'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  />
                </div>
              </InfiniteScroll>
            ) : (
              <NotFoundText />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  allProducts: state.productReducer.allProducts,
  bestSellers: state.productReducer.bestSellers.list,
  meta: state.productReducer.bestSellers.meta,
});
const mapDispatchToProps = (dispatch) => ({
  getAccountWishList: () => dispatch(accountActions.getAccountWishList()),
  getBestSellers: (params) => dispatch(productActions.getBestSellers(params)),
  clearBestSellers: () => dispatch(productActions.clearBestSellers()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductListing));
