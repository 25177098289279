import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import UniverseCookies from '../utils/cookies';
import { actions as accountActions } from './AccountRedux';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { API_CALLING, API_CALLED_SUCCESS, API_CALLED_FAILURE, CART } = PREFIX;
const _types = typesWithPrefix(CART);
const types = {
  GET_ITEMS_IN_CART: _types('GET_ITEMS_IN_CART'),
  ADD_ITEM_TO_CART: _types('ADD_ITEM_TO_CART'),
  DELETE_ITEM_IN_CART: _types('DELETE_ITEM_IN_CART'),
  CHECKOUT_CART: _types('CHECKOUT_CART'),
  ADD_NOTE_FOR_CART: _types('ADD_NOTE_FOR_CART'),
  GET_VOUCHERS: _types('GET_VOUCHERS'),
  APPLY_VOUCHER: _types('APPLY_VOUCHER'),
};

export const actions = {
  //GET CART
  getItemsInCart: () => async (dispatch) => {
    const token = UniverseCookies.getToken();
    if (!token) {
      return;
    }
    const api = API_URLS.CART.getItemsInCart();
    dispatch(actions.gettingItemsInCart);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      dispatch(
        actions.getItemsInCartSuccess({
          order_code: data?.order_number,
          order_items: data?.order_items,
          note: data?.note,
          payment_amount: data?.payment_amount,
          total_amount: data?.total_amount,
          total_sale_off: data?.total_sale_off,
          station_code: data?.customer_information.station_code,
          shipping_address: data?.shipping_address,
          gift_code: data?.gift_code,
          gift_product: data?.selected_gift_product_code,
        }),
      );
    } else {
      dispatch(actions.getItemsInCartFailure({}));
    }
  },
  gettingItemsInCart: () => ({
    type: types.GET_ITEMS_IN_CART,
    meta: { prefix: [CART, API_CALLING] },
  }),
  getItemsInCartSuccess: (payload) => ({
    type: types.GET_ITEMS_IN_CART,
    payload,
    meta: {
      prefix: [CART, API_CALLED_SUCCESS],
    },
  }),
  getItemsInCartFailure: (payload) => ({
    type: types.GET_ITEMS_IN_CART,
    payload,
    meta: {
      prefix: [CART, API_CALLED_FAILURE],
    },
  }),

  //ADD ITEM TO CART
  addingItemToCart: () => ({
    type: types.ADD_ITEM_TO_CART,
    meta: { prefix: [CART, API_CALLING] },
  }),
  addItemToCartSuccess: (payload) => ({
    type: types.ADD_ITEM_TO_CART,
    payload,
    meta: { prefix: [CART, API_CALLED_SUCCESS] },
  }),

  addItemToCartFailure: () => ({
    type: types.ADD_ITEM_TO_CART,
    meta: { prefix: [CART, API_CALLED_FAILURE] },
  }),

  addItemToCart: (payload, meta) => async (dispatch) => {
    const api = API_URLS.CART.addItemToCart(payload);
    dispatch(actions.addingItemToCart());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data && response.data.data) {
      dispatch(actions.addItemToCartSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
      dispatch(actions.getItemsInCart());
    } else {
      dispatch(actions.addItemToCartFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
    return { response, error };
  },

  //DELETE ITEM IN CART
  deletingItemToCart: () => ({
    type: types.DELETE_ITEM_IN_CART,
    meta: { prefix: [CART, API_CALLING] },
  }),
  deleteItemInCartSuccess: (payload) => ({
    type: types.DELETE_ITEM_IN_CART,
    payload,
    meta: { prefix: [CART, API_CALLED_SUCCESS] },
  }),

  deleteItemInCartFailure: () => ({
    type: types.DELETE_ITEM_IN_CART,
    meta: { prefix: [CART, API_CALLED_FAILURE] },
  }),

  deleteItemInCart: (productCode) => async (dispatch) => {
    const api = API_URLS.CART.deleteItemInCart(productCode);
    dispatch(actions.deletingItemToCart());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.deleteItemInCartSuccess(response.data));
      dispatch(actions.getItemsInCart());
    } else {
      dispatch(actions.deleteItemInCartSuccess(error));
    }
    return { response, error };
  },

  //CHECK OUT CART
  checkoutingCart: () => ({
    type: types.CHECKOUT_CART,
    meta: { prefix: [CART, API_CALLING] },
  }),
  checkoutCartSuccess: (payload) => ({
    type: types.CHECKOUT_CART,
    payload,
    meta: { prefix: [CART, API_CALLED_SUCCESS] },
  }),

  checkoutCartFailure: () => ({
    type: types.CHECKOUT_CART,
    meta: { prefix: [CART, API_CALLED_FAILURE] },
  }),

  checkoutCart: (meta) => async (dispatch) => {
    const api = API_URLS.CART.checkoutCart();
    dispatch(actions.checkoutingCart());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.checkoutCartSuccess(response.data));
      dispatch(accountActions.getAccountOrderHistory({ page: 1, page_size: 4 }));
      dispatch(actions.getItemsInCart());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.checkoutCartFailure(error));
      if (meta && meta.onError) {
        meta.onError();
      }
    }
    return { response, error };
  },

  //ADD NOTE
  addingNoteForCart: () => ({
    type: types.ADD_NOTE_FOR_CART,
    meta: { prefix: [CART, API_CALLING] },
  }),
  addNoteForCartSuccess: (payload) => ({
    type: types.ADD_NOTE_FOR_CART,
    payload,
    meta: { prefix: [CART, API_CALLED_SUCCESS] },
  }),

  addNoteForCartFailure: () => ({
    type: types.ADD_NOTE_FOR_CART,
    meta: { prefix: [CART, API_CALLED_FAILURE] },
  }),

  addNoteForCart: (payload) => async (dispatch) => {
    const api = API_URLS.CART.addNoteForCart(payload);
    dispatch(actions.addingNoteForCart());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.addNoteForCartSuccess(response.data));
      dispatch(actions.getItemsInCart());
    } else {
      dispatch(actions.addNoteForCartFailure(error));
    }
    return { response, error };
  },

  //GET VOUCHERS
  getVouchers: () => async (dispatch) => {
    const api = API_URLS.CART.getVouchers();
    dispatch(actions.gettingVouchers);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      dispatch(actions.getVouchersSuccess(data));
    } else {
      dispatch(actions.getVouchersFailure({}));
    }
  },
  gettingVouchers: () => ({
    type: types.GET_VOUCHERS,
    meta: { prefix: [CART, API_CALLING] },
  }),
  getVouchersSuccess: (payload) => ({
    type: types.GET_VOUCHERS,
    payload,
    meta: {
      prefix: [CART, API_CALLED_SUCCESS],
    },
  }),
  getVouchersFailure: (payload) => ({
    type: types.GET_VOUCHERS,
    payload,
    meta: {
      prefix: [CART, API_CALLED_FAILURE],
    },
  }),

  //APPLY VOUCHER
  applyingVoucher: () => ({
    type: types.APPLY_VOUCHER,
    meta: { prefix: [CART, API_CALLING] },
  }),
  applyVoucherSuccess: (payload) => ({
    type: types.APPLY_VOUCHER,
    payload,
    meta: { prefix: [CART, API_CALLED_SUCCESS] },
  }),

  applyVoucherFailure: () => ({
    type: types.APPLY_VOUCHER,
    meta: { prefix: [CART, API_CALLED_FAILURE] },
  }),

  applyVoucher: (payload, meta) => async (dispatch) => {
    const api = API_URLS.CART.applyVoucher(payload);
    dispatch(actions.applyingVoucher());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.applyVoucherSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
      dispatch(actions.getItemsInCart());
    } else {
      dispatch(actions.applyVoucherFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
    return { response, error };
  },
};

const initialState = {
  order_code: '',
  shipping_address: '',
  items: [],
  vouchers: [],
  payment_amount: 0,
  total_amount: 0,
  total_sale_off: 0,
  note: '',
  station_code: '',
  gift_code: '',
  gift_product: '',
  isFetching: false,
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_ITEMS_IN_CART:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const {
            order_code,
            order_items,
            note,
            total_sale_off,
            total_amount,
            payment_amount,
            station_code,
            shipping_address,
            gift_code,
            gift_product,
          } = action.payload;
          // log('CartRedux', 'reducer', 'data', data);
          draft.order_code = order_code;
          draft.items = order_items;
          draft.note = note;
          draft.total_amount = total_amount;
          draft.payment_amount = payment_amount;
          draft.total_sale_off = total_sale_off;
          draft.station_code = station_code;
          draft.shipping_address = shipping_address;
          draft.gift_code = gift_code;
          draft.gift_product = gift_product;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.order_code = '';
          draft.items = [];
          draft.isFetching = false;
        }
        break;

      case types.ADD_ITEM_TO_CART:
      case types.DELETE_ITEM_IN_CART:
      case types.CHECKOUT_CART:
      case types.ADD_NOTE_FOR_CART:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        }
        if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
        }
        break;

      case types.GET_VOUCHERS:
        if (isCallingApi(action)) {
          draft.isFetchingVouchers = true;
        } else if (isSuccessfulApiCall(action)) {
          const data = action.payload;
          draft.vouchers = data ? data : [];
          draft.isFetchingVouchers = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetchingVouchers = false;
        }
        break;
      default:
        return draft;
    }
  });
