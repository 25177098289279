// import { Range } from 'rc-slider';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
// import ReactPaginate from 'react-paginate';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import '../category/tree.css';
// import { actions as categoryActions } from '../../redux/CategoriesRedux';
// import { actions as productActions} from '../../redux/ProductRedux';
import CategoryTree from '../category/tree';
import ProductListing from './product-listing';

import 'rc-slider/assets/index.css';
import '../../index.scss';
class PromotionPage extends Component {
  state = {
    layoutColumns: 3,
  };

  openFilter = () => {
    document.querySelector('.collection-filter').style = 'left: -15px';
  };

  closeFilter = () => {
    document.querySelector('.collection-filter').style = 'left: -370px';
  };

  onSelecting = (props) => {
    const categoryCode = props.key.split('/');
    this.props.history.push(`/category/${categoryCode[categoryCode.length - 1]}`);
  };

  render() {
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>Trạm Xanh | Danh sách sản phẩm</title>
          <meta name="description" content="Trạm Xanh - Vật tư nông nghiệp" />
        </Helmet>
        {/*SEO Support End */}

        {/* <Breadcrumb title={'Collection'} /> */}

        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-sm-3 collection-filter">
                  <StickyBox offsetTop={20} offsetBottom={20} style={{ padding: '0 20px' }}>
                    <div className="font-category-tree-header" style={{ marginBottom: '15px' }}>
                      Danh mục sản phẩm
                    </div>
                    <div>
                      <CategoryTree onSelect={this.onSelecting} match={this.props.match} />
                    </div>
                    <a className="close-sidebar-filter" onClick={this.closeFilter} style={{ top: '-40px' }}>
                      <i className="fa fa-close fa-2x" aria-hidden="true" style={{ fontSize: 30 }} />
                    </a>
                  </StickyBox>
                </div>
                <div className="collection-content col">
                  <div className="page-main-content ">
                    <div className="">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="top-banner-wrapper">
                            <div className="font-category-tree-header" style={{ marginBottom: '15px' }}>
                              Sản phẩm ưu đãi
                            </div>
                            <img src="/assets/images/SlideShow.png" className="img-fluid" alt="" />
                          </div>
                          <div className="collection-product-wrapper">
                            <div className="product-top-filter">
                              <div className="container-fluid p-0">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="filter-main-btn">
                                      <span onClick={this.openFilter} className="filter-btn btn btn-theme">
                                        <i className="fa fa-filter" aria-hidden="true" /> Bộ lọc
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ProductListing colSize={this.state.layoutColumns} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(PromotionPage);
