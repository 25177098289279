import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { API_CALLING, API_CALLED_SUCCESS, API_CALLED_FAILURE, SUPPLIER } = PREFIX;
const _types = typesWithPrefix(SUPPLIER);
const TYPES = {
  GET_SUPPLIERS: _types('GET_SUPPLIERS'),
};

export const actions = {
  getSuppliers: () => async (dispatch) => {
    const api = API_URLS.SUPPLIER.getSuppliers();
    dispatch(actions.gettingSuppliers());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      if (data !== null) {
        dispatch(actions.getSuppliersSuccess(data));
      }
    } else {
      dispatch(actions.getSuppliersFailure({}));
    }
  },
  gettingSuppliers: () => ({
    type: TYPES.GET_SUPPLIERS,
    meta: { prefix: [SUPPLIER, API_CALLING] },
  }),
  getSuppliersSuccess: (payload) => ({
    type: TYPES.GET_SUPPLIERS,
    payload,
    meta: {
      prefix: [SUPPLIER, API_CALLED_SUCCESS],
    },
  }),
  getSuppliersFailure: (payload) => ({
    type: TYPES.GET_SUPPLIERS,
    payload,
    meta: {
      prefix: [SUPPLIER, API_CALLED_FAILURE],
    },
  }),
};

const initialState = {
  suppliers: [],
  isFetching: false,
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPES.GET_SUPPLIERS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const data = action.payload;
          draft.suppliers = data;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.suppliers = [];
          draft.isFetching = false;
        }
        break;
      default:
        return draft;
    }
  });
