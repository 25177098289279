import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Notification extends Component {
  state = {};

  renderNotiImage = () => {
    const { notification, userId } = this.props;
    const notificationReadStatus = notification?.recipient_information?.find(item => item.recipient_id === userId);
    switch (notification.type){
      case 'promotion':
        if(notificationReadStatus?.read_status === 'UnRead') {
          return <img style={{ height: '50px' }} src="/assets/images/notification/UnreadPromotion.png" />
        } else  return <img style={{ height: '50px' }} src="/assets/images/notification/Promotion.png" />;
      case 'order':
          if(notificationReadStatus?.read_status === 'UnRead') {
            return <img style={{ height: '50px' }} src="/assets/images/notification/UnreadNotification.png" />
          } else  return <img style={{ height: '50px' }} src="/assets/images/notification/Notification.png" />
      default: 
        return <img style={{ height: '50px' }} src="/assets/images/notification/Notification.png" />;
    }
  }

  goToScreen = () => {
    const { notification } = this.props;
    switch (notification.screen_to) {
      case 'home': 
        return "/";
      case 'promotion': 
        return "/promotion";
      case 'category': 
        return `/category/${notification.data_code}`;
      case 'product': 
        return `/detail-product/${notification.data_code}`;
      case 'post': 
        return `/detail-post/${notification.data_code}`;
      case 'order': 
        return `/detail-order/${notification.data_code}`;
      default:
        return '/account/notification';
    }
  }

  render() {
    const { notification, userId, setNotiReadStatus } = this.props;
    const notificationReadStatus = notification?.recipient_information?.find(item => item.recipient_id === userId);
    return (
      <div>
        <div className="row" style={{ marginBottom: '30px' }}>
          <div className="col-sm-1">
            {this.renderNotiImage()}
          </div>
          <div style={{ textAlign: 'left' }} className="col-sm-9">
            <Link 
              to={this.goToScreen()}
              onClick={() => {
                if (notificationReadStatus?.read_status === "UnRead"){
                  setNotiReadStatus(notification.code);
                }
              }}>
              <div 
                className="font-below-category"
                style={{ 
                  marginBottom: '10px',
                  color: notificationReadStatus?.read_status === 'UnRead' ? '#363636' : '#717171',
                  fontWeight: 'bold' }}>
                {notification.title}
              </div>
            </Link>
            <div style={{ color: notificationReadStatus?.read_status === 'UnRead' ? '#363636' : '#717171' }}>
              {notification.content}
              {/* <Link to="/detail-notification"> Chi tiết </Link> */}
            </div>
          </div>
          <div className="col-sm-2" style={{ display: 'block' }}>
            <div style={{ height: '15px' }}>
              <p style={{  position: 'absolute', marginLeft: '40px', color: notificationReadStatus?.read_status === 'UnRead' ? '#363636' : '#717171' }}>
                {moment(notification.sending_time).format('DD-MM-YY/HH:mm')}
              </p>
            </div>
            <div style={{ display: 'inline-flex', marginBottom: '8px' }}>
              <button 
                className="btn info" 
                style={{ 
                  position: 'absolute',
                  color: notificationReadStatus?.read_status === 'UnRead' ? '' : '#717171',
                  cursor: notificationReadStatus?.read_status === 'UnRead' ? '' : 'not-allowed',
                }}
                disabled={notificationReadStatus?.read_status === 'UnRead' ? false : true}
                onClick={() => setNotiReadStatus(notification.code)}> 
                  Đánh dấu đã đọc 
              </button>
              {/* <button className="btn danger" style={{  position: 'absolute', marginLeft: '180px' }}> Xoá </button> */}
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

export default Notification;
