import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../constants/colors';
import { slugPath } from '../../../utils/product';

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});
const button1 = {
  height: '30px',
  background: 'red',
  borderRadius: '4px',
  marginLeft: '10px',
  width: '80px',
  color: 'white',
  textTransform: 'uppercase',
};
const button2 = {
  height: '30px',
  background: COLORS.primary,
  borderRadius: '4px',
  cursor: 'not-allowed',
};
const input = {
  height: '30px',
  textAlign: 'center',
  width: '70%',
  background: '#D9F2E5',
  border: 'none',
};
const group = {
  justifyContent: 'unset',
  marginTop: '10px',
};
const icon = {
  marginRight: '5px',
  color: 'white',
};
// const margin = {
//   marginTop: '10px',
//   marginBottom: '10px',
// };
// const total = {
//   opacity: 'unset',
//   marginTop: '30px',
//   marginLeft: '20px',
// };
class OrderProduct extends Component {
  state = {};
  render() {
    const { products } = this.props;

    return (
      <div className="border-box">
        {products?.map((item, index) =>
          item.state !== 'cancelled' ? (
            <div
              key={`${index}-${item.code}`}
              style={{ display: 'flex', padding: '10px 0', borderBottom: '1px solid #e3e3e3' }}>
              <div className="col-3" style={{ paddingRight: 0 }}>
                <Link to={`/detail-product/${slugPath(item)}`}>
                  <img className="img-fluid" src={item.pictures[0]} />
                </Link>
              </div>
              <div className="col-9">
                <div className="font-product margin-bottom-10px">
                  <Link to={`/detail-product/${slugPath(item)}`} className="font-product">
                    {item.name}
                  </Link>
                </div>
                <div className="font-category-of-product margin-bottom-10px"> {item.category}</div>
                <div className="font-price"> {formatter.format(item.price)}</div>
                <div className="qty-box">
                  <div className="input-group" style={group}>
                    <div style={{ display: 'flex' }}>
                      <button style={button2} disabled>
                        <i className="fa fa-minus" style={icon} />
                      </button>
                      <input type="text" name="quantity" value={item.qty} readOnly style={input} />
                      <button style={button2} disabled>
                        <i className="fa fa-plus" style={icon} />
                      </button>
                      <button
                        style={{ ...button1, cursor: this.props.state === 'processing' ? '' : 'not-allowed' }}
                        onClick={() => this.props.removeOrderItem(item.code)}
                        disabled={this.props.state !== 'processing'}>
                        {'Hủy'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null,
        )}
      </div>
    );
  }
}

export default OrderProduct;
