// import { Range } from 'rc-slider';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
// import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import './tree.css';
// import Breadcrumb from '../common/breadcrumb';
import { actions as categoryActions } from '../../redux/CategoriesRedux';
import { actions as productActions } from '../../redux/ProductRedux';
import { codeFromSlug } from '../../utils/product';
import Supplier from '../category/supplier';
import CategoryTree from '../category/tree';
import ProductListing from './product-listing';
// import FilterBar from './common/filter-bar';
import 'rc-slider/assets/index.css';
import '../../index.scss';
// const formatter = new Intl.NumberFormat('vi-VN', {
//   style: 'currency',
//   currency: 'VND',
//   minimumFractionDigits: 0,
// });
class Category extends Component {
  state = {
    layoutColumns: 3,
    value: [0, 748000],
    codeOfCategory: '',
    codeOfSupplier: '',
    curCodeOfCategory: '',
    web_banner: '',
    cat_name: '',
    seo_title: '',
    isMobileFilterOpened: false,
  };

  static getDerivedStateFromProps = (nextProps) => {
    if (nextProps.match.params.code && nextProps.categories?.length) {
      const categoryCode = codeFromSlug(nextProps.match.params.code);
      const curCategory = nextProps.categories[categoryCode];
      return {
        codeOfCategory: categoryCode,
        web_banner: curCategory?.web_banner,
        cat_name: curCategory?.name,
        seo_title: curCategory?.seo_title || curCategory?.name,
      };
    }

    return null;
  };

  componentDidMount() {
    this.props.getCategories();
  }

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  openFilter = () => {
    document.querySelector('.collection-filter').style = 'left: -15px';
    this.setState({ isMobileFilterOpened: true });
  };

  closeFilter = () => {
    if (this.state.isMobileFilterOpened) {
      document.querySelector('.collection-filter').style = 'left: -370px';
      this.setState({ isMobileFilterOpened: false });
    }
  };

  onSliderChange = (value) => {
    this.setState({
      value,
    });
  };

  onSelectCategory = (props) => {
    this.closeFilter();
    this.setState({ codeOfSupplier: '' });
    const categoryCode = props.key.split('/');
    this.props.history.push(`/category/${categoryCode[categoryCode.length - 1]}`);
  };

  onSelectSupplier = (supplierCode) => {
    this.closeFilter();
    this.setState({ codeOfSupplier: supplierCode });
  };

  // onSelectSupplier = (supplierCode) => {
  //   if (this.state.codeOfCategory) {
  //     this.props.history.push(`/search?s=&category=${this.state.codeOfCategory}&supplier=${supplierCode}`);
  //   } else this.props.history.push(`/search?s=&supplier=${supplierCode}`);
  //   this.setState({ codeOfSupplier: supplierCode });
  //   this.closeFilter();
  // }

  render() {
    const { seo_title, web_banner } = this.state;
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>Trạm Xanh | {seo_title || 'Danh sách sản phẩm'}</title>
          <meta name="description" content={`Trạm Xanh - ${seo_title || 'Vật tư nông nghiệp'}`} />
          <meta name="og:title" content={`Trạm Xanh | ${seo_title || 'Danh sách sản phẩm'}`} />
          <meta name="og:description" content={`Trạm Xanh - ${seo_title || 'Vật tư nông nghiệp'}`} />
          {web_banner ? <meta property="og:image" content={web_banner} /> : null}
        </Helmet>
        {/*SEO Support End */}

        {/* <Breadcrumb title={'Collection'} /> */}

        <section className="section-b-space">
          <div className="collection-wrapper" style={{ marginBottom: '70px' }}>
            <div className="container">
              <div className="row">
                <div className="col-sm-3 collection-filter">
                  <StickyBox offsetTop={20} offsetBottom={20} style={{ padding: '0 20px' }}>
                    <div className="font-category-tree-header" style={{ marginBottom: '15px' }}>
                      Danh mục sản phẩm
                    </div>
                    <div>
                      <CategoryTree onSelect={this.onSelectCategory} match={this.props.match} />
                      {/* <NewProduct /> */}
                      {/* <div className="collection-sidebar-banner">
                        <a href="#">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/2.png`} className="img-fluid" alt="" />
                        </a>
                      </div> */}
                    </div>
                    {/*side-bar banner end here*/}
                    {/* <div className="font-category-tree-header" style={{ marginBottom: '15px', marginTop: '50px' }}>
                      Theo giá tiền
                    </div>
                    <div className="font-range" style={{ marginBottom: '30px' }}>
                      {formatter.format(this.state.value[0])} đến {formatter.format(this.state.value[1])}
                    </div>
                    <Range
                      onChange={this.onSliderChange}
                      defaultValue={this.state.value}
                      step={10000}
                      max={1000000}
                      trackStyle={[{ backgroundColor: 'red' }]}
                    />
                    <div>
                      <button className="filter-button1"> Lọc theo giá </button>
                      <button className="filter-button2"> Bỏ lọc</button>
                    </div> */}

                    <div className="font-category-tree-header" style={{ marginBottom: '10px', marginTop: '20px' }}>
                      Theo nhà cung cấp
                    </div>
                    <Supplier onSelectSupplier={this.onSelectSupplier} selectedSupplier={this.state.codeOfSupplier} />
                    <a className="close-sidebar-filter" onClick={this.closeFilter} style={{ top: '-40px' }}>
                      <i className="fa fa-close fa-2x" aria-hidden="true" style={{ fontSize: 30 }} />
                    </a>
                  </StickyBox>
                </div>
                <div className="collection-content col">
                  <div className="page-main-content ">
                    <div className="">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="top-banner-wrapper">
                            {!web_banner ? (
                              <div className="font-category-tree-header" style={{ marginBottom: '15px' }}>
                                <h1>{seo_title}</h1>
                              </div>
                            ) : (
                              <>
                                <img src={web_banner} className="img-fluid" alt="" />
                                <h1 style={{ display: 'none' }}>{seo_title}</h1>
                              </>
                            )}
                          </div>
                          <div className="collection-product-wrapper">
                            <div className="product-top-filter">
                              <div className="container-fluid p-0">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="filter-main-btn">
                                      <span onClick={this.openFilter} className="filter-btn btn btn-theme">
                                        <i className="fa fa-filter" aria-hidden="true" /> Bộ lọc
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    {/* <FilterBar onLayoutViewClicked={colmuns => this.LayoutViewClicked(colmuns)} /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <ProductListing
                                colSize={this.state.layoutColumns}
                                codeOfCategory={this.state.codeOfCategory}
                                codeOfSupplier={this.state.codeOfSupplier}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  // categories: state.categoriesReducer.categories,
  categories: state.categoriesReducer.flattenCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: () => {
    dispatch(categoryActions.getCategories());
  },
  getAllProducts: () => {
    dispatch(productActions.getAllProducts());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Category));
