// import Tree from '@naisutech/react-tree';
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import { ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TreeMenu from 'react-simple-tree-menu';

import '../../../node_modules/react-simple-tree-menu/dist/main.css';

import { actions } from '../../redux/CategoriesRedux';
import { getUrlString } from '../../utils/browserWindow';
import './tree.css';

// import './styles.css';
// const Item = { TreeMenu };
const DEFAULT_PADDING = 0.5;
const ICON_SIZE = 1;
const LEVEL_SPACE = 1.75;

const ToggleContent2 = ({ on, hasNodes, onClickItem }) => (
  <div className="rstm-toggle-icon">
    {hasNodes ? (
      <div role="img" aria-label="Toggle" className="rstm-toggle-icon-symbol" onClick={onClickItem}>
        {on ? '-' : '+'}
      </div>
    ) : null}
  </div>
);

let catCodeTree = [];
class CategoryTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openFilter: false,
      codeOfCategory: '',
      openNodes: [],
      catCodeTree: [],
      init: false,
      catCodeTreeString: '',
    };
  }
  // var codeOfCategory ;

  static getDerivedStateFromProps = (props) => {
    if (props.categories?.length) {
      const urlString = getUrlString(props.location);
      const url = new URL(urlString);
      const catCode = url.searchParams.get('category') ? url.searchParams.get('category') : 'all';
      catCodeTree = [];
      const catCodeTree1 = CategoryTree.findTreeCode(catCode, props.flattenCategories);
      const string = catCodeTree1.join('/');
      return { catCodeTreeString: string };
    }
    return null;
  };

  componentDidMount() {
    // const urlString = window.location.href;
    // const url = new URL(urlString);
    // const catCodeTreeString = url.searchParams.get('category') ? url.searchParams.get('category') : 'all';
    // this.setState({ catCodeTreeString });
  }

  static findTreeCode = (code, list) => {
    catCodeTree.unshift(code);
    if (list.length) {
      const catCode = list[code];
      if (catCode?.parent_code) {
        this.findTreeCode(catCode?.parent_code, list);
      }
    }
    return catCodeTree;
  };

  convertCategoryData = (data, parentId = null) => {
    const newCat = {
      label: data.name,
      key: data.code,
      parentId,
      web_banner: data.web_banner ? data.web_banner : '',
    };
    if (data.children && data.children.length) {
      newCat.nodes = data.children.map((item) => this.convertCategoryData(item, data.id));
      return newCat;
    } else {
      return newCat;
    }
  };

  onClickItem = (cat) => {
    const currentOpenNodes = this.state.openNodes;
    const key = cat.key;
    if (!currentOpenNodes.includes(key)) {
      currentOpenNodes.push(key);
    }
    this.setState({ openNodes: currentOpenNodes });
    this.props.onSelect(cat);
  };

  toggleNode = (cat) => {
    let currentOpenNodes = this.state.openNodes;
    const key = cat.key;
    if (!currentOpenNodes.includes(key)) {
      currentOpenNodes.push(key);
    } else {
      currentOpenNodes = currentOpenNodes.filter((item) => item !== key);
    }
    this.setState({ openNodes: currentOpenNodes });
  };

  render() {
    const ListItem = ({ level = 0, hasNodes, isOpen, label, openNodes, catKey, ...props }) => (
      <ListGroupItem
        {...props}
        style={{
          paddingLeft: `${DEFAULT_PADDING + ICON_SIZE * (hasNodes ? 0 : 1) + level * LEVEL_SPACE}rem`,
          cursor: 'pointer',
          textTransform: level === 0 ? 'uppercase' : 'none',
        }}>
        {hasNodes && (
          <ToggleContent2
            on={isOpen}
            hasNodes={hasNodes}
            onClickItem={(e) => {
              e.stopPropagation();
              this.toggleNode({ key: catKey, openNodes });
            }}
          />
        )}
        {label}
      </ListGroupItem>
    );

    const allObj = {
      code: 'all',
      name: 'Tất cả',
    };
    const cate = [...this.props.categories];
    cate.unshift(allObj);
    const categori = cate?.map((row) => this.convertCategoryData(row));

    return (
      <div style={{ display: 'flex', flexWrap: 'nowrap', flexGrow: 1 }}>
        <div id="tree-menu" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <TreeMenu
            openNodes={this.state.openNodes}
            cacheSearch
            data={categori}
            hasSearch={false}
            onClickItem={this.onClickItem}
            debounceTime={125}
            activeKey={this.props.resetCatTree ? 'none' : this.state.catCodeTreeString}
            // resetOpenNodesOnDataUpdate={false}
            disableKeyboard={false}>
            {({ items }) => (
              <>
                {items.map(({ key, ...props }) => (
                  <ListItem key={key} catKey={key} {...props} />
                ))}
              </>
            )}
          </TreeMenu>

          {/* <Tree nodes={categori} theme={'my-theme'} customTheme={myTheme} iconSet={{ file: 'none' }} onSelect={this.props.onSelect} codeOfCategory={this.state.codeOfCategory} /> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  categories: state.categoriesReducer.categories,
  flattenCategories: state.categoriesReducer.flattenCategories,
});
const mapDispatchToProps = (dispatch) => ({
  getCategories: () => {
    dispatch(actions.getCategories());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoryTree));
