import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { Link, withRouter } from 'react-router-dom';

import { COLORS } from '../../../constants/colors';
import { ORDER_STATUS } from '../../../constants/enum';
import { actions as orderActions } from '../../../redux/OrderRedux';
import { slugPath } from '../../../utils/product';
import OrderProduct from './order-product';
import './orderPage.css';

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});
class DetailOrder extends Component {
  state = { open: false };

  componentDidMount = () => {
    const orderCode = this.props.match.params.code;
    this.props.getOrderDetail(orderCode);
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  cancelOrder = () => {
    this.props.cancelOrder(this.props.order.order_number, {
      onSuccess: () => {
        NotificationManager.success('Hủy đơn hàng thành công!', 'Thành công', 2000);
        this.props.history.push('/account/history');
      },
      onError: () => {
        NotificationManager.error('Hủy đơn hàng thất bại!', 'Thất bại', 2000);
      },
    });
  };

  showOrderState = (state) => {
    switch (state) {
      case 'processing':
        return 'Đang xử lý';
      case 'completed':
        return 'Đã hoàn thành';
      case 'returned':
        return 'Trả lại';
      case 'cancelled':
        return 'Đã hủy';
      default:
        return state;
    }
  };

  removeOrderItem = (productCode) => {
    const payload = {
      order_code: this.props.order.order_number,
      product_code: productCode,
    };
    this.props.cancelItemInOrder(payload, {
      onSuccess: () => {
        NotificationManager.success('Loại bỏ sản phẩm khỏi đơn hàng thành công!', 'Thành công', 2000);
      },
      onError: () => {
        NotificationManager.error('Loại bỏ sản phẩm khỏi đơn hàng thất bại!', 'Thất bại', 2000);
      },
    });
  };

  render() {
    const { order, allProducts } = this.props;
    const cartList = order?.order_items?.map((item) => ({
      id: item?.product?.code,
      code: item?.product?.code,
      name: item?.product?.name,
      pictures: [item?.product?.image?.thumbnail_url],
      qty: item?.quantity,
      price: item?.product?.price?.selling_price,
      category: item?.product?.category?.name,
      state: item.state,
    }));
    const giftProduct = allProducts.find((item) => item.code === order?.selected_gift_product_code);

    return (
      <div className="container" style={{ maxWidth: 1200 }}>
        <div className="row" style={{ marginBottom: '50px' }}>
          <div className="col-12 col-md-8 cart-left">
            <div
              style={{
                border: '1px solid #E3E3E3',
                width: '100%',
                borderRadius: '4px 4px 0px 0px',
                paddingBottom: 10,
              }}>
              <div
                style={{
                  border: '1px solid #E3E3E3',
                  width: '100%',
                  height: '40px',
                  background: COLORS.primary,
                  borderRadius: '4px 4px 0px 0px',
                }}>
                <div className="font-product" style={{ color: 'white', marginTop: '10px', marginLeft: '15px' }}>
                  Mã đơn hàng: {order?.order_number}
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div className="col-4 col-sm-3 font-product ">Trạng thái</div>
                <div className="col-8 col-sm-9">
                  <div>
                    {/* {this.showOrderState(order?.order_state)} */}
                    {ORDER_STATUS[order?.order_state]}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div className="col-4 col-sm-3 font-product ">Địa điểm nhận</div>
                <div className="col-8 col-sm-9">
                  <div className="font-product ">{order?.shipping_address}</div>
                  {/* <div>{orderStation?.address?.text}, {orderStation?.address?.district?.name}, {orderStation?.address?.city?.name}</div> */}
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div className="col-4 col-sm-3 font-product ">Số điện thoại</div>
                <div className="col-8 col-sm-9">
                  <div>{order?.customer_information?.phone}</div>
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div className="col-4 col-sm-3 font-product ">Ghi chú</div>
                <div className="col-8 col-sm-9">
                  <div>{order?.note ? order?.note : 'Không có ghi chú'}</div>
                </div>
              </div>
            </div>
            {/* <div className="warning-theme">
              <img
                src="/assets/images/NotAvailable.png"
                style={{
                  width: '40px',
                  height: '40px',
                  marginTop: '8px',
                  marginLeft: '20px',
                }}
              />
              <div className="center1" style={{ marginLeft: '20px' }}>
                {' '}
                Đơn hàng có mặt hàng không có sẵn tại trạm
              </div>
            </div> */}
            <div style={{ marginTop: '20px' }}>
              <OrderProduct products={cartList} removeOrderItem={this.removeOrderItem} state={order?.order_state} />
            </div>
          </div>
          <div className="col-12 col-md-4 cart-right">
            <div className="border-box">
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div className="col-5"> Tiền hàng:</div>
                <div className="col-7 font-product text-right" style={{ color: '#ED1C24' }}>
                  {formatter.format(order?.total_amount)}
                </div>
              </div>
              {giftProduct ? (
                <div style={{ display: 'flex', marginTop: '10px' }}>
                  <div className="col-5">Tặng sản phẩm:</div>
                  <Link
                    to={`/detail-product/${slugPath(giftProduct)}`}
                    className="col-7 font-product"
                    style={{ color: '#D1871B', textAlign: 'right' }}>
                    {giftProduct?.name}
                  </Link>
                </div>
              ) : (
                <div style={{ display: 'flex', marginTop: '10px' }}>
                  <div className="col-7">Khuyến mại:</div>
                  <div className="col-5 font-product" style={{ color: '#D1871B', textAlign: 'right' }}>
                    {formatter.format(order?.total_sale_off || 0)}
                  </div>
                </div>
              )}
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div className="col-sm-7">Phí ship:</div>
                <div className="col-sm-5 font-product text-right" style={{ color: COLORS.primary }}>
                  {formatter.format(order?.shipping_fee || 0)}
                </div>
              </div>
              <div style={{ border: '1px solid #F2F2F2', width: '100%', marginTop: '10px', marginBottom: '10px' }} />
              <div style={{ display: 'flex', marginBottom: '10px' }}>
                <div className="col-5" style={{ lineHeight: '29px' }}>
                  {' '}
                  Tổng thanh toán:
                </div>
                <div className="col-7 font-price text-right">{formatter.format(order?.payment_amount)}</div>
              </div>
            </div>
            <div>
              <Link to="/">
                <button style={{ color: 'white' }} className="font-continue-shopping shopping-button">
                  Tiếp tục mua hàng
                </button>
              </Link>
            </div>
            <div>
              <button
                onClick={this.onOpenModal}
                style={{
                  color: 'white',
                  background: '#ED1C24',
                  cursor: order?.order_state === 'processing' ? '' : 'not-allowed',
                }}
                className="font-continue-shopping shopping-button"
                disabled={order?.order_state !== 'processing'}>
                Huỷ đơn hàng
              </button>
            </div>
            <Modal
              open={this.state.open}
              onClose={this.onCloseModal}
              showCloseIcon={false}
              classNames={{ modal: 'customModal' }}>
              <div className="confirm-modal-width-default">
                <div className="col-sm-12">
                  <div style={{ marginTop: '20px', marginBottom: '30px', textAlign: 'center' }}>
                    Bạn có chắc chắn huỷ đơn hàng ?
                  </div>
                </div>
                <div style={{ border: '1px solid #000000', width: '100%', opacity: '0.1' }} />
                <div style={{ display: 'flex', marginTop: '20px' }}>
                  <button
                    className="col-sm-5 no-confirm-button"
                    onClick={this.onCloseModal}
                    style={{ marginLeft: '20px', marginRight: '5px' }}>
                    Không
                  </button>
                  <button className="col-sm-5 yes-confirm-button" onClick={this.cancelOrder}>
                    Có
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.orderReducer.order,
  stationList: state.stationReducer.stations,
  allProducts: state.productReducer.allProducts,
});

const mapDispatchToProps = (dispatch) => ({
  getOrderDetail: (code) => dispatch(orderActions.getOrderDetail(code)),
  cancelOrder: (code, meta) => dispatch(orderActions.cancelOrder(code, meta)),
  cancelItemInOrder: (payload, meta) => dispatch(orderActions.cancelItemInOrder(payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DetailOrder));
