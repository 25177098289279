import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { API_CALLING, API_CALLED_SUCCESS, API_CALLED_FAILURE, HISTORY } = PREFIX;
const _types = typesWithPrefix(HISTORY);
const TYPES = {
  GET_HISTORIES: _types('GET_HISTORIES'),
  CHANGE_PAGE: _types('CHANGE_PAGE'),
};

export const actions = {
  gettingHistories: () => ({
    type: TYPES.GET_HISTORIES,
    meta: { prefix: [HISTORY, API_CALLING] },
  }),
  getHistoriesSuccess: payload => ({
    type: TYPES.GET_HISTORIES,
    payload,
    meta: {
      prefix: [HISTORY, API_CALLED_SUCCESS],
    },
  }),
  getHistoriesFailure: payload => ({
    type: TYPES.GET_HISTORIES,
    payload,
    meta: {
      prefix: [HISTORY, API_CALLED_FAILURE],
    },
  }),
  getHistories: meta => async (dispatch, getState) => {
    const { historyReducer } = getState();
    const params = meta && 'page' in meta ? meta : historyReducer.meta;
    const api = API_URLS.HISTORY.getHistories(params);
    dispatch(actions.gettingHistories);
    const { response, error } = await apiCall(api);

    if (!error && response.status === 200) {
      dispatch(
        actions.getHistoriesSuccess({
          data: response.data.data,
          page: meta && 'page' in meta ? meta.page : historyReducer.meta.page,
        }),
      );
    } else {
      dispatch(actions.getHistoriesFailure({}));
    }
  },
  changePage: () => ({
    type: TYPES.CHANGE_PAGE,
    meta: { prefix: [HISTORY] },
  }),
};

const initialState = {
  histories: [],
  isFetchingList: false,
  meta: {
    page: 1,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TYPES.GET_HISTORIES:
        if (isCallingApi(action)) {
          draft.isFetchingList = true;
        } else if (isSuccessfulApiCall(action)) {
          const { data, page } = action.payload;

          draft.histories = page !== 1 ? [...draft.histories, ...data] : data;
          draft.isFetchingList = false;
          draft.page = page;
        } else if (isFailedApiCall(action)) {
          draft.histories = [];
          draft.isFetchingList = false;
          draft.meta = {
            page: 1,
          };
        }
        break;

      case TYPES.CHANGE_PAGE:
        draft.meta.page = draft.meta.page + 1;
        break;
      default:
        return draft;
    }
  });
