import moment from 'moment';
import React, { Component } from 'react';

import EmptyNotìication from './empty-notification';
const data = {
  date: '2020-08-06T09:09:41.671Z',
  content:
    ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra vel purus metus a elementum suscipit amet sed quis. Sed arcu malesuada sit venenatis sodales viverra lectus gravida ornare. Fames purus commodo, neque sit convallis viverra. Sit nunc suscipit viverra nisl eu. Dolor iaculis ut gravida nisi at vulputate pharetra. In id aliquam enim id elit euismod. Mattis condimentum et ac arcu. Nisl enim id dui et at. At metus vel ut aenean sagittis rhoncus ornare pretium tristique. Vestibulum orci elementum sed duis eget congue ipsum gravida. Neque, a nam quis nisl, vulputate accumsan tellus. Arcu malesuada diam mauris purus morbi odio id vitae elementum. Proin risus adipiscing nulla sit augue.',
  image: '/assets/images/NotificationBanner.png',
};
class Detail extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="center1">
          <div style={{ height: '144px', border: '1px solid green', width: '72%' }}>
            <img
              className="center1"
              style={{ width: '100%', height: '144px' }}
              src="/assets/images/NotificationBanner.png"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '-20px',
              }}>
              <div
                style={{
                  borderRadius: '50%',
                  width: '48px',
                  height: '48px',
                  border: '4px solid white',
                  zIndex: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}>
                <img style={{ width: '40px', height: '40px' }} src="/assets/images/Notification.png" />
              </div>
            </div>
          </div>
          <div className="center1" style={{ marginTop: '50px' }}>
            <div className="font-below-category">Thông báo</div>
            <div className="font-range">{moment(data.date).format('DD-MM-YY/hh:mm')}</div>
          </div>
          <div style={{ width: '66%', marginTop: '50px' }}>
            <div className="font-range" style={{ marginBottom: '50px' }}>
              {data.content}
            </div>
            <img style={{ width: '100%', height: '200px' }} src={data.image} />
          </div>
        </div>
      </div>
    );
  }
}

export default Detail;
