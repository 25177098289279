import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { API_CALLING, API_CALLED_SUCCESS, API_CALLED_FAILURE, FAVOURITES } = PREFIX;
const _types = typesWithPrefix(FAVOURITES);
const types = {
  GET_ITEMS_IN_FAVOURITES: _types('GET_ITEMS_IN_FAVOURITES'),
  ADD_ITEM_TO_FAVOURITES: _types('ADD_ITEM_TO_FAVOURITES'),
  DELETE_ITEM_IN_FAVOURITES: _types('DELETE_ITEM_IN_FAVOURITES'),
  DELETE_ALL_ITEMS_IN_FAVOURITES: _types('DELETE_ALL_ITEMS_IN_FAVOURITES'),
};

export const actions = {
  getItemsInFavourites: () => async dispatch => {
    const api = API_URLS.FAVOURITES.getItemsInFavourites();
    dispatch(actions.gettingItemsInFavourites);
    const { response, error } = await apiCall(api);
    // console.log('FavouritesRedux', 'getItemInFavourites');
    // log('FavouritesRedux:', 'getItemInFavourites', 'response', response);
    if (!error && response.status === 200) {
      // log('FavouritesRedux:', 'getItemInFavourites', 'response', response);
      const { data } = response.data;
      dispatch(
        actions.getItemsInFavouritesSuccess({
          favourites: data,
        }),
      );
    } else {
      dispatch(actions.getItemsInFavouritesFailure({}));
    }
  },
  gettingItemsInFavourites: () => ({
    type: types.GET_ITEMS_IN_FAVOURITES,
    meta: { prefix: [FAVOURITES, API_CALLING] },
  }),
  getItemsInFavouritesSuccess: payload => ({
    type: types.GET_ITEMS_IN_FAVOURITES,
    payload,
    meta: {
      prefix: [FAVOURITES, API_CALLED_SUCCESS],
    },
  }),
  getItemsInFavouritesFailure: payload => ({
    type: types.GET_ITEMS_IN_FAVOURITES,
    payload,
    meta: {
      prefix: [FAVOURITES, API_CALLED_FAILURE],
    },
  }),

  addingItemToFavourites: () => ({
    type: types.ADD_ITEM_TO_FAVOURITES,
    meta: { prefix: [FAVOURITES, API_CALLING] },
  }),
  addItemToFavouritesSuccess: payload => ({
    type: types.ADD_ITEM_TO_FAVOURITES,
    payload,
    meta: { prefix: [FAVOURITES, API_CALLED_SUCCESS] },
  }),

  addItemToFavouritesFailure: () => ({
    type: types.ADD_ITEM_TO_FAVOURITES,
    meta: { prefix: [FAVOURITES, API_CALLED_FAILURE] },
  }),

  addItemToFavourites: payload => async dispatch => {
    const api = API_URLS.FAVOURITES.addItemToFavourites(payload);
    dispatch(actions.addingItemToFavourites());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.addItemToFavouritesSuccess(response.data));
      dispatch(actions.getItemsInFavourites());
    } else {
      dispatch(actions.addItemToFavouritesFailure(error));
    }
    return { response, error };
  },

  deletingItemToFavourites: () => ({
    type: types.DELETE_ITEM_IN_FAVOURITES,
    meta: { prefix: [FAVOURITES, API_CALLING] },
  }),
  deleteItemInFavouritesSuccess: payload => ({
    type: types.DELETE_ITEM_IN_FAVOURITES,
    payload,
    meta: { prefix: [FAVOURITES, API_CALLED_SUCCESS] },
  }),

  deleteItemInFavouritesFailure: () => ({
    type: types.DELETE_ITEM_IN_FAVOURITES,
    meta: { prefix: [FAVOURITES, API_CALLED_FAILURE] },
  }),

  deleteItemInFavourites: productCode => async dispatch => {
    const api = API_URLS.FAVOURITES.deleteItemInFavourites(productCode);
    dispatch(actions.deletingItemToFavourites());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.deleteItemInFavouritesSuccess(response.data));
      dispatch(actions.getItemsInFavourites());
    } else {
      dispatch(actions.deleteItemInFavouritesSuccess(error));
    }
    return { response, error };
  },
  deletingAllItemToFavourites: () => ({
    type: types.DELETE_ALL_ITEMS_IN_FAVOURITES,
    meta: { prefix: [FAVOURITES, API_CALLING] },
  }),
  deleteAllItemInFavouritesSuccess: payload => ({
    type: types.DELETE_ALL_ITEMS_IN_FAVOURITES,
    payload,
    meta: { prefix: [FAVOURITES, API_CALLED_SUCCESS] },
  }),

  deleteAllItemInFavouritesFailure: () => ({
    type: types.DELETE_ALL_ITEMS_IN_FAVOURITES,
    meta: { prefix: [FAVOURITES, API_CALLED_FAILURE] },
  }),

  deleteAllItemInFavourites: payload => async dispatch => {
    const api = API_URLS.FAVOURITES.deleteAllItemInFavourites(payload);
    dispatch(actions.deletingAllItemToFavourites());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.deleteAllItemInFavouritesSuccess(response.data));
      dispatch(actions.getItemsInFavourites());
    } else {
      dispatch(actions.deleteAllItemInFavouritesFailure(error));
    }
    return { response, error };
  },
};

const initialState = {
  favourites: [],
  favourtiesDetail: [],
  isFetching: false,
};

export const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.GET_ITEMS_IN_FAVOURITES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { favourites } = action.payload;
          draft.favourites = favourites;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.favourites = [];
          draft.isFetching = false;
        }
        break;

      case types.ADD_ITEM_TO_FAVOURITES:
      case types.DELETE_ITEM_IN_FAVOURITES:
      case types.DELETE_ALL_ITEMS_IN_FAVOURITES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
        }
        break;
      default:
        return draft;
    }
  });
