/* eslint-disable no-undef */

export const getCDNImage = (imageUrl) => {
  if (!imageUrl) return '';

  if (!imageUrl.startsWith('http')) {
    return `${process.env.RAZZLE_TX_IMAGE_SERVER}${imageUrl}`;
  }

  return imageUrl;
};
