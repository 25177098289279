import React, { Component } from 'react';
class EmptyNotification extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="center1">
          <img style={{ width: '30%', height: '30%', marginTop: '0px' }} src="/assets/images/EmptyNotification.png" />
          <div>
            <div className="font-below-category"> Bạn không có thông báo nào cả!!! </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmptyNotification;
