import React, { Component } from 'react';
import { slugPath } from 'utils/product';
import '../home/product/index.scss';

class Product extends Component {
  render() {
    const { category } = this.props;
    return (
      <div>
        <a href={`/category/${slugPath(category)}`}>
          <div className="categoryStyle categoryHover">
            <img src={category.icon ? category.icon : '/assets/images/Category1.png'} />
            <div className="center1 font-below-category" style={{ marginTop: '15px', textAlign: 'center' }}>
              {category.name}
            </div>
          </div>
        </a>
      </div>
    );
  }
}
export default Product;
