import React, { Component } from 'react';
import { UAParser } from 'ua-parser-js';

import Breadcrumb from '../../components/common/breadcrumb';

class Contact extends Component {
  componentDidMount() {
    const parser = new UAParser();
    // by default it takes ua string from current browser's window.navigator.userAgent
    const ua = parser.getResult();
    const isAndroid = !!ua.ua.match(/Android/);
    const isIOS = !!ua.ua.match(/iPhone/);
    if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=vn.anvita.xtv.sanvt';
    } else if (isIOS) {
      window.location.href = 'https://apps.apple.com/us/app/tram-xanh/id1583373711';
    }
  }

  render() {
    return (
      <div>
        <Breadcrumb title={'Cài đặt'} />
        <section className=" contact-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center section-b-space mt-5">
                <h2 style={{ color: '#333333', textTransform: 'none' }}>Cài đặt</h2>
                <h3>Tải ứng dụng trên điện thoại</h3>
                <div>
                  <a href="https://apps.apple.com/us/app/tram-xanh/id1583373711" target="blank">
                    <img
                      src="/assets/images/icon/logo/ios-download.png"
                      className="img-fluid"
                      width="260px"
                      height="160px"
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=vn.anvita.xtv.sanvt" target="blank">
                    <img
                      src="/assets/images/icon/logo/android-download.png"
                      className="img-fluid"
                      width="260px"
                      height="160px"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
