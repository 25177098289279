import moment from 'moment';
import React, { Component } from 'react';

import { API_URLS } from '../../../config/api';
import { apiCall } from '../../../utils/api';
import './discount.css';

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});

class Discount extends Component {
  state = {};

  getCollaborators = async () => {
    const cur = moment().format('YYYY-MM');
    const prev = moment().subtract(6, 'month').format('YYYY-MM');
    const api = API_URLS.COLLABORATOR.getCollaborator({
      time_start: `${prev}-01T00:00:00Z`,
      time_end: `${cur}-01T00:00:00Z`,
    });
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const collaboratorList = response.data.data;
      const table = document.getElementById('table');
      table.innerHTML = '';
      let tr = '';
      collaboratorList.forEach((x) => {
        tr += '<tr>';
        tr += `<td>${x.month}/${x.year}</td> 
              <td>${x.total_order}</td>
              <td>${formatter.format(x.total_value)}</td>
              <td>${formatter.format(x.collaborator_discount)}</td>`;
        tr += '</tr>';
      });
      table.innerHTML += tr;
    }
  };

  componentDidMount = () => {
    this.getCollaborators();
  };

  render() {
    return (
      <div>
        <div className="font-header" style={{ marginBottom: '50px' }}>
          Chiết khấu
        </div>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Tháng/Năm</th>
              <th>Số đơn</th>
              <th>Tổng chi</th>
              <th>Chiết khấu</th>
            </tr>
          </thead>
          <tbody id="table" />
        </table>
      </div>
    );
  }
}

export default Discount;
