import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { components } from 'react-select';

import { actions as accountActions } from '../../../../redux/AccountRedux';
import { actions as authActions } from '../../../../redux/AuthRedux';
import UniverseCookies from '../../../../utils/cookies';
import StationSearch from '../../../formItems/StationSearch';

const StationModal = (props) => {
  const { isOpen, onCancel, user } = props;
  const [selectedStationCode, setSelectedStationCode] = useState(UniverseCookies.getStationCode());

  // const [openStationForm, setOpenStationForm] = useState(!token);
  // const closeStationModal = () => setOpenStationForm(false);

  const updateStation = () => {
    if (selectedStationCode) {
      const meta = {
        onSuccess: () => {
          onCancel();
          window.location.reload();
        },
        onFailure: () => {
          NotificationManager.error('Có lỗi khi chọn Trạm, hãy thử lại!', 'Thất bại', 2000);
        },
      };
      if (user?.code) {
        props.updateAccountInfo(selectedStationCode, meta);
      } else {
        props.guestLogin(selectedStationCode, meta);
      }
    }
  };

  const ValueContainerStation = ({ children, ...props }) =>
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
          <i
            className="fa fa-building"
            aria-hidden="true"
            style={{ position: 'absolute', left: 6, fontSize: 24, color: '#808080' }}
          />
        )}
        {children}
      </components.ValueContainer>
    );
  const styles = {
    valueContainer: (base) => ({
      ...base,
      paddingLeft: 24,
      height: '60px',
    }),
  };

  return (
    <Modal open={isOpen} onClose={onCancel} closeOnEsc={false} closeOnOverlayClick={false}>
      <div className="modal-width-default" style={{ width: '100%', height: '100%' }}>
        <div className="headerFormLogin">
          <div className="headerFormLogin-item">Chọn Trạm</div>
        </div>
        <div className="form-group center-form-phone">
          <div className="inputOtp">
            <span style={{ marginLeft: '-100px', fontFamily: 'Helvetica', fontSize: '18px' }}>
              Chọn Trạm để mua hàng
            </span>
          </div>
          <div className="select-station">
            <div style={{ marginTop: '10px' }} />
            <StationSearch
              selectedValue={selectedStationCode}
              onChange={(station) => {
                setSelectedStationCode(station.value);
              }}
              classNamePrefix="vyrill"
              components={{ ValueContainer: ValueContainerStation }}
              styles={styles}
            />
            <button
              onClick={updateStation}
              style={{
                marginTop: '30px',
              }}
              className="submit-button">
              <span style={{ fontFamily: 'Roboto', fontSize: '18px' }}>Xác nhận</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.accountReducer.accountInformation,
  token: state.accountReducer.token,
});

const mapDispatchToProps = (dispatch) => ({
  guestLogin: (stationCode, meta) => {
    dispatch(authActions.guestLogin(stationCode, meta));
  },
  updateAccountInfo: (stationCode, meta) => {
    dispatch(accountActions.updateAccountInfo({ station_code: stationCode }, meta));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StationModal);
