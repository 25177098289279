import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { getCDNImage } from 'utils/image';
import { slugPath } from 'utils/product';

class PostScrollListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 10,
      hasMoreItems: true,
      relatedProductsInfo: [],
    };
  }

  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const { posts } = this.props;

    return (
      <div style={{ marginLeft: -15, marginRight: -15 }}>
        <Slider {...settings}>
          {posts.map((item, index) => (
            <div className={'col-xl-3 col-md-6 col-grid-box'} key={index}>
              <div className="blog-media" key={index}>
                <div className="blog-left">
                  <Link to={`/detail-post/${slugPath(item)}`}>
                    <img
                      src={item.cover_image ? getCDNImage(item.cover_image) : '/assets/images/blog-1.jpg'}
                      className="img-fluid blur-up lazyload bg-img"
                      alt={item.cover_image_alt || ''}
                    />
                  </Link>
                </div>
                <div className="blog-right-custom">
                  <div>
                    <h6>{item.date}</h6>
                    <Link to={`/detail-post/${slugPath(item)}`}>
                      <h5>{item.title}</h5>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default PostScrollListing;
