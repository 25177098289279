import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { actions as accountActions } from '../../redux/AccountRedux';
import { actions } from '../../redux/ProductRedux';
import ProductListItem from './product-list-item';

let data = [
  {
    name: 'name',
    category: { name: '' },
    rating: null,
    variants: [{ images: '' }],
    id: 'id',
    discount: 0,
    price: '500000',
    code: '',
    image: '',
  },
];
class ProductListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 16,
      hasMoreItems: true,
      pageCount: 0,
      curPage: 1,
      curCategoryCode: '',
      curSupplierCode: '',
    };
  }

  componentDidMount() {
    this.props.getAccountWishList();
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.codeOfCategory) {
      const params = {
        page: prevState.curPage,
        pageSize: 16,
        categoryCode: nextProps.codeOfCategory,
        supplierCode: nextProps.codeOfSupplier || undefined,
      };
      if (
        nextProps.codeOfCategory !== prevState.curCategoryCode ||
        nextProps.codeOfSupplier !== prevState.curSupplierCode
      ) {
        // reset page if switch category or supplier
        params.page = 1;
        nextProps.getProductByCatCodeIfNeed(params);
        return { curCategoryCode: nextProps.codeOfCategory, curSupplierCode: nextProps.codeOfSupplier, curPage: 1 };
      }

      nextProps.getProductByCatCodeIfNeed(params);
    }
    if (nextProps.meta.pagination.total) {
      return { pageCount: Math.ceil(nextProps.meta.pagination.total / 16) };
    }
    return null;
  };

  handlePageClick = (data) => {
    this.setState({ curPage: data.selected + 1 });
  };

  render() {
    const { curCategoryCode, curSupplierCode } = this.state;
    const { allProducts, productsInCategory, symbol } = this.props;
    // console.log('=============products=====', products);
    if (this.props.codeOfCategory !== '') {
      data = productsInCategory?.map((row) => ({
        name: row.name,
        slug: row.slug,
        // category: null,
        rating: 5,
        variants: row.images?.map((item) => ({
          images: item.listing_url,
        })),
        discount: row.price?.sale_off || 0,
        price: row.price?.selling_price || 0,
        old_price: row.price?.price || 0,
        category: row.category?.name,
        code: row.code,
        image: row.image ? row.image?.listing_url : '',
        image_name: row.image ? row.image.name : '',
        image_alt: row.image ? row.image.alt : '',
      }));
    } else {
      data = allProducts?.map((row) => ({
        name: row.name,
        slug: row.slug,
        // category: null,
        rating: 5,
        variants: row.images?.map((item) => ({
          images: item.listing_url,
        })),
        discount: row.price?.sale_off || 0,
        price: row.price?.price || 0,
        category: row.category?.name,
        code: row.code,
        image: row.image ? row.image?.listing_url : '',
        image_name: row.image ? row.image.name : '',
        image_alt: row.image ? row.image.alt : '',
      }));
    }
    //console.log('data_product', data);
    return (
      <div>
        <div className="product-wrapper-grid">
          <div className="container-fluid">
            {data?.length > 0 ? (
              <InfiniteScroll
                dataLength={this.state.limit} //This is important field to render the next data
                next={this.fetchMoreItems}
                hasMore={this.state.hasMoreItems}
                // loader={<div className="loading-cls" />}
                endMessage={
                  <p className="seen-cls seen-it-cls">
                    <b>Yay! You have seen it all</b>
                  </p>
                }>
                <div className="row">
                  {data.slice(0, this.state.limit).map((product, index) => (
                    <div
                      className={`${
                        this.props.colSize === 3
                          ? 'col-6 col-sm-4 col-lg-3 col-grid-box'
                          : `col-lg-${this.props.colSize}`
                      }`}
                      key={index}>
                      <ProductListItem product={product} symbol={symbol} key={index} />
                    </div>
                  ))}
                </div>
                <div className={'pagination-box'} style={{ marginTop: '30px' }}>
                  <ReactPaginate
                    key={`${curCategoryCode}-${curSupplierCode}`}
                    previousLabel={'←'}
                    nextLabel={'→'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  />
                </div>
              </InfiniteScroll>
            ) : (
              <div className="row">
                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                  <h3>Không tìm thấy sản phẩm nào!!</h3>
                  <p>Hãy thử lại với danh mục sản phẩm khác</p>
                  <Link to={`/`} className="btn btn-solid">
                    Về trang chủ
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  productsInCategory: state.productReducer?.categoryProducts?.list || [],
  meta: state.productReducer.categoryProducts?.meta || { pagination: { total: 0 } },
  allProducts: state.productReducer.allProducts,
});
const mapDispatchToProps = (dispatch) => ({
  getProductByCatCode: (params) => {
    dispatch(actions.getProductByCatCode(params));
  },
  getProductByCatCodeIfNeed: (params) => {
    dispatch(actions.getProductByCatCodeIfNeed(params));
  },
  getAccountWishList: () => dispatch(accountActions.getAccountWishList()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductListing));
