import React from 'react';
import { Link } from 'react-router-dom';
import { slugPath } from 'utils/product';

const CartHeader = ({ item, removeFromCart }) => (
  <li>
    <div className="media">
      <Link to={`/detail-product/${slugPath(item)}`}>
        <img alt="" className="mr-3" src={`${item.pictures[0]}`} />
      </Link>
      <div className="media-body">
        <Link to={`/detail-product/${slugPath(item)}`}>
          <h4>{item.name}</h4>
        </Link>
        <h4>
          <span>
            {/* {item.qty} x {symbol} {(item.price * item.discount) / 100} */}
            {item.qty} x {item.price} ₫
          </span>
        </h4>
      </div>
    </div>
    {/*<span>{cart}</span>*/}
    <div className="close-circle">
      <a href={null} onClick={removeFromCart}>
        <i className="fa fa-times" aria-hidden="true" />
      </a>
    </div>
  </li>
);

export default CartHeader;
