// import moment from 'moment';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { actions as contentActions } from '../../redux/ContentRedux';
import { getCDNImage } from '../../utils/image';
import { codeFromSlug } from '../../utils/product';
import PostScrollListing from './post-scroll-listing';

class PostDetail extends Component {
  state = {};

  // componentDidMount = () => {
  //   const postCode = this.props.match.params.code;
  //   this.props.getPostDetail(postCode);
  // }

  static getDerivedStateFromProps = (props, state) => {
    if (props.match.params.code !== '' && state.curCode !== props.match.params.code) {
      const postCode = codeFromSlug(props.match.params.code);
      props.getPostDetail(postCode);
      props.getLatestPosts();

      return { curCode: props.match.params.code };
    }
    return null;
  };

  actionPath = () => {
    switch (this.props.curPost.action_type) {
      case 'home':
        return '/';
      case 'promotion':
        return '/promotion';
      case 'category':
        return `/category/${this.props.curPost.action_value}`;
      case 'product':
        return `/detail-product/${this.props.curPost.action_value}`;
      default:
        return '/';
    }
  };

  render() {
    const { curPost, latestPosts } = this.props;
    return (
      <div>
        <Helmet>
          <title>{curPost.title}</title>
          <meta property="description" content={curPost.short_description} />
          <meta property="og:title" content={curPost.title} />
          <meta property="og:type" content="article" />
          <meta property="og:description" content={curPost.short_description} />
          <meta name="keywords" content={curPost.keywords ? curPost.keywords.join(' ') : ''} />
          {curPost.banner || curPost.cover_image ? (
            <meta property="og:image" content={getCDNImage(curPost.banner || curPost.cover_image)} />
          ) : null}
          <meta property="og:image:alt" content={curPost.title} />
        </Helmet>
        <div className="container">
          <div className="col-sm-24">
            <div className="" style={{ marginTop: '50px' }}>
              <div className="font-product-header">
                <h1>{curPost.title}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container" style={{ marginBottom: '30px' }}>
          <div className="col-sm-24">
            <div style={{ marginTop: '30px' }}>
              {curPost.banner ? (
                <img
                  style={{ width: '100%', height: 'auto' }}
                  src={getCDNImage(curPost.banner)}
                  alt={curPost.banner_alt || ''}
                />
              ) : (
                ''
              )}
              <div
                dangerouslySetInnerHTML={{ __html: curPost.content }}
                style={{ marginBottom: '30px' }}
                className="post-detail-html"
              />
              {/* <div className="font-range" style={{ marginBottom: '30px' }} /> */}
            </div>
          </div>
          {curPost.action_type ? (
            <div className="center1">
              <Link
                to={this.actionPath()}
                className="center1 more-product-button center2"
                style={{ marginTop: '30px' }}>
                MUA HÀNG
              </Link>
            </div>
          ) : (
            ''
          )}
          {latestPosts.length ? (
            <>
              <div className="font-category-tree-header flush-left" style={{ marginTop: '50px', marginBottom: '30px' }}>
                Bài viết mới nhất
              </div>
              <PostScrollListing
                // colSize={3}
                posts={latestPosts}
              />
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  curPost: state.contentReducer.post.curPost,
  latestPosts: state.contentReducer.post.latest,
  meta: state.contentReducer.post.meta,
});

const mapDispatchToProps = (dispatch) => ({
  getPostDetail: (code) => dispatch(contentActions.getPostDetail(code)),
  getLatestPosts: () => dispatch(contentActions.getLatestPosts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostDetail);
