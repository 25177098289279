import cookies from 'react-cookies';

class UniverseCookies {
  constructor() {
    this.cookies = cookies;
  }

  initCookies = (presetCookies) => {
    this.cookies = presetCookies;
  };

  getCookies = () => this.cookies;

  getCookie = (key) => {
    if (this.cookies && this.cookies[key]) {
      return this.cookies[key];
    }

    if (this.cookies?.load) {
      return this.cookies.load(key);
    }

    return null;
  };

  saveCookie = (key, value, minMaxAge) => {
    if (this.cookies?.save) {
      const maxAge = minMaxAge || 31536000; // a year 365*24*60*60 seconds

      // console.log(adCookie);
      const cookieOptions = { path: '/', maxAge };
      this.cookies.save(key, value, cookieOptions);
    }
  };

  removeCookie = (key) => {
    if (this.cookies?.remove) {
      this.cookies.remove(key);
    }
  };

  getToken = () => this.getCookie('tx_token');

  saveToken = (token) => this.saveCookie('tx_token', token);

  removeToken = () => this.removeCookie('tx_token');

  // eslint-disable-next-line no-undef
  getStationCode = () => this.getCookie('tx_station_code') || process.env.RAZZLE_TX_DEFAULT_STATION_CODE;

  saveStationCode = (station_code) => this.saveCookie('tx_station_code', station_code);

  removeStationCode = () => this.removeCookie('tx_station_code');
}

export default new UniverseCookies();

// export { getCookies as default, getCookie, saveCookie, getToken, saveToken };
