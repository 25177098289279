import React, { Component } from 'react';
import { getCDNImage } from 'utils/image';
// import {
//   //   MagnifierContainer,
//   //   MagnifierPreview,
//   //   SideBySideMagnifier,
//   //   MagnifierZoom,
//   // GlassMagnifier,
//   //   Magnifier,
//   //   MOUSE_ACTIVATION,
//   //   TOUCH_ACTIVATION,
//   //   PictureInPictureMagnifier,
// } from 'react-image-magnifiers';

export default class ImageZoom extends Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       image: '',
  //     };
  //   }

  render() {
    const { image } = this.props;
    // const { isDetail } = this.props;
    const imageUrl = getCDNImage(image?.detail_url);
    const largeImage = imageUrl;
    return (
      <>
        {imageUrl ? (
          // <GlassMagnifier
          //   imageSrc={imageUrl}
          //   imageAlt="Product_image"
          //   largeImageSrc={largeImage}
          //   magnifierSize="35%"
          //   square
          // />
          <img src={largeImage} className="img-fluid image_zoom_cls-0" alt={image?.alt || image?.name || ''} />
        ) : (
          <img src="/assets/images/product_default.png" className="img-fluid image_zoom_cls-0" />
        )}
      </>
    );
  }
}
