import React from 'react';
import { Link } from 'react-router-dom';

function LogoImage(props) {
  return (
    <Link to={`/`}>
      <img src={`/assets/images/icon/logo/${props.logo}`} alt="" className="img-fluid" width="150px" />
    </Link>
  );
}

export default LogoImage;
