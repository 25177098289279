import React, { Component } from 'react';
import { NotificationContainer } from 'react-notifications';

// Custom Components
import FlyButtons from './components/common/fly-buttons';
import FooterOne from './components/common/footers/footer-one';
import HeaderOne from './components/common/headers/header-one';

class AppLayout extends Component {
  go = () => {};
  render() {
    const pathName = this.props.location?.pathname;
    return (
      <div>
        <HeaderOne logoName="logoApp.jpg" showH1={pathName && pathName === '/'} />
        {this.props.children}
        <FooterOne logoName="logoApp.jpg" />
        <FlyButtons />
        <NotificationContainer />
        {/* <ThemeSettings /> */}
      </div>
    );
  }
}

export default AppLayout;
