import React, { Component } from 'react';
import './post.scss';
import { Link } from 'react-router-dom';
import { slugPath } from 'utils/product';

class PostList extends Component {
  state = {};
  render() {
    return (
      <>
        {this.props.postList &&
          this.props.postList.map((item, i) => (
            <div className="row blog-media" key={i}>
              <div className="col-xl-6">
                <div className="blog-left">
                  <Link to={`/detail-post/${slugPath(item)}`}>
                    <img
                      src={item.img ? item.img : '/assets/images/blog-1.jpg'}
                      className="img-fluid blur-up lazyload bg-img"
                      alt={item.img_alt || ''}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="blog-right-custom">
                  <div>
                    <h6>{item.date}</h6>
                    <Link to={`/detail-post/${slugPath(item)}`}>
                      <h4>{item.title}</h4>
                    </Link>
                    {/* <ul className="post-social">
                                        <li>Posted By : Admin Admin</li>
                                        <li><i className="fa fa-heart"/>5 Hits</li>
                                        <li><i className="fa fa-comments"/>10 Comment</li>
                                    </ul> */}
                    <p>{item.shortDesc}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </>
    );
  }
}

export default PostList;
