import moment from 'moment';
import React,{Component} from 'react';

const currencyFormatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
    minimumFractionDigits: 0,
  });
class Voucher extends Component {
    state = {  }

    renderPromoDiscription = () => {
        const { voucher, allProducts } = this.props;
        switch (voucher.promotion_discount?.discount_type) {
            case 'PERCENT':
                if (voucher.promotion_discount?.nb_usages > 1) {
                    return `Giảm giá đơn hàng: ${voucher.promotion_discount?.usages.map((value, index) => {
                        return ` lần ${index + 1} giảm ${value}%${index === voucher.promotion_discount?.usages.length - 1 ? '.' : ''}`
                    })}`;
                } else return `Giảm giá đơn hàng: ${voucher.promotion_discount?.usages[0]}%.`;
            case 'GIFT_PRODUCT':
                return `Tặng sản phẩm: ${voucher.gift_products.map((value, index) => {
                    const gift_products = allProducts?.find(
                      (item) => item.code === value,
                    );
                    return gift_products
                      ? `${gift_products.name}${
                          index === voucher.gift_products.length - 1 ? '.' : ','
                        }`
                      : '';
                  })}`;
            case 'FIXED_PRICE_OFF':
                    if (voucher.promotion_discount?.nb_usages > 1) {
                        return `Giảm giá đơn hàng: ${voucher.promotion_discount?.usages.map((value, index) => {
                            return ` lần ${index + 1} giảm ${currencyFormatter.format(value)}${index === voucher.promotion_discount?.usages.length - 1 ? '.' : ''}`
                        })}`;
                    } else return `Giảm giá đơn hàng: ${currencyFormatter.format(voucher.promotion_discount?.usages[0])}.`;
        }
    }

    render() { 
        const { voucher, currentVoucher } = this.props;
        return ( 
            <div style={{background:'#F6F6F8',boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)', borderRadius:'4px', height:'100px',display:'flex',marginBottom: '10px'}}>
                                    <div className='col-sm-3 center1'>
                                        <img src='/assets/images/VoucherTicket.png' style={{width: '57px', height:'33px',marginTop:'5px',position:'absolute'}}/>
                                        <img src='/assets/images/VoucherCircle.png' style={{width: '62px', height: '62px'}} />
                                    </div>
                                    <div className='modifyBorder1'/>

                                    <div className='col-sm-6 center1' style={{alignItems: 'stretch', paddingRight: 0}}>
                                        <div className='font-category-tree'>{voucher?.promotion_discount?.gift_code} - {voucher?.name}</div>
                                        <div className='font-voucher2' style={{ marginTop: 3, marginBottom: 5 }}>{this.renderPromoDiscription()}</div>
                                        <div style={{color: '#363636'}}> HSD: {moment(voucher?.end_time).format('DD/MM/YYYY')}</div>
                                    </div>
                                    <div className='col-sm-3' style={{paddingLeft: 20, paddingRight: 0}}>
                                        <button 
                                            className='font-voucher1' 
                                            disabled={voucher.code === currentVoucher.code ? true : false}
                                            style={{
                                                border:'none', 
                                                color: voucher.code === currentVoucher.code ? 'red' : 'blue', 
                                                cursor: voucher.code === currentVoucher.code ? 'not-allowed' : '',
                                                background:'#F6F6F8', marginTop:'75px'}}
                                            onClick={() => this.props.applyVoucher(this.props.voucher)}
                                        >
                                            {voucher.code === currentVoucher.code ? 'đã sử dụng' : 'dùng ngay'}
                                        </button>
                                    </div>
                                </div>
         );
    }
}
 
export default Voucher;