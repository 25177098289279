import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import Breadcrumb from '../../components/common/breadcrumb';
import { actions as accountActions } from '../../redux/AccountRedux';
import SideBar from '../account/sidebar';
import Discount from './discount/discount';
import History from './history/history';
import Form from './information/form';
// import Detail from './notification/detail-notification';
import Notification from './notification/notification-list';
import WishLists from './wishlists/wishlists';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountInformation: {},
      isMobileFilterOpened: false,
    };
  }

  componentDidMount = () => {};

  openFilter = () => {
    document.querySelector('.collection-filter').style = 'left: -15px';
    this.setState({ isMobileFilterOpened: true });
  };

  closeFilter = () => {
    if (this.state.isMobileFilterOpened) {
      document.querySelector('.collection-filter').style = 'left: -370px';
      this.setState({ isMobileFilterOpened: false });
    }
  };

  render() {
    const { accountInformation } = this.props;
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>Trạm Xanh | Quản lý tài khoản</title>
          <meta
            name="description"
            // eslint-disable-next-line max-len
            content=""
          />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={'Quản lý tài khoản'} />

        <section className="section-b-space">
          <div className="collection-wrapper" style={{ marginTop: '50px' }}>
            <div className="container">
              <div className="row">
                <div className="col-md-3 collection-filter">
                  <div className="sidebar-wrapper">
                    <SideBar isCollaborator={accountInformation.is_collaborator} closeSidebar={this.closeFilter} />
                  </div>
                </div>
                <div className="col-12 col-lg-9">
                  <div className="product-top-filter">
                    <div className="container-fluid p-0">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="filter-main-btn">
                            <span onClick={this.openFilter} className="filter-btn btn btn-theme">
                              <i className="fa fa-bars" aria-hidden="true" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Switch>
                    <Route
                      path="/account/information"
                      render={(props) => <Form {...props} accountInformation={accountInformation} />}
                    />
                    <Route path="/account/wishlists" component={WishLists} />
                    <Route path="/account/history" component={History} />
                    <Route path="/account/notification" component={Notification} />
                    {accountInformation.is_collaborator ? (
                      <Route path="/account/discount" component={Discount} />
                    ) : (
                      <></>
                    )}
                  </Switch>

                  {/* <h3 style={{ textAlign: 'left' }}>Thông tin cá nhân</h3> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accountInformation: state.accountReducer.accountInformation,
});

const mapDispatchToProps = (dispatch) => ({
  getAccountInformation: () => dispatch(accountActions.getAccountInformation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Account));
