import React, { Component } from 'react';
import './post.scss';
import { Link, withRouter } from 'react-router-dom';

class PostList extends Component {
  state = {};
  render() {
    return (
      <>
        {this.props.postList &&
          this.props.postList.map((item, i) => (
            <div className="row blog-media" key={i}>
              <div className="col-xl-6">
                <div className="blog-left video-responsive">
                  <iframe
                    className="img-fluid blur-up lazyload bg-img"
                    width="100%"
                    src={`https://www.youtube.com/embed/${item.videoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="blog-right-custom">
                  <div>
                    <h6>{item.date}</h6>
                    <h4>{item.title}</h4>
                    <p>{item.shortDesc}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </>
    );
  }
}

export default PostList;
