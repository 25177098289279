import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { flattenCategories } from '../utils/product';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { CATEGORIES } = PREFIX;
const _types = typesWithPrefix(CATEGORIES);
const types = {
  GET_CATEGORIES: _types('GET_CATEGORIES'),
  GET_MENU_CATEGORIES: _types('GET_MENU_CATEGORIES'),
};

// ACTION
export const actions = {
  gettingCategories: () => ({
    type: types.GET_CATEGORIES,
    meta: {
      prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLING],
    },
  }),

  getCategoriesSuccess: (payload) => ({
    type: types.GET_CATEGORIES,
    payload,
    meta: {
      prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getCategoriesFailure: () => ({
    type: types.GET_CATEGORIES,
    meta: {
      prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_FAILURE],
    },
  }),

  getCategories: (params) => async (dispatch) => {
    // console.log('getCategories---response');
    const api = API_URLS.PRODUCT.getCategories(params);
    dispatch(actions.gettingCategories());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const payload = response.data;
      // console.log('getCategories---response',payload);
      dispatch(actions.getCategoriesSuccess(payload));
    } else {
      dispatch(actions.getCategoriesFailure());
    }
  },

  gettingMenuCategories: () => ({
    type: types.GET_MENU_CATEGORIES,
    meta: {
      prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLING],
    },
  }),

  getMenuCategoriesSuccess: (payload) => ({
    type: types.GET_MENU_CATEGORIES,
    payload,
    meta: {
      prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getMenuCategoriesFailure: () => ({
    type: types.GET_MENU_CATEGORIES,
    meta: {
      prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_FAILURE],
    },
  }),

  getMenuCategories: () => async (dispatch) => {
    // console.log('getCategories---response');
    const api = API_URLS.PRODUCT.getCategories({ show_on_horizontal_menu: true });
    dispatch(actions.gettingMenuCategories());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const payload = response.data;
      // console.log('getCategories---response',payload);
      dispatch(actions.getMenuCategoriesSuccess(payload));
    } else {
      dispatch(actions.getMenuCategoriesFailure());
    }
  },
};

// state
const initialState = {
  isFetching: false,
  categories: [],
  flattenCategories: {},
  horizontalMenuCategories: [],
  meta: {
    page: 1,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_CATEGORIES:
        if (isCallingApi(action)) {
          draft.isFetchingCategories = true;
        }
        if (isSuccessfulApiCall(action)) {
          draft.categories = action.payload.data;
          const flatCategories = {};
          flattenCategories(action.payload.data, flatCategories);
          draft.flattenCategories = flatCategories;
        }
        if (isFailedApiCall(action)) {
          draft.isFetchingCategories = false;
        }
        break;

      case types.GET_MENU_CATEGORIES:
        if (isCallingApi(action)) {
          draft.isFetchingCategories = true;
          // console.log('davao category');
        }
        if (isSuccessfulApiCall(action)) {
          draft.horizontalMenuCategories = action.payload.data;
          // console.log('draft.categories',draft.categories);
        }
        if (isFailedApiCall(action)) {
          draft.isFetchingCategories = false;
        }
        break;
      default:
        return draft;
    }
  });
