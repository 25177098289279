import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class EmptyHistory extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="center1">
          <img style={{ width: '30%', height: '30%', marginTop: '0px' }} src="/assets/images/EmptyHistory.png" />
          <div>
            <div className="font-below-category"> Chưa có lịch sử mua hàng </div>
            <Link to="/#">
              <button className="font-continue-shopping"> Tiếp tục mua hàng </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default EmptyHistory;
