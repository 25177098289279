import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import UniverseCookies from '../utils/cookies';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { API_CALLING, API_CALLED_SUCCESS, API_CALLED_FAILURE, STATION } = PREFIX;
const _types = typesWithPrefix(STATION);
const TYPES = {
  GET_STATION_DETAIL: _types('GET_STATION_DETAIL'),
  GET_STATIONS: _types('GET_STATIONS'),
  CHOOSE_STATION: _types('CHOOSE_STATION'),
};

export const actions = {
  // SET_STATION
  initStationFromCookie: () => async (dispatch) => {
    const stationCode = UniverseCookies.getStationCode();
    if (stationCode) {
      dispatch(actions.getStationDetail(stationCode));
    }
  },
  getStationDetail: (station_code) => async (dispatch) => {
    const api = API_URLS.STATION.getStationByCode(station_code);
    dispatch(actions.gettingStationDetail);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      dispatch(
        actions.getStationDetailSuccess({
          station_detail: data,
        }),
      );
    } else {
      dispatch(actions.getStationDetailFailure({}));
    }
  },
  gettingStationDetail: () => ({
    type: TYPES.GET_STATION_DETAIL,
    meta: { prefix: [STATION, API_CALLING] },
  }),
  getStationDetailSuccess: (payload) => ({
    type: TYPES.GET_STATION_DETAIL,
    payload,
    meta: {
      prefix: [STATION, API_CALLED_SUCCESS],
    },
  }),
  getStationDetailFailure: (payload) => ({
    type: TYPES.GET_STATION_DETAIL,
    payload,
    meta: {
      prefix: [STATION, API_CALLED_FAILURE],
    },
  }),

  getStations: (meta) => async (dispatch, getState) => {
    const { stationReducer } = getState();
    const params = meta && 'page' in meta ? meta : stationReducer.meta;
    const api = API_URLS.STATION.getStations(params);
    dispatch(actions.gettingStations);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      if (data !== null) {
        dispatch(
          actions.getStationsSuccess({
            data,
            page: meta && 'page' in meta ? meta.page : stationReducer.meta.page,
          }),
        );
      }
    } else {
      dispatch(actions.getStationsFailure({}));
    }
  },
  gettingStations: () => ({
    type: TYPES.GET_STATIONS,
    meta: { prefix: [STATION, API_CALLING] },
  }),
  getStationsSuccess: (payload) => ({
    type: TYPES.GET_STATIONS,
    payload,
    meta: {
      prefix: [STATION, API_CALLED_SUCCESS],
    },
  }),
  getStationsFailure: (payload) => ({
    type: TYPES.GET_STATIONS,
    payload,
    meta: {
      prefix: [STATION, API_CALLED_FAILURE],
    },
  }),
  chooseStation: (payload) => async (dispatch) => {
    dispatch({
      type: TYPES.CHOOSE_STATION,
      payload,
      meta: { prefix: [STATION] },
    });
  },
};

const initialState = {
  choosen_station: {},
  station_detail: {},
  stations: [],
  isFetching: false,
  meta: {
    page: -1,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPES.GET_STATION_DETAIL:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { station_detail } = action.payload;
          draft.station_detail = station_detail;

          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.station_detail = {};
          draft.isFetching = false;
        }
        break;
      case TYPES.GET_STATIONS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { data, page } = action.payload;
          draft.stations = page !== 1 && data !== null ? [...draft.stations, ...data] : data;
          draft.meta.page = data !== null ? page : 1;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.stations = [];
          draft.isFetching = false;
          draft.meta = {
            page: 1,
          };
        }
        break;
      case TYPES.CHANGE_PAGE:
        draft.meta.page = draft.meta.page + 1;
        break;
      case TYPES.CHOOSE_STATION:
        draft.choosen_station = action.payload;

        break;
      default:
        return draft;
    }
  });
