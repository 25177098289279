import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NotFoungdText extends Component {
  state = { currentQuantity: 0, value: false };

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 text-center section-b-space mt-5 no-found">
          <h3>Không tìm thấy sản phẩm nào!!</h3>
          <p>Hãy thử lại với danh mục sản phẩm khác</p>
          {this.props.hideButton ? null : (
            <Link to={`/`} className="btn btn-solid">
              Về trang chủ
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default NotFoungdText;
