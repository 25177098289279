import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
// import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';

// import { COLORS } from '../../constants/colors';
import { actions as accountActions } from '../../redux/AccountRedux';
import { actions as authActions } from '../../redux/AuthRedux';
import { actions as cartActions } from '../../redux/CartRedux';
import { slugPath } from '../../utils/product';

import 'react-notifications/lib/notifications.css';

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});
class ProductListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock: 'InStock',
      quantity: 1,
      image: '',
      isInWishList: false,
      init: false,
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.accountWishList) {
      let string = `page=1&page_size=20`;
      for (let i = 0; i < props.accountWishList.length; i++) {
        string += `&product_codes=${props.accountWishList[i]?.product_code}`;
      }
      if (string !== `page=1&page_size=20`) {
        props.getWishListProductInfoIfNeed(string);
      }
    }
    if (props.wishListProduct) {
      const product = props.wishListProduct.find((product) => product.code === props.product.code);
      if (product && !state.init) {
        return { isInWishList: true, init: true };
      }
    }
    return null;
  };

  addProductToWishList = () => {
    if (!this.props.token) {
      // e.preventDefault();
      this.props.toggleLoginModal();
    } else {
      this.setState({ isInWishList: true });
      const payload = {
        product_Code: this.props.product.code,
      };
      this.props.addProductToWishList(payload, {
        onSuccess: () => {
          NotificationManager.success('Thêm sản phẩm vào danh sách yêu thích thành công!', 'Thành công', 2000);
        },
        onError: () => {
          NotificationManager.error('Thêm sản phẩm vào danh sách yêu thích thất bại!', 'Thất bại', 2000);
        },
      });
    }
  };

  removeWishListProduct = () => {
    if (!this.props.token) {
      // e.preventDefault();
      this.props.toggleLoginModal();
    } else {
      this.setState({ isInWishList: false });
      this.props.removeWishListProduct(this.props.product.code, {
        onSuccess: () => {
          NotificationManager.success('Xóa sản phẩm khỏi danh sách yêu thích thành công!', 'Thành công', 2000);
        },
        onError: () => {
          NotificationManager.error('Xóa sản phẩm khỏi danh sách yêu thích thất bại!', 'Thất bại', 2000);
        },
      });
    }
  };

  addItemToCart = () => {
    if (!this.props.token) {
      // e.preventDefault();
      this.props.toggleLoginModal();
    } else {
      const payload = {
        product_code: this.props.product.code,
        quantity: 1,
      };
      this.props.addItemToCart(payload, {
        onSuccess: () => {
          NotificationManager.success('Thêm vào giỏ hàng thành công!', 'Thành công', 2000);
        },
        onError: () => {
          NotificationManager.error('Thêm vào giỏ hàng thất bại!', 'Thất bại', 2000);
        },
      });
    }
  };

  render() {
    const { product } = this.props;
    const { isInWishList } = this.state;

    // const RatingStars = [];
    // for (var i = 0; i < product.rating; i++) {
    //   RatingStars.push(<i className="fa fa-star" key={i} />);
    // }

    return (
      <>
        <div className="product-box">
          <div className="img-wrapper">
            <div className="front">
              <Link to={`/detail-product/${slugPath(product)}`}>
                <img
                  src={product.image ? `${product.image}` : '/assets/images/product_default.png'}
                  className="img-fluid"
                  alt={product.image_alt || product.image_name || product.name}
                  loading="lazy"
                />
              </Link>
            </div>
          </div>
          <div className="product-detail">
            <div>
              <Link to={`/detail-product/${slugPath(product)}`}>
                <h6
                  style={{ flexWrap: 'wrap', display: 'flex', width: '100%', marginTop: 10 }}
                  className="font-product">
                  {product.name}
                </h6>
              </Link>
              <div className="font-category-of-product"> {product?.unit} </div>
              <div className="font-price1">
                {/* {symbol} */}
                {product.price ? formatter.format(product.price) : 'Giá liên hệ'}
                {product.price && product.price !== product.old_price ? (
                  <div
                    className="font-price"
                    style={{
                      textDecorationLine: 'line-through',
                      display: 'inline-block',
                      color: '#363636',
                      marginLeft: '5px',
                      fontSize: '20px',
                    }}>
                    {formatter.format(product.old_price)}{' '}
                  </div>
                ) : (
                  ''
                )}
                {/* <del>
                <span className="money">
                  {symbol}
                  {product.price}
                </span>
              </del> */}
              </div>
            </div>
          </div>
          <div className="cart-info cart-wrap">
            {product.price ? (
              <button title="Add to cart" onClick={this.addItemToCart}>
                <i className="fa fa-shopping-cart cart-icon" aria-hidden="true" />
              </button>
            ) : null}
            <a
              href={null}
              title="Add to Wishlist"
              onClick={isInWishList ? this.removeWishListProduct : this.addProductToWishList}>
              <i className={`fa ${isInWishList ? 'fa-heart' : 'fa-heart-o'}`} aria-hidden="true" />
            </a>
          </div>
        </div>
        {/* })} */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accountWishList: state.accountReducer.accountWishList.list,
  wishListProduct: state.accountReducer.accountWishList.wishListProducts,
  token: state.accountReducer.token,
});

const mapDispatchToProps = (dispatch) => ({
  getWishListProductInfo: (string) => dispatch(accountActions.getWishListProductInfo(string)),
  getWishListProductInfoIfNeed: (string) => dispatch(accountActions.getWishListProductInfoIfNeed(string)),
  addProductToWishList: (payload, meta) => dispatch(accountActions.addProductToWishList(payload, meta)),
  removeWishListProduct: (productCode, meta) => dispatch(accountActions.removeWishListProduct(productCode, meta)),
  addItemToCart: (payload, meta) => dispatch(cartActions.addItemToCart(payload, meta)),
  toggleLoginModal: () => dispatch(authActions.toggleLoginModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductListItem);
