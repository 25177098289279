import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import UniverseCookies from '../utils/cookies';
import { actions as accountActions } from './AccountRedux';
import { actions as stationActions } from './StationRedux';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const _TYPES = typesWithPrefix(PREFIX.AUTH);
const TYPES = {
  GUEST_LOGIN: _TYPES('GUEST_LOGIN'),
  SEND_PHONE: _TYPES('SEND_PHONE'),
  LOG_IN: _TYPES('LOG_IN'),
  LOG_OUT: _TYPES('LOG_OUT'),
  INVALIDATE_LOG: _TYPES('INVALIDATE_LOG'),
  UPDATE_TOKEN: _TYPES('UPDATE_TOKEN'),
  TOGGLE_LOGIN_MODAL: _TYPES('TOGGLE_LOGIN_MODAL'),
};

export const actions = {
  guestLoggingIn: () => ({
    type: TYPES.GUEST_LOGIN,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLING] },
  }),
  guestLoginSuccess: (payload, stationCode) => ({
    type: TYPES.GUEST_LOGIN,
    payload,
    stationCode,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_SUCCESS] },
  }),
  guestLoginFailure: (payload) => ({
    type: TYPES.GUEST_LOGIN,
    payload,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_FAILURE] },
  }),
  guestLogin: (stationCode, meta) => async (dispatch) => {
    const api = API_URLS.CUSTOMER.guestLogin(stationCode);
    dispatch(actions.guestLoggingIn());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.guestLoginSuccess(response.data, stationCode));
      UniverseCookies.saveStationCode(stationCode || '');
      dispatch(stationActions.getStationDetail(stationCode));
      if (meta && meta.onSuccess) meta.onSuccess();
    } else {
      dispatch(actions.guestLoginFailure(error));
      if (meta && meta.onFailure) meta.onFailure();
    }
  },
  sendingPhone: () => ({
    type: TYPES.SEND_PHONE,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLING] },
  }),
  sendPhoneSuccess: (payload, phoneNumber) => ({
    type: TYPES.SEND_PHONE,
    payload,
    phoneNumber,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_SUCCESS] },
  }),
  sendPhoneFailure: (payload) => ({
    type: TYPES.SEND_PHONE,
    payload,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_FAILURE] },
  }),
  sendPhone: (payload, meta) => async (dispatch) => {
    const api = API_URLS.CUSTOMER.sendPhone({ phone: payload });
    dispatch(actions.sendingPhone());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.sendPhoneSuccess(response.data, payload.phone));
      if (meta && meta.onSuccess) meta.onSuccess();
    } else {
      dispatch(actions.logInFailure(error));
      if (meta && meta.onFailure) meta.onFailure();
    }
  },
  loggingIn: () => ({
    type: TYPES.LOG_IN,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLING] },
  }),
  logInSuccess: (payload) => ({
    type: TYPES.LOG_IN,
    payload,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_SUCCESS] },
  }),
  logInFailure: (payload) => ({
    type: TYPES.LOG_IN,
    payload,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_FAILURE] },
  }),
  login: (payload, meta) => async (dispatch) => {
    const api = API_URLS.CUSTOMER.login(payload);
    dispatch(actions.loggingIn);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data && response.data.data && response.data.token) {
      dispatch(actions.logInSuccess(response));
      dispatch(accountActions.getAccountInformation());
      if (meta && meta.onSuccess) meta.onSuccess(response.data.data);
    } else {
      dispatch(actions.logInFailure(error));
      if (meta && meta.onFailure) meta.onFailure();
    }
  },
  changePassword: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ACCOUNT.changePassword();
    dispatch(actions.changingPassword());
    const { response, error } = await apiCall({ ...api, payload });
    if (!error && response.status === 200) {
      dispatch(actions.changePasswordSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.changePasswordFailure());
      if (meta && meta.onSuccess) {
        meta.onError(error);
      }
    }
  },

  logOut: () => (dispatch) => {
    UniverseCookies.removeToken();
    dispatch(actions.logOutSuccess());
    const stationCode = UniverseCookies.getStationCode();
    dispatch(
      actions.guestLogin(stationCode, {
        onSuccess: () => {
          window.location.reload();
        },
      }),
    );
  },
  logOutSuccess: () => ({
    type: TYPES.LOG_OUT,
    meta: { prefix: [PREFIX.AUTH] },
  }),
  changingPassword: () => ({
    type: TYPES.CHANGE_PASSWORD,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLING] },
  }),
  changePasswordSuccess: (payload) => ({
    type: TYPES.CHANGE_PASSWORD,
    payload: { ...payload },
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_SUCCESS] },
  }),
  changePasswordFailure: () => ({
    type: TYPES.CHANGE_PASSWORD,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_FAILURE] },
  }),
  invalidateLog: () => ({
    type: TYPES.INVALIDATE_LOG,
    meta: { prefix: [PREFIX.AUTH] },
  }),
  updateToken: (token) => ({
    type: TYPES.UPDATE_TOKEN,
    payload: token,
    meta: { prefix: [PREFIX.AUTH] },
  }),
  toggleLoginModal: () => ({
    type: TYPES.TOGGLE_LOGIN_MODAL,
    meta: { prefix: [PREFIX.AUTH] },
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  isAuthenticated: false,
  authUser: {},
  role: {},
  phoneNumber: '',
  otp: '',
  showLoginModal: false,
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPES.GUEST_LOGIN:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          UniverseCookies.saveToken(action?.payload?.token || '');
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
        }
        break;
      case TYPES.SEND_PHONE:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.isAuthenticated = true;
          draft.isFetching = false;
          draft.otp = action.payload.message;
          draft.phoneNumber = action.phoneNumber;
        } else if (isFailedApiCall(action)) {
          draft.isAuthenticated = false;
          draft.isFetching = false;
        }
        break;
      case TYPES.LOG_IN:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { data } = action.payload;
          UniverseCookies.saveToken(data.token);
          draft.isAuthenticated = true;
          draft.isFetching = false;
          draft.showLoginModal = false;
          // eslint-disable-next-line no-undef
          draft.authUser = data.data;
        } else if (isFailedApiCall(action)) {
          draft.isAuthenticated = false;
          draft.isFetching = false;
        }
        break;
      case TYPES.LOG_OUT:
        return initialState;
      case TYPES.INVALIDATE_LOG:
        draft.didInvalidate = false;
        break;
      case TYPES.UPDATE_TOKEN:
        draft.authUser.token = action.payload;
        break;
      case TYPES.TOGGLE_LOGIN_MODAL:
        draft.showLoginModal = !state.showLoginModal;
        break;
      default:
        return draft;
    }
  });
