import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';

import { actions as accountActions } from '../../../redux/AccountRedux';
import EmptyNotification from './empty-notification';
import Notification from './notification';

class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageCount: 0,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const { meta } = nextProps;
    if (meta.total) {
      return { pageCount: Math.ceil(meta.total / 8) };
    }
    return null;
  }

  componentDidMount = () => {
    this.props.getAccountNotifications({page: 1, page_size: 8});
  }

  handlePageClick = (data) => {
    this.props.getAccountNotifications({ page: data.selected + 1, page_size: 8 });
  };

  render() {
    const { accountNotifications, userId, setNotiReadStatus } = this.props;
    return (
      <div>
       <div className="font-header" style={{ marginBottom: '50px' }}>
          Thông báo của tôi
        </div>
        { accountNotifications.length ? 
          (
            <div>
              {accountNotifications.map(item => (
                <Notification notification={item} userId={userId} setNotiReadStatus={setNotiReadStatus}/>
              ))}
              <div className={'pagination-box'}>
                <ReactPaginate
                  previousLabel={'←'}
                  nextLabel={'→'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}/>
              </div>  
            </div>
          )
        : (<EmptyNotification />)}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
({
  accountNotifications: state.accountReducer.accountNotifications.list,
  meta: state.accountReducer.accountNotifications.meta,
  userId: state.accountReducer.accountInformation.id,
})

const mapDispatchToProps = (dispatch) => ({
  getAccountNotifications: (params) => dispatch(accountActions.getAccountNotifications(params)),
  setNotiReadStatus: (code) => dispatch(accountActions.setNotiReadStatus(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
