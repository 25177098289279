import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions as cartActions } from '../../../redux/CartRedux';
import Cart from './cart';
import EmptyCart from './empty-cart';
class CartPage extends Component {
  state = {};

  componentDidMount = () => {
    this.props.getItemsInCart();
  };

  removeFromCart = (code) => {
    this.props.removeFromCart(code);
  };

  updateCartItem = (qty, code) => {
    const payload = {
      product_code: code,
      quantity: qty,
    };
    this.props.addItemToCart(payload);
  };

  render() {
    const cartList = this.props.cartItems?.map((item) => ({
      id: item?.product?.code,
      code: item?.product?.code,
      name: item?.product?.name,
      pictures: [item?.product?.image?.thumbnail_url],
      qty: item?.quantity,
      price: item?.product?.price?.selling_price,
      category: item?.product?.category?.name,
    }));
    if (cartList && cartList.length) {
      cartList.sort((a, b) => {
        const keyA = a.price,
          keyB = b.price;
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
    }

    return (
      <div className="container" style={{ maxWidth: 1200, marginBottom: '50px' }}>
        <div className="row">
          <div className="col-12">
            <div className="font-header" style={{ marginBottom: '50px' }}>
              Giỏ hàng ({cartList?.length} sản phẩm)
            </div>
          </div>
          {cartList?.length ? (
            <Cart
              products={cartList}
              totalAmount={this.props.total_amount}
              removeFromCart={this.removeFromCart}
              updateCartItem={this.updateCartItem}
            />
          ) : (
            <div className="col-12">
              <EmptyCart />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  total_amount: state.cartReducer.total_amount,
  cartItems: state.cartReducer.items,
});

const mapDispatchToProps = (dispatch) => ({
  addItemToCart: (payload) => dispatch(cartActions.addItemToCart(payload)),
  getItemsInCart: () => dispatch(cartActions.getItemsInCart()),
  removeFromCart: (code) => dispatch(cartActions.deleteItemInCart(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CartPage));
