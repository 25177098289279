import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class OrderSuccess extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="center1">
          <img style={{ width: '20%', height: '20%', marginTop: '0px' }} src="/assets/images/OrderSuccess.png" />
          <div style={{ marginBottom: '50px', cursor: 'default' }}>
            <div className="font-below-category center1">Đặt hàng thành công</div>
            <Link to="/">
              <button className="font-continue-shopping"> Tiếp tục mua hàng </button>
            </Link>
            <Link to={`/detail-order/${this.props.orderHistory[0]?.order_number}`}>
              <button
                className="font-continue-shopping"
                style={{ color: '#FF0008', background: 'rgba(237, 28, 36, 0.15)' }}>
                {' '}
                Xem chi tiết đơn hàng{' '}
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) =>
({
  orderHistory: state.accountReducer.accountOrderHistory.list,
})

const mapDispatchToProps = (dispatch) => ({
 
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSuccess);
