import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';

import 'react-slideshow-image/dist/styles.css';
import { actions as contentActions } from '../../redux/ContentRedux';
import { actions as productActions } from '../../redux/ProductRedux';
import { getCDNImage } from '../../utils/image';
import Category from './category';
import ProductListing from './product/product-listing';
import ProductScrollListing from './product/product-scroll-listing';

class Home extends Component {
  state = {
    layoutColumns: 3,
    pageSize: 10,
  };

  componentDidMount() {
    this.props.getBanners();
    this.props.getPopups();
    this.props.getPromotionProducts({ page: 1, page_size: 5 });
    this.props.getBestSellers({ page: 1, page_size: 20 });
  }

  expandProductsList = () => {
    this.setState({ pageSize: this.state.pageSize * 2 });
  };

  render() {
    const { banners, horizontalMenuCategories, promotionProducts, bestSellers: bestSellerCodes } = this.props;
    const slideImages = banners.filter((item) => !!item.web_image);
    // slideImages = slideImages.filter(item => !!item);
    const bestSellers = bestSellerCodes?.map((item) => item.ProductCode);

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>Trạm Xanh | Trang chủ</title>
          <meta
            name="description"
            // eslint-disable-next-line max-len
            content="Trạm Xanh | Vật tư nông nghiệp"
          />
        </Helmet>
        {/*SEO Support End */}

        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div
                  style={{
                    minHeight: '400px',
                    // border: '1px solid gray',
                    width: '100%',
                    height: '100%',
                  }}>
                  <Slide id="home-slider">
                    {slideImages.map((item) => (
                      <div className="each-slide" key={item.web_image}>
                        {/* <div style={{ backgroundImage: `url(${getCDNImage(item.web_image)})` }} /> */}
                        <a href={item.url || null}>
                          <img src={getCDNImage(item.web_image)} alt={item.web_image_alt || ''} />
                        </a>
                      </div>
                    ))}
                  </Slide>
                  <div className="font-header flush-left large-spacing">
                    <h2>CÁC PHÂN NHÓM SẢN PHẨM</h2>
                  </div>
                  <div className="font-below-header flush-left" style={{ marginBottom: '10px' }}>
                    <h3>Các phân nhóm sản phẩm chi tiết của Trạm Xanh</h3>
                  </div>
                  <div className="row home-main-category">
                    {horizontalMenuCategories?.map((item, index) => (
                      <div
                        className={`col col-cat-home ${index < 3 ? 'col-cat-home-2' : ''}`}
                        key={`${item.code}-${index}`}>
                        <Category category={item} />
                      </div>
                    ))}
                  </div>
                  {bestSellers && bestSellers.length ? (
                    <>
                      <div className="font-header flush-left large-spacing">SẢN PHẨM BÁN CHẠY</div>
                      <div className="font-below-header flush-left small-spacing">
                        Các sản phẩm được mua nhiều bởi người tiêu dùng
                      </div>
                      <ProductScrollListing colSize={this.state.layoutColumns} relatedProductsCode={bestSellers} />
                      <div className="center1">
                        <Link to={`/best-sellers`} className="center1 more-product-button large-spacing center2">
                          XEM THÊM SẢN PHẨM
                        </Link>
                      </div>
                    </>
                  ) : null}
                  {promotionProducts && promotionProducts.length ? (
                    <>
                      <div className="font-header flush-left large-spacing">
                        <h2>SẢN PHẨM ƯU ĐÃI</h2>
                      </div>
                      <div className="font-below-header flush-left small-spacing">
                        <h3>Các sản phẩm được bán với giá ưu đãi nhất trong ngày</h3>
                      </div>
                      <ProductScrollListing colSize={this.state.layoutColumns} relatedProducts={promotionProducts} />
                      <div className="center1">
                        <Link to={`/promotion`} className="center1 more-product-button large-spacing center2">
                          XEM THÊM SẢN PHẨM
                        </Link>
                      </div>
                    </>
                  ) : null}
                  <div className="font-header flush-left large-spacing">
                    <h2>CÁC MẶT HÀNG</h2>
                  </div>
                  <ProductListing colSize={this.state.layoutColumns} pageSize={this.state.pageSize} />
                  <div className="center1">
                    <button
                      className="center1 more-product-button large-spacing center2"
                      onClick={this.expandProductsList}
                      style={{ cursor: 'pointer' }}>
                      XEM THÊM SẢN PHẨM
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  banners: state.contentReducer.banner.list,
  popups: state.contentReducer.popup.list,
  horizontalMenuCategories: state.categoriesReducer.horizontalMenuCategories,
  promotionProducts: state.productReducer.promotionProducts?.list || [],
  bestSellers: state.productReducer.bestSellers?.list || [],
});

const mapDispatchToProps = (dispatch) => ({
  getBanners: () => {
    dispatch(contentActions.getBanners());
  },
  getPopups: () => {
    dispatch(contentActions.getPopups());
  },
  getPromotionProducts: (params) => dispatch(productActions.getPromotionProducts(params)),
  getBestSellers: (params) => dispatch(productActions.getBestSellers(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
