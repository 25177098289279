import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../constants/colors';
import { ORDER_STATUS } from '../../../constants/enum';

const style1 = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
  marginTop: '20px',
};
const style2 = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
};
const style3 = {
  width: '20%',
  textAlign: 'right',
  marginRight: '32px',
};
const style4 = {
  marginLeft: '20px',
  width: '80%',
};

const style5 = {
  width: '20%',
  textAlign: 'right',
  marginRight: '32px',
  color: COLORS.primary,
};
const style6 = {
  width: '20%',
  textAlign: 'right',
  marginRight: '32px',
  color: '#FF0000',
};
const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});
class DetailHistory extends Component {
  state = {};
  render() {
    const { invoice } = this.props;
    return (
      <div>
        <div
          style={{
            border: '1px solid #E3E3E3',
            width: '100%',
            height: '150px',
            marginBottom: '30px',
            boxSizing: 'border-box',
            borderRadius: '4px',
          }}>
          <div style={{ height: '20%', background: '#D9F2E5' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                // border: '1px solid green',
                height: '30px',
              }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="font-product" style={{ marginLeft: '20px', width: '90%' }}>
                  Mã đơn hàng: #{invoice.order_number} ({ORDER_STATUS[invoice.order_state]})
                </div>
                <Link
                  className="font-category-tree"
                  style={{ justifyContent: 'flex-end', width: '10%', color: 'black' }}
                  to={`/detail-order/${invoice.order_number}`}>
                  {' '}
                  Chi tiết
                  <img
                    src="/assets/images/RightArrow.png"
                    style={{ height: '12px', marginLeft: '5px', marginBottom: '2px' }}
                  />
                </Link>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '120px',
              }}>
              <div style={style1}>
                <div className="font-category-tree" style={style4}>
                  Tổng tiền:
                </div>
                <div className="font-category-tree" style={style6}>
                  {formatter.format(invoice.payment_amount)}
                </div>
              </div>
              <div style={style2}>
                <div className="font-category-tree" style={style4}>
                  Chiết khấu:
                </div>
                <div className="font-category-tree" style={style5}>
                  {formatter.format(invoice.total_sale_off)}
                </div>
              </div>
              <div style={style2}>
                <div className="font-category-tree" style={style4}>
                  Ngày đặt hàng:
                </div>
                <div className="font-history" style={style3}>
                  {moment(invoice.checkout_at).format('DD/MM/YYYY')}
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="font-category-tree" style={style4}>
                  Ngày nhận hàng:
                </div>
                <div className="font-history" style={style3}>
                  {moment(invoice.completed_at).format('DD/MM/YYYY')}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailHistory;
