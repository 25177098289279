import lodash from 'lodash';
import React, { Component } from 'react';
import Select from 'react-select';

class SelectFormItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.selectedValue || undefined,
      selectedValue: props.selectedValue || undefined,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { selectedValue } = nextProps;
    const { selectedValue: prevSelectedValue } = prevState;
    if (!lodash.isEqual(prevSelectedValue, selectedValue)) {
      return { value: selectedValue || undefined, selectedValue };
    }

    return null;
  }

  handleChange = (value) => {
    const { isMulti } = this.props;
    this.setState({
      value,
    });
    const { onChange } = this.props;
    onChange(isMulti ? this.convertToValueArray(value) : value ? value : undefined);
  };

  convertToValueArray = (value) => (value && value.length ? value.map((item) => item.key) : []);

  render() {
    const { value } = this.state;
    const { dataSource, placeholder, isMulti, disabled, styles, className, classNamePrefix, components, isSearchable } =
      this.props;

    const placeholderText = placeholder || 'Tìm kiếm';
    const options = dataSource?.map((d) => ({ value: d.code, label: d.name }));
    return (
      <Select
        styles={styles}
        classNamePrefix={classNamePrefix}
        className={className}
        isSearchable={isSearchable}
        isDisabled={disabled}
        isMulti={isMulti}
        value={value}
        placeholder={placeholderText}
        options={options}
        components={components}
        onChange={this.handleChange}
      />
    );
  }
}

export default SelectFormItem;
