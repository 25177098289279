import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { actions as authActions } from '../../../redux/AuthRedux';
import { actions as contentActions } from '../../../redux/ContentRedux';
import { SlideUpDown } from '../../../services/script';
import LogoImage from '../headers/common/logo';
import '../index.scss';
class FooterOne extends Component {
  componentDidMount() {
    const contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown('footer-title');
    } else {
      const elems = document.querySelectorAll('.footer-title');
      [].forEach.call(elems, function (elemt) {
        const el = elemt.nextElementSibling;
        el.style = 'display: block';
      });
    }
    this.props.getWebConfig();
  }

  showLoginModal = (e) => {
    if (!this.props.token) {
      e.preventDefault();
      this.props.toggleLoginModal();
    }
  };

  render() {
    const { stationDetail } = this.props;
    const stationPhone = stationDetail?.phone_number || '086.277.2966 - 0358.746.399';
    const addressObj = stationDetail?.address || {};
    const stationAddress = `${addressObj.text ? `${addressObj.text} - ` : ''}${
      addressObj?.ward?.name ? `${addressObj.ward.name} - ` : ''
    }${addressObj?.district?.name ? `${addressObj.district.name} - ` : ''}${addressObj.city?.name || ''}`;

    return (
      <footer className="footer-light">
        <section className="section-t-space section-b-space light-layout">
          <div className="container">
            <div className="row footer-theme partition-f">
              <div className="col-lg-4 col-md-6">
                <div className="footer-title footer-mobile-title">
                  <h4>Trạm Xanh</h4>
                </div>
                <div className="footer-contant">
                  <div className="footer-logo">
                    <LogoImage logo={this.props.logoName} />
                  </div>
                  <div>
                    <div className="footer-title">
                      <div className="footer-header" style={{ marginBottom: '50px' }}>
                        Tải ứng dụng trạm xanh
                      </div>
                    </div>
                    <div>
                      <a href="https://apps.apple.com/us/app/tram-xanh/id1583373711" target="blank">
                        <img
                          src="/assets/images/icon/logo/ios-download.png"
                          className="img-fluid"
                          width="160px"
                          height="80px"
                        />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=vn.anvita.xtv.sanvt" target="blank">
                        <img
                          src="/assets/images/icon/logo/android-download.png"
                          className="img-fluid"
                          width="160px"
                          height="80px"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="footer-social">
                    <ul>
                      <li>
                        <a href={'https://www.facebook.com/tramvattuthongminh'} _target="blank">
                          <i className="fa fa-facebook" aria-hidden="true" style={{ color: '#4267B2', fontSize: 20 }} />
                        </a>
                      </li>
                      <li>
                        <a href={'https://www.youtube.com/channel/UCDvu50NeJKZTQ5TMqc5StyQ'} _target="blank">
                          <i
                            className="fa fa-youtube-play"
                            aria-hidden="true"
                            style={{ color: '#FF0000', fontSize: 20 }}
                          />
                        </a>
                      </li>
                      <li>
                        <a href={'https://zalo.me/0862772966'} _target="blank">
                          <img
                            className="fa"
                            aria-hidden="true"
                            src="/assets/images/icon/zalo.svg"
                            alt="Zalo"
                            style={{ width: 22, marginTop: -8 }}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sub-title">
                  <div className="footer-title">
                    <div className="footer-header">CÔNG TY CỔ PHẦN ĐẦU TƯ VÀ PHÁT TRIỂN NÔNG NGHIỆP TRẠM XANH</div>
                  </div>
                  <div className="footer-contant">
                    <p style={{ marginTop: 12 }}>
                      <b>Địa chỉ</b>: Mặt bằng số 01, tầng 2, khu VL1A, Chợ thương mại, dịch vụ tổng hợp Trung Văn, p.
                      Trung Văn, q. Nam Từ Liêm, Tp. Hà Nội
                    </p>
                    {stationAddress ? (
                      <p>
                        <b>VPGD</b>: {stationAddress}
                      </p>
                    ) : null}
                    <p>
                      <b>Điện thoại</b>: {stationPhone}
                    </p>
                    <p>
                      <b>Mã số doanh nghiệp</b>: 0108596772 do Sở KHĐT TP. Hà Nội, cấp lần đầu ngày 22/01/2019.
                    </p>
                    <p>
                      <a href="http://online.gov.vn/Home/WebDetails/87868" target="_blank">
                        <img
                          className="img-fluid"
                          alt=""
                          title=""
                          src="/assets/images/logoSaleNoti.png"
                          style={{ maxHeight: 90 }}
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="sub-title">
                  <div className="footer-title">
                    <div className="footer-header">Chính sách bán hàng</div>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <Link to={`/detail-post/${this.props.webConfig['introduction-page']}`}>Giới thiệu</Link>
                      </li>
                      <li>
                        <Link to={`/detail-post/${this.props.webConfig['general-policy-page']}`}>
                          Chính sách & qui định chung
                        </Link>
                      </li>
                      <li>
                        <Link to={`/detail-post/${this.props.webConfig['order-policy-page']}`}>
                          Chính sách đặt hàng & thanh toán
                        </Link>
                      </li>
                      <li>
                        <Link to={`/detail-post/${this.props.webConfig['shipping-policy-page']}`}>
                          Chính sách giao hàng & nhận hàng
                        </Link>
                      </li>
                      <li>
                        <Link to={`/detail-post/${this.props.webConfig['return-policy-page']}`}>
                          Chính sách đổi/trả hàng & hoàn thanh toán
                        </Link>
                      </li>
                      <li>
                        <Link to={`/detail-post/${this.props.webConfig['security-policy-page']}`}>
                          Chính sách bảo mật
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="sub-title">
                  <div className="footer-title">
                    <div className="footer-header">Tài khoản</div>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <Link to={'/account/information'} onClick={this.showLoginModal}>
                          Tài khoản của tôi
                        </Link>
                      </li>
                      <li>
                        <Link to={'/account/notification'} onClick={this.showLoginModal}>
                          Thông báo
                        </Link>
                      </li>
                      <li>
                        <Link to={'/account/wishlists'} onClick={this.showLoginModal}>
                          Danh sách yêu thích
                        </Link>
                      </li>
                      <li>
                        <Link to={'/account/history'} onClick={this.showLoginModal}>
                          Lịch sử mua hàng
                        </Link>
                      </li>
                      <li>
                        <Link to={'/cai-dat'}>Tải app</Link>
                      </li>
                      <li>
                        <Link to={'/contact'}>Yêu cầu hỗ trợ</Link>
                      </li>
                      {/* <li>
                        <a href="#">Câu hỏi thường gặp</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sub-footer ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-end">
                  <p className="text-center">
                    <i className="fa fa-copyright" aria-hidden="true" /> 2021 Xúc tiến việt
                  </p>
                </div>
              </div>
              {/* <div className="col-sm-6 col-12">
                <div className="footer-end">
                  <p>
                    <a href="http://online.gov.vn/Home/WebDetails/87868" target="_blank">
                      <img className="img-fluid" alt="" title="" src="/assets/images/logoSaleNoti.png"/>
                    </a>
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({
  webConfig: state.contentReducer.webConfig,
  token: state.accountReducer.token,
  stationDetail: state.stationReducer.station_detail,
});

const mapDispatchToProps = (dispatch) => ({
  getWebConfig: () => dispatch(contentActions.getWebConfig()),
  toggleLoginModal: () => dispatch(authActions.toggleLoginModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterOne);
