import React from 'react';
import { Link } from 'react-router-dom';

import CartPage from '../components/common/headers/common/cart-header';
const CartContainer = ({ cartList, total, symbol, removeFromCart, totalAmount, showLoginModal, token }) => (
  <li className={`onhover-div ${token ? 'mobile-cart-logged-in' : 'mobile-cart'}`}>
    <div className="cart-qty-cls">{cartList?.length ? cartList.length : 0}</div>
    <Link to="/cart" onClick={showLoginModal}>
      <img src="/assets/images/icon/cart.png" className="img-fluid" alt="" />
      <i className="fa fa-shopping-cart" />
    </Link>
    <ul className="show-div shopping-cart">
      {cartList?.map((item, index) => (
        <CartPage
          key={index}
          item={item}
          total={total}
          symbol={symbol}
          removeFromCart={() => removeFromCart(item.code)}
        />
      ))}
      {cartList?.length > 0 ? (
        <div>
          <li>
            <div className="total">
              <h5>
                Tổng Cộng: {totalAmount} ₫
                <span>
                  {symbol}
                  {total}
                </span>
              </h5>
            </div>
          </li>
          <li>
            <div className="buttons">
              <Link to="/cart" className="view-cart">
                Giỏ hàng
              </Link>
              <Link to="/checkout" className="checkout">
                Thanh toán
              </Link>
            </div>
          </li>
        </div>
      ) : (
        <li>
          <h5>Giỏ hàng đang trống.</h5>
        </li>
      )}
    </ul>
  </li>
);

export default CartContainer;
