import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class EmptyCart extends Component {
  state = {};
  render() {
    return (
      <div className="center1">
        <img style={{ width: '30%', height: '30%', marginTop: '0px' }} src="/assets/images/EmptyCart.png" />
        <div>
          <div className="font-below-category">Không có sản phẩm nào trong giỏ hàng của bạn cả!!!!!! </div>
          <Link to="/">
            <button style={{ marginBottom: '60px' }} className="font-continue-shopping">
              {' '}
              Mua Hàng{' '}
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default EmptyCart;
