import produce from 'immer';
// import { NotificationManager } from 'react-notifications';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import UniverseCookies from '../utils/cookies';
import { actions as authActions } from './AuthRedux';
import { actions as stationActions } from './StationRedux';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { API_CALLING, API_CALLED_SUCCESS, API_CALLED_FAILURE, ACCOUNT } = PREFIX;
const _types = typesWithPrefix(ACCOUNT);
const TYPES = {
  GET_ACCOUNT_INFORMATION: _types('GET_ACCOUNT_INFORMATION'),
  UPDATE_ACCOUNT_INFORMATION: _types('UPDATE_ACCOUNT_INFORMATION'),
  GET_ACCOUNT_ORDER_HISTORY: _types('GET_ACCOUNT_ORDER_HISTORY'),
  GET_ACCOUNT_WISH_LIST: _types('GET_ACCOUNT_WISH_LIST'),
  GET_WISH_LIST_PRODUCT_INFO: _types('GET_WISH_LIST_PRODUCT_INFO'),
  REMOVE_WISH_LIST_PRODUCT: _types('REMOVE_WISH_LIST_PRODUCT'),
  ADD_RPODUCT_TO_WISH_LIST: _types('ADD_RPODUCT_TO_WISH_LIST'),
  CLEAR_WISH_LIST: _types('CLEAR_WISH_LIST'),
  GET_ACCOUNT_NOTIFICATIONS: _types('GET_ACCOUNT_NOTIFICATIONS'),
  SET_NOTI_READ_STATUS: _types('SET_NOTI_READ_STATUS'),
};

export const actions = {
  // *INFO* //
  // GET ACCOUNT INFO
  getAccountInformation: () => async (dispatch) => {
    const token = UniverseCookies.getToken();
    if (!token) {
      return;
    }
    const api = API_URLS.ACCOUNT.INFORMATION.getAccountInformation();
    dispatch(actions.gettingAccountInformation());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data && response.data.token && response.data.data) {
      const { data, token } = response.data;
      if (data.station_code) {
        UniverseCookies.saveStationCode(data.station_code);
        dispatch(stationActions.getStationDetail(data.station_code));
      }
      dispatch(
        actions.getAccountInformationSuccess({
          account_information: data,
          token,
        }),
      );
    } else if (error && error?.status === 401) {
      dispatch(authActions.logOut());
      // NotificationManager.error('Phiên đăng nhập đã hết hạn', '', 2000);
    } else {
      // console.log('response', error);
      dispatch(actions.getAccountInformationFailure());
    }
  },
  gettingAccountInformation: () => ({
    type: TYPES.GET_ACCOUNT_INFORMATION,
    meta: { prefix: [ACCOUNT, API_CALLING] },
  }),
  getAccountInformationSuccess: (payload) => ({
    type: TYPES.GET_ACCOUNT_INFORMATION,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_SUCCESS],
    },
  }),
  getAccountInformationFailure: (payload) => ({
    type: TYPES.GET_ACCOUNT_INFORMATION,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_FAILURE],
    },
  }),

  // UPDATE ACCOUNT
  updateAccountInfo: (rawPayload, meta) => async (dispatch, getState) => {
    const { accountReducer } = getState();
    const { accountInformation } = accountReducer;
    const payload = {
      address: accountInformation.address,
      default_shipping_address: accountInformation.default_shipping_address,
      email: accountInformation.email,
      gender: accountInformation.gender,
      referral_code: accountInformation.referral_code,
      station_code: accountInformation.station_code,
      name: accountInformation.name,
      ...rawPayload,
      phone: accountInformation.phone,
    };
    const api = API_URLS.ACCOUNT.INFORMATION.updateAccountInfo(payload);
    dispatch(actions.updatingAccountInfo());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data) {
      dispatch(actions.updateAccountInfoSuccess(response.data));
      dispatch(authActions.updateToken(response.data.token));
      if (response.data?.data?.station_code) {
        UniverseCookies.saveStationCode(response.data.data.station_code);
        dispatch(stationActions.getStationDetail(response.data.data.station_code));
      }
      // dispatch(actions.getAccountInfos({ page: 1, page_size: 10 }));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.updateAccountInfoFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  updatingAccountInfo: () => ({
    type: TYPES.UPDATE_ACCOUNT_INFORMATION,
    meta: { prefix: [ACCOUNT, PREFIX.API_CALLING] },
  }),
  updateAccountInfoSuccess: (payload) => ({
    type: TYPES.UPDATE_ACCOUNT_INFORMATION,
    meta: {
      prefix: [ACCOUNT, PREFIX.API_CALLED_SUCCESS],
    },
    payload,
  }),
  updateAccountInfoFailure: () => ({
    type: TYPES.UPDATE_ACCOUNT_INFORMATION,
    meta: {
      prefix: [ACCOUNT, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // *ORDER* //
  // GET ACCOUNT INVOICE HISTORY
  getAccountOrderHistory: (params) => async (dispatch) => {
    const token = UniverseCookies.getToken();
    if (!token) {
      return;
    }
    const api = API_URLS.ACCOUNT.ORDER.getAccountOrderHistory(params);
    dispatch(actions.gettingAccountOrderHistory());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      dispatch(
        actions.getAccountOrderHistorySuccess({
          account_order_history: data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
        }),
      );
    } else {
      dispatch(actions.getAccountOrderHistoryFailure());
    }
  },
  gettingAccountOrderHistory: () => ({
    type: TYPES.GET_ACCOUNT_ORDER_HISTORY,
    meta: { prefix: [ACCOUNT, API_CALLING] },
  }),
  getAccountOrderHistorySuccess: (payload) => ({
    type: TYPES.GET_ACCOUNT_ORDER_HISTORY,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_SUCCESS],
    },
  }),
  getAccountOrderHistoryFailure: (payload) => ({
    type: TYPES.GET_ACCOUNT_ORDER_HISTORY,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_FAILURE],
    },
  }),

  // *WISH LIST* //
  // GET ACCOUNT WISH LIST
  getAccountWishList: (params) => async (dispatch) => {
    const token = UniverseCookies.getToken();
    if (!token) {
      return;
    }
    const api = API_URLS.ACCOUNT.WISH_LIST.getAccountWishList(params);
    dispatch(actions.gettingAccountWishList());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      dispatch(
        actions.getAccountWishListSuccess({
          accountWishList: data,
        }),
      );
    } else {
      dispatch(actions.getAccountWishListFailure());
    }
  },
  gettingAccountWishList: () => ({
    type: TYPES.GET_ACCOUNT_WISH_LIST,
    meta: { prefix: [ACCOUNT, API_CALLING] },
  }),
  getAccountWishListSuccess: (payload) => ({
    type: TYPES.GET_ACCOUNT_WISH_LIST,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_SUCCESS],
    },
  }),
  getAccountWishListFailure: (payload) => ({
    type: TYPES.GET_ACCOUNT_WISH_LIST,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_FAILURE],
    },
  }),

  // GET ACCOUNT WISH LIST PRODUCT INFO
  getWishListProductInfoIfNeed: (string) => async (dispatch, getState) => {
    const { accountReducer } = getState();
    const isFetching = accountReducer.accountWishList.isFetching;
    const didInvalidate = accountReducer.accountWishList.didInvalidate;
    const isWishListChanged =
      accountReducer.accountWishList.list.length !== accountReducer.accountWishList.wishListProducts.length;
    if ((!isFetching && didInvalidate) || (!isFetching && isWishListChanged)) {
      dispatch(actions.getWishListProductInfo(string));
    }
  },

  getWishListProductInfo: (string) => async (dispatch) => {
    const api = API_URLS.PRODUCT.getProductsByStringParams(string);
    dispatch(actions.gettingishListProductInfo());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      dispatch(
        actions.getWishListProductInfoSuccess({
          wishListProducts: data,
        }),
      );
    } else {
      dispatch(actions.getWishListProductInfoFailure());
    }
  },
  gettingishListProductInfo: () => ({
    type: TYPES.GET_WISH_LIST_PRODUCT_INFO,
    meta: { prefix: [ACCOUNT, API_CALLING] },
  }),
  getWishListProductInfoSuccess: (payload) => ({
    type: TYPES.GET_WISH_LIST_PRODUCT_INFO,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_SUCCESS],
    },
  }),
  getWishListProductInfoFailure: (payload) => ({
    type: TYPES.GET_WISH_LIST_PRODUCT_INFO,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_FAILURE],
    },
  }),

  // REMOVE WISH LIST PRODUCT
  removeWishListProduct: (productCode, meta) => async (dispatch) => {
    const api = API_URLS.ACCOUNT.WISH_LIST.removeWishListPorduct(productCode);
    dispatch(actions.removingWishListProduct());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.removeWishListProductSuccess());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.removeWishListProductFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  removingWishListProduct: () => ({
    type: TYPES.REMOVE_WISH_LIST_PRODUCT,
    meta: { prefix: [ACCOUNT, API_CALLING] },
  }),
  removeWishListProductSuccess: (payload) => ({
    type: TYPES.REMOVE_WISH_LIST_PRODUCT,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_SUCCESS],
    },
  }),
  removeWishListProductFailure: (payload) => ({
    type: TYPES.REMOVE_WISH_LIST_PRODUCT,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_FAILURE],
    },
  }),

  // ADD PRODUCT TO WISH LIST
  addProductToWishList: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ACCOUNT.WISH_LIST.addProductToWishList(payload);
    dispatch(actions.addingProductToWishList());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.addProductToWishListSuccess());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.addProductToWishListFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  addingProductToWishList: () => ({
    type: TYPES.ADD_RPODUCT_TO_WISH_LIST,
    meta: { prefix: [ACCOUNT, API_CALLING] },
  }),
  addProductToWishListSuccess: (payload) => ({
    type: TYPES.ADD_RPODUCT_TO_WISH_LIST,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_SUCCESS],
    },
  }),
  addProductToWishListFailure: (payload) => ({
    type: TYPES.ADD_RPODUCT_TO_WISH_LIST,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_FAILURE],
    },
  }),

  // CLEAR ACCOUNT WISHLIST
  clearWishList: () => ({
    type: TYPES.CLEAR_WISH_LIST,
    meta: { prefix: [ACCOUNT, API_CALLING] },
  }),

  // *NOTIFICATION* //
  // GET ACCOUNT NOTIFICAITIONS
  getAccountNotifications: (params) => async (dispatch) => {
    const api = API_URLS.ACCOUNT.NOTIFICATION.getAccountNotifications(params);
    dispatch(actions.gettingAccountNotifications());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      dispatch(
        actions.getAccountNotificationsSuccess({
          account_notifications: data,
          meta: {
            page: response.data.metaData.page,
            page_size: response.data.metaData.page_size,
            total: response.data.metaData.total,
          },
        }),
      );
    } else {
      dispatch(actions.getAccountNotificationsFailure());
    }
  },
  gettingAccountNotifications: () => ({
    type: TYPES.GET_ACCOUNT_NOTIFICATIONS,
    meta: { prefix: [ACCOUNT, API_CALLING] },
  }),
  getAccountNotificationsSuccess: (payload) => ({
    type: TYPES.GET_ACCOUNT_NOTIFICATIONS,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_SUCCESS],
    },
  }),
  getAccountNotificationsFailure: (payload) => ({
    type: TYPES.GET_ACCOUNT_NOTIFICATIONS,
    payload,
    meta: {
      prefix: [ACCOUNT, API_CALLED_FAILURE],
    },
  }),

  //SET NOTI READ STATUS
  setNotiReadStatus: (code) => async (dispatch, getState) => {
    const { accountReducer } = getState();
    const meta = accountReducer.accountNotifications.meta;
    const api = API_URLS.ACCOUNT.NOTIFICATION.setNotiReadStatus(code);
    dispatch(actions.settingNotiReadStatus());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.setNotiReadStatusSuccess());
      dispatch(actions.getAccountNotifications({ page: meta.page, page_size: meta.page_size }));
    } else {
      dispatch(actions.setNotiReadStatusFailure());
    }
  },
  settingNotiReadStatus: () => ({
    type: TYPES.SET_NOTI_READ_STATUS,
    meta: { prefix: [ACCOUNT, PREFIX.API_CALLING] },
  }),
  setNotiReadStatusSuccess: () => ({
    type: TYPES.SET_NOTI_READ_STATUS,
    meta: {
      prefix: [ACCOUNT, PREFIX.API_CALLED_SUCCESS],
    },
  }),
  setNotiReadStatusFailure: () => ({
    type: TYPES.SET_NOTI_READ_STATUS,
    meta: {
      prefix: [ACCOUNT, PREFIX.API_CALLED_FAILURE],
    },
  }),
};

const initialState = {
  accountInformation: {},
  token: null,
  isFetching: false,
  accountOrderHistory: {
    list: [],
    meta: {
      page: 1,
      page_size: 4,
      total: 0,
    },
    isFetching: false,
  },
  accountWishList: {
    list: [],
    wishListProducts: [],
    didInvalidate: true,
    isFetching: false,
  },
  accountNotifications: {
    list: [],
    meta: {
      page: 1,
      page_size: 4,
      total: 0,
    },
    isFetching: false,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPES.GET_ACCOUNT_INFORMATION:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { account_information, token } = action.payload;
          draft.accountInformation = account_information;
          if (token) {
            draft.token = token;
            UniverseCookies.saveToken(token);
          }

          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
        }
        break;
      case TYPES.UPDATE_ACCOUNT_INFORMATION:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { data, token } = action.payload;
          draft.accountInformation = data;
          if (token) {
            draft.token = token;
            UniverseCookies.saveToken(token);
          }

          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
        }
        break;

      case TYPES.GET_ACCOUNT_ORDER_HISTORY:
        if (isCallingApi(action)) {
          draft.accountOrderHistory.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { account_order_history, meta } = action.payload;
          draft.accountOrderHistory.list = account_order_history;
          draft.accountOrderHistory.meta.page = meta.page;
          draft.accountOrderHistory.meta.page_size = meta.page_size;
          draft.accountOrderHistory.meta.total = meta.total;
          draft.accountOrderHistory.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.accountOrderHistory.isFetching = false;
        }
        break;

      case TYPES.GET_ACCOUNT_WISH_LIST:
        if (isCallingApi(action)) {
          draft.accountWishList.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { accountWishList } = action.payload;
          draft.accountWishList.list = accountWishList;

          draft.accountWishList.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.accountWishList.isFetching = false;
        }
        break;

      case TYPES.GET_WISH_LIST_PRODUCT_INFO:
        if (isCallingApi(action)) {
          draft.accountWishList.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { wishListProducts } = action.payload;
          draft.accountWishList.wishListProducts = wishListProducts;
          draft.accountWishList.didInvalidate = false;
          draft.accountWishList.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.accountWishList.isFetching = false;
        }
        break;

      case TYPES.CLEAR_WISH_LIST:
        draft.accountWishList.list = [];
        draft.accountWishList.wishListProducts = [];
        break;

      case TYPES.GET_ACCOUNT_NOTIFICATIONS:
        if (isCallingApi(action)) {
          draft.accountNotifications.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { account_notifications, meta } = action.payload;
          draft.accountNotifications.list = account_notifications;
          draft.accountNotifications.meta.page = meta.page;
          draft.accountNotifications.meta.page_size = meta.page_size;
          draft.accountNotifications.meta.total = meta.total;
          draft.accountNotifications.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.accountNotifications.isFetching = false;
        }
        break;

      default:
        return draft;
    }
  });
