import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as stationActions } from '../../redux/StationRedux';
import SelectSearch from './Select';

const { getStations } = stationActions;
class StationSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSelectedValue: [],
      defaultValue: [],
    };
  }
  componentDidMount() {
    if (!this.props.station) {
      this.props.getStations({});
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.station && nextProps.station.length) {
      if (!lodash.isEqual(nextProps.selectedValue, prevState.prevSelectedValue)) {
        const nextState = { prevSelectedValue: nextProps.selectedValue };
        const { selectedValue } = nextProps;
        if (selectedValue) {
          if (typeof selectedValue === 'string') {
            nextState.defaultValue = StationSearch.getStationByCodes([selectedValue], nextProps);
          } else if (typeof selectedValue === 'object') {
            if (Array.isArray(selectedValue) && selectedValue.length) {
              if (typeof selectedValue[0] === 'string') {
                nextState.defaultValue = StationSearch.getStationByCodes(selectedValue.value, nextProps);
              } else if (typeof selectedValue[0] === 'object') {
                nextState.defaultValue = selectedValue;
              }
            } else {
              nextState.defaultValue = selectedValue;
            }
          }
        } else {
          nextState.defaultValue = [];
        }

        return nextState;
      }
    }

    return null;
  }

  static getStationByCodes = (codes, props) => {
    if (codes && codes.length) {
      const { station } = props;
      if (!station.length) {
        return [];
      }

      const defaultValue = [];
      codes.forEach((code) => {
        const staCode = code.trim();
        if (!staCode) return;

        const foundStation = station.find((p) => p.code === staCode);
        defaultValue.push(
          foundStation
            ? {
                value: staCode,
                label: `${foundStation.name}`,
              }
            : { value: staCode, label: '' },
        );
      });

      return defaultValue;
    }

    return [];
  };

  searchStationByName = async (name) => {
    const { station } = this.props;
    if (!station || !station.length) return [];

    const key = name.toLowerCase().trim();
    const foundStations = key ? station.filter((item) => item.name.toLowerCase().includes(key)) : station;
    return foundStations && foundStations.length
      ? foundStations.map((item) => ({
          key: item.code,
          label: `${item.name}`,
        }))
      : [];
  };

  render() {
    const { station, placeholder, disabled } = this.props;
    const dataSource = station;
    return (
      <SelectSearch
        {...this.props}
        placeholder={placeholder || 'Chọn trạm'}
        dataSource={dataSource}
        selectedValue={this.state.defaultValue}
        disabled={disabled}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  station: state.stationReducer.stations,
});

const mapDispatchToProps = (dispatch) => ({
  getStations: ({ params }) => dispatch(getStations(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StationSearch);
