import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';

// import { addToCart, addToWishlist, addToCompare } from '../../../actions';
import NotFoundText from '../../../components/text/NotFound';
import { actions as productActions } from '../../../redux/ProductRedux';
import ProductListItem from '../../category/product-list-item';
class ProductListing extends Component {
  constructor(props) {
    super(props);

    this.state = { limit: 10, hasMoreItems: true, pageSize: 0 };
  }

  //   componentDidMount() {
  //     this.fetchMoreItems();
  //   }

  //   fetchMoreItems = () => {
  //     if (this.state.limit >= this.props.products.length) {
  //       this.setState({ hasMoreItems: false });
  //       return;
  //     }
  //     // a fake async api call
  //     setTimeout(() => {
  //       this.setState({
  //         limit: this.state.limit + 5,
  //       });
  //     }, 3000);
  //   };
  static getDerivedStateFromProps = (props, state) => {
    if (props.pageSize && props.pageSize !== state.pageSize) {
      props.getProducts({ page: 1, page_size: props.pageSize }, 'DEFAULT');
      return { pageSize: props.pageSize, limit: props.pageSize };
    }

    return null;
  };

  render() {
    const { products, addToCart, symbol, addToWishlist, addToCompare } = this.props;
    const data = products?.map((row) => ({
      name: row.name,
      slug: row.slug,
      // category: null,
      rating: 5,
      variants: row.images?.map((item) => ({
        images: item.listing_url,
      })),
      discount: row?.price?.sale_off ? row?.price?.sale_off : 0,
      price: row?.price?.selling_price || 0,
      old_price: row?.price?.price || 0,
      category: row.category.name,
      unit: row?.unit?.primary,
      code: row.code,
      image: row.image ? row.image.listing_url : '',
      image_name: row.image ? row.image.name : '',
      image_alt: row.image ? row.image.alt : '',
    }));

    return (
      <div>
        <div className="product-wrapper-grid">
          <div className="">
            {data?.length > 0 ? (
              <InfiniteScroll
                dataLength={this.state.limit} //This is important field to render the next data
                next={this.fetchMoreItems}
                hasMore={this.state.hasMoreItems}
                // loader={<div className="loading-cls" />}
                endMessage={
                  <p className="seen-cls seen-it-cls">
                    <b>Không còn sản phẩm</b>
                  </p>
                }>
                <div className="row">
                  {data?.slice(0, this.state.limit).map((product, index) => (
                    <div
                      className={`${
                        this.props.colSize === 3
                          ? 'col-xl-20p col-6 col-sm-4 col-md-3 col-grid-box'
                          : `col-lg-${this.props.colSize} col-6 col-sm-4 col-md-3`
                      }`}
                      style={{ marginTop: 25 }}
                      key={index}>
                      <ProductListItem
                        product={product}
                        symbol={symbol}
                        onAddToCompareClicked={() => addToCompare(product)}
                        onAddToWishlistClicked={() => addToWishlist(product)}
                        onAddToCartClicked={addToCart}
                        key={index}
                      />
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <NotFoundText hideButton />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.productReducer.products,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (params, mode) => {
    dispatch(productActions.getProducts(params, mode));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);
