import React, { Component } from 'react';

class Introduction extends Component {
  state = {};
  render() {
    return (
      <div className="center1">
        <div style={{ width: '72%' }}>
          <div className="font-header" style={{ marginBottom: '30px' }}>
            Giới thiệu trạm xanh
          </div>
          <div style={{ marginBottom: '30px' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra vel purus metus a elementum suscipit amet
            sed quis. Sed arcu malesuada sit venenatis sodales viverra lectus gravida ornare. Fames purus commodo, neque
            sit convallis viverra. Sit nunc suscipit viverra nisl eu. Dolor iaculis ut gravida nisi at vulputate
            pharetra. In id aliquam enim id elit euismod. Mattis condimentum et ac arcu. Nisl enim id dui et at. At
            metus vel ut aenean sagittis rhoncus ornare pretium tristique. Vestibulum orci elementum sed duis eget
            congue ipsum gravida. Neque, a nam quis nisl, vulputate accumsan tellus. Arcu malesuada diam mauris purus
            morbi odio id vitae elementum. Proin risus adipiscing nulla sit augue. Tincidunt urna, suspendisse faucibus
            sed placerat scelerisque fames sed. Et congue tempor purus consequat. Pulvinar et elementum lacus nisl.
            Vestibulum ipsum sit mauris at aliquet. Pharetra, eu augue sit sit vel, phasellus ornare. Sit risus
            pellentesque accumsan at augue sapien curabitur eu fermentum. Sed sit dignissim etiam eros, eget nisi nisi
            justo pellentesque. Fermentum, quis ultrices at sed et mi in. Feugiat lacus egestas ullamcorper nulla duis.
            Fusce scelerisque ultrices non amet, in nec. Sed morbi eget fermentum fusce eu. Odio penatibus felis
            adipiscing sodales sagittis, pharetra, nunc mattis
          </div>
          <img
            className="center1"
            style={{ width: '100%', height: '200px' }}
            src="/assets/images/NotificationBanner.png"
          />
        </div>
      </div>
    );
  }
}

export default Introduction;
