import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { API_CALLING, API_CALLED_SUCCESS, API_CALLED_FAILURE, CONTENT } = PREFIX;
const _types = typesWithPrefix(CONTENT);
const TYPES = {
  GET_POST_DETAIL: _types('GET_POST_DETAIL'),
  GET_POSTS: _types('GET_POSTS'),
  GET_LATEST_POSTS: _types('GET_LATEST_POSTS'),
  GET_BANNERS: _types('GET_BANNERS'),
  GET_POPUPS: _types('GET_POPUPS'),
  GET_WEB_CONGIG: _types('GET_WEB_CONGIG'),
};

export const actions = {
  // *POST* //
  //GET POST DETAIL
  getPostDetail: (code) => async (dispatch) => {
    const api = API_URLS.CONTENT.getPostByCode(code);
    dispatch(actions.gettingPostDetail());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      dispatch(
        actions.getPostDetailSuccess({
          post_detail: data,
        }),
      );
    } else {
      dispatch(actions.getPostDetailFailure({}));
    }
  },
  gettingPostDetail: () => ({
    type: TYPES.GET_POST_DETAIL,
    meta: { prefix: [CONTENT, API_CALLING] },
  }),
  getPostDetailSuccess: (payload) => ({
    type: TYPES.GET_POST_DETAIL,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_SUCCESS],
    },
  }),
  getPostDetailFailure: (payload) => ({
    type: TYPES.GET_POST_DETAIL,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_FAILURE],
    },
  }),

  //GET POSTS
  getPosts: (params) => async (dispatch) => {
    // const { contentReducer } = getState();
    const api = API_URLS.CONTENT.getPosts(params);
    dispatch(actions.gettingPosts);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data, page, page_size, total } = response.data;
      if (data !== null) {
        dispatch(
          actions.getPostsSuccess({
            posts: data,
            page,
            page_size,
            total,
          }),
        );
      }
    } else {
      dispatch(actions.getPostsFailure({}));
    }
  },
  gettingPosts: () => ({
    type: TYPES.GET_POSTS,
    meta: { prefix: [CONTENT, API_CALLING] },
  }),
  getPostsSuccess: (payload) => ({
    type: TYPES.GET_POSTS,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_SUCCESS],
    },
  }),
  getPostsFailure: (payload) => ({
    type: TYPES.GET_POSTS,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_FAILURE],
    },
  }),

  //GET_LATEST_POSTS
  getLatestPosts: () => async (dispatch) => {
    // const { contentReducer } = getState();
    const api = API_URLS.CONTENT.getPosts({ page: 1, page_size: 12, type: 'news' });
    dispatch(actions.gettingLatestPosts);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data, page, page_size, total } = response.data;
      if (data !== null) {
        dispatch(
          actions.getLatestPostsSuccess({
            posts: data,
            page,
            page_size,
            total,
          }),
        );
      }
    } else {
      dispatch(actions.getLatestPostsFailure({}));
    }
  },
  gettingLatestPosts: () => ({
    type: TYPES.GET_LATEST_POSTS,
    meta: { prefix: [CONTENT, API_CALLING] },
  }),
  getLatestPostsSuccess: (payload) => ({
    type: TYPES.GET_LATEST_POSTS,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_SUCCESS],
    },
  }),
  getLatestPostsFailure: (payload) => ({
    type: TYPES.GET_LATEST_POSTS,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_FAILURE],
    },
  }),

  // *BANNER* //
  //GET BANNERS
  getBanners: () => async (dispatch) => {
    const api = API_URLS.CONTENT.getBanners();
    dispatch(actions.gettingBanners());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      if (data !== null) {
        dispatch(actions.getBannersSuccess(data));
      }
    } else {
      dispatch(actions.getBannersFailure([]));
    }
  },
  gettingBanners: () => ({
    type: TYPES.GET_BANNERS,
    meta: { prefix: [CONTENT, API_CALLING] },
  }),
  getBannersSuccess: (payload) => ({
    type: TYPES.GET_BANNERS,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_SUCCESS],
    },
  }),
  getBannersFailure: (payload) => ({
    type: TYPES.GET_BANNERS,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_FAILURE],
    },
  }),

  // *POPUP* //
  //GET POPUPS
  getPopups: () => async (dispatch) => {
    const api = API_URLS.CONTENT.getPopups();
    dispatch(actions.gettingPopups());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;
      if (data !== null) {
        dispatch(actions.getPopupsSuccess(data));
      }
    } else {
      dispatch(actions.getPopupsFailure([]));
    }
  },
  gettingPopups: () => ({
    type: TYPES.GET_POPUPS,
    meta: { prefix: [CONTENT, API_CALLING] },
  }),
  getPopupsSuccess: (payload) => ({
    type: TYPES.GET_POPUPS,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_SUCCESS],
    },
  }),
  getPopupsFailure: (payload) => ({
    type: TYPES.GET_POPUPS,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_FAILURE],
    },
  }),

  // *WEB CONFIG* //
  // GET WEB CONFIG
  getWebConfig: () => async (dispatch) => {
    const api = API_URLS.CONTENT.getWebConfig();
    dispatch(actions.gettingWebConfig());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const { data } = response.data;

      if (data !== null) {
        dispatch(actions.getWebConfigSuccess(data.config_json));
      }
    } else {
      dispatch(actions.getWebConfigFailure([]));
    }
  },
  gettingWebConfig: () => ({
    type: TYPES.GET_WEB_CONGIG,
    meta: { prefix: [CONTENT, API_CALLING] },
  }),
  getWebConfigSuccess: (payload) => ({
    type: TYPES.GET_WEB_CONGIG,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_SUCCESS],
    },
  }),
  getWebConfigFailure: (payload) => ({
    type: TYPES.GET_WEB_CONGIG,
    payload,
    meta: {
      prefix: [CONTENT, API_CALLED_FAILURE],
    },
  }),
};

const initialState = {
  post: {
    curPost: {},
    list: [],
    latest: [],
    isFetching: false,
    meta: {
      page: -1,
      page_size: -1,
      total: 0,
    },
  },
  banner: {
    list: [],
    isFetching: false,
  },
  popup: {
    list: [],
    isFetching: false,
  },
  isFetching: false,
  webConfig: {},
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // case TYPES.GET_POST_DETAIL:
      //   if (isCallingApi(action)) {
      //     draft.isFetching = true;
      //   } else if (isSuccessfulApiCall(action)) {
      //     const { station_detail } = action.payload;
      //     draft.station_detail = station_detail;

      //     draft.isFetching = false;
      //   } else if (isFailedApiCall(action)) {
      //     draft.station_detail = {};
      //     draft.isFetching = false;
      //   }
      //   break;
      case TYPES.GET_POSTS:
        if (isCallingApi(action)) {
          draft.post.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { posts, page, page_size, total } = action.payload;
          draft.post.list = posts !== null ? posts : [];
          draft.post.meta.page = posts !== null ? page : 1;
          draft.post.meta.page_size = posts !== null ? page_size : 10;
          draft.post.meta.total = posts !== null ? total : 0;
          draft.post.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.post.list = [];
          draft.post.isFetching = false;
        }
        break;
      case TYPES.GET_LATEST_POSTS:
        if (isCallingApi(action)) {
        } else if (isSuccessfulApiCall(action)) {
          const { posts } = action.payload;
          draft.post.latest = posts !== null ? posts : [];
        } else if (isFailedApiCall(action)) {
          draft.post.latest = [];
        }
        break;
      case TYPES.GET_POST_DETAIL:
        if (isCallingApi(action)) {
          draft.post.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { post_detail } = action.payload;
          draft.post.curPost = post_detail !== null ? post_detail : {};
          draft.post.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.post.curPost = {};
          draft.post.isFetching = false;
        }
        break;
      case TYPES.GET_BANNERS:
        if (isCallingApi(action)) {
          draft.banner.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const payload = action.payload;
          draft.banner.list = payload || [];
          draft.banner.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.banner.list = [];
          draft.banner.isFetching = false;
        }
        break;
      case TYPES.GET_POPUPS:
        if (isCallingApi(action)) {
          draft.popup.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const payload = action.payload;
          draft.popup.list = payload || [];
          draft.popup.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.popup.list = [];
          draft.popup.isFetching = false;
        }
        break;
      case TYPES.GET_WEB_CONGIG:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const data = action.payload;
          draft.webConfig = data !== null ? JSON.parse(data) : [];
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.webConfig = {};
          draft.isFetching = false;
        }
        break;
      default:
        return draft;
    }
  });
