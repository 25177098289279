import React, { Component } from 'react';

import Breadcrumb from '../../components/common/breadcrumb';

class Contact extends Component {
  render() {
    return (
      <div>
        <Breadcrumb title={'Liên hệ'} />

        {/*Forget Password section*/}
        <section className=" contact-page section-b-space">
          <div className="container">
            <div className="row section-b-space">
              <div className="col-lg-7 map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1861.8472979274911!2d105.78115655590994!3d21.044902592603478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xabc7b712ff3dce19!2sTrade%20Promotion%20Center%20for%20Agriculture!5e0!3m2!1sen!2s!4v1632718858352!5m2!1sen!2s"
                  allowFullScreen=""
                  loading="lazy"
                />
              </div>
              <div className="col-lg-5">
                <div className="contact-right">
                  <ul>
                    <li>
                      <div className="contact-icon">
                        <img src={`/assets/images/icon/phone.png`} alt="Generic placeholder image" />
                        <h6>Hotline</h6>
                      </div>
                      <div className="media-body">
                        <p>086.277.2966</p>
                        <p>0358.746.399</p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon" style={{ height: '130px' }}>
                        <div style={{ marginTop: '30px' }}>
                          <i className="fa fa-map-marker" aria-hidden="true" />
                          <h6>Địa chỉ</h6>
                        </div>
                      </div>
                      <div className="media-body">
                        <p>
                          Khu hội chợ triển lãm nông nghiệp Việt Nam - Bộ nông nghiệp & PTNN, Số 489 Hoàng Quốc Việt, Cổ
                          Nhuế 1, Bắc Từ Liêm, Hà Nội.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <img src={`/assets/images/icon/email.png`} alt="Generic placeholder image" />
                        <h6>Email</h6>
                      </div>
                      <div className="media-body">
                        <p>tramxanhviet@gmail.com</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <form className="theme-form" action="https://formsubmit.io/send/tramxanhviet@gmail.com" method="POST">
                  <input name="_formsubmit_id" type="text" style={{ display: 'none' }} />
                  <input name="_redirect" type="hidden" id="redirect" value="https://tramxanhviet.vn" />
                  <div className="form-row">
                    <div className="col-12 col-sm-6">
                      {/* <label htmlFor="name">Họ tên</label> */}
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Họ tên*"
                        required
                        name="1-Ho-Ten"
                      />
                      {/* <label htmlFor="phone">Số điện thoại</label> */}
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Số điện thoại*"
                        required
                        name="2-So-Dien-Thoai"
                      />
                      {/* <label htmlFor="email">Email</label> */}
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email*"
                        required
                        name="3-Email"
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      {/* <label htmlFor="content">Nội dung</label> */}
                      <textarea
                        className="form-control"
                        placeholder="Nội dung*"
                        id="content"
                        rows="10"
                        name="4-Noi-dung"
                      />
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-solid" value="Submit" type="submit">
                        Gửi nội dung
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
