import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-image-lightbox/style.css';

import 'react-tabs/style/react-tabs.scss';
// import { Link } from 'react-router-dom';
import { getCDNImage } from '../../utils/image';

class DetailsTopTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const product = this.props.product;
    const criteria = product.criteria.map((item) => getCDNImage(item.url || item.image_url));
    return (
      <section className="tab-product m-0">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <Tabs className="tab-content nav-material">
              <TabList className="nav nav-tabs nav-material">
                <Tab id="product-tab-info" key="product-tab-info" className="nav-item">
                  <span className="nav-link font-tab">
                    <i className="icofont icofont-man-in-glasses" />
                    Thông tin chi tiết
                  </span>
                  <div className="material-border" />
                </Tab>
                <Tab id="product-tab-criteria" key="product-tab-criteria" className="nav-item">
                  <span className="nav-link active font-tab">
                    <i className="icofont icofont-ui-home" />
                    Chứng nhận chất lượng
                  </span>
                  <div className="material-border" />
                </Tab>

                {/* <Tab className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-contacts" />
                    Video
                  </span>
                  <div className="material-border" />
                </Tab>
                <Tab className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-contacts" />
                    Write Review
                  </span>
                  <div className="material-border" />
                </Tab> */}
              </TabList>
              <TabPanel>
                <div dangerouslySetInnerHTML={{ __html: product.description }} style={{ marginTop: '10px' }} />
                {/* { product.supplierStatus === false ? 
                 (
                   <>
                  <div>
                    Mô tả : {product.supplierIntroduction}
                  </div>
                  <div>
                    email : {product.supplierLink_to_web}
                  </div>
                  <div>
                    Số điện thoại : {product.supplierPhone_number}
                  </div>
                  </>
                 ) : null
                 } */}
              </TabPanel>
              <TabPanel className="tab-pane fade mt-4 show active">
                <table className="table table-striped mb-0">
                  <tbody>
                    <tr>
                      <th>Tên nhà cung cấp</th>
                      <td>{product.supplierName}</td>
                    </tr>
                    {product.supplierStatus !== false ? (
                      <>
                        <tr>
                          <th>Link website nhà cung cấp</th>
                          <td>{product.supplierLink_to_web}</td>
                        </tr>
                        <tr>
                          <th>Số điện thoại nhà cung cấp</th>
                          <td>{product.supplierPhone_number}</td>
                        </tr>
                      </>
                    ) : null}
                    {product.supplierIntroduction ? (
                      <tr>
                        <th>Sơ lược</th>
                        <td dangerouslySetInnerHTML={{ __html: product.supplierIntroduction }} />
                      </tr>
                    ) : null}
                    {criteria && criteria.length ? (
                      <>
                        <tr>
                          <th>Chứng nhận chất lượng</th>
                          <td style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {criteria.map((item, index) => (
                              <div className="col-6 col-sm-3" key={item}>
                                <img
                                  className="img-fluid"
                                  src={getCDNImage(item)}
                                  alt=""
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    this.setState({ isOpen: true, photoIndex: index });
                                  }}
                                />
                              </div>
                            ))}
                          </td>
                        </tr>
                      </>
                    ) : null}
                  </tbody>
                </table>
              </TabPanel>

              {/* <TabPanel>
                <div className="mt-4 text-center">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      src="https://www.youtube.com/embed/BUWzX78Ye_8"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <form className="theme-form mt-4">
                  <div className="form-row">
                    <div className="col-md-12 ">
                      <div className="media m-0">
                        <label>Rating</label>
                        <div className="media-body ml-3">
                          <div className="rating three-star">
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="name">Name</label>
                      <input type="text" className="form-control" id="name" placeholder="Enter Your name" required />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email</label>
                      <input type="text" className="form-control" id="email" placeholder="Email" required />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="review">Review Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="review"
                        placeholder="Enter your Review Subjects"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="review">Review Title</label>
                      <textarea
                        className="form-control"
                        placeholder="Wrire Your Testimonial Here"
                        id="exampleFormControlTextarea1"
                        rows="6"
                      />
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-solid" type="submit">
                        Submit YOur Review
                      </button>
                    </div>
                  </div>
                </form>
              </TabPanel> */}
            </Tabs>
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={criteria[photoIndex]}
            nextSrc={criteria[(photoIndex + 1) % criteria.length]}
            prevSrc={criteria[(photoIndex + criteria.length - 1) % criteria.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + criteria.length - 1) % criteria.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % criteria.length,
              })
            }
          />
        )}
      </section>
    );
  }
}

export default DetailsTopTabs;
