import React, { Component } from 'react';

class EmptyList extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="center1">
          <img style={{ width: '30%', height: '30%', marginTop: '0px' }} src="/assets/images/EmptyWishList.png" />
          <div>
            <div className="font-below-category"> Không có sản phẩm nào trong yêu thích của bạn cả </div>
            <button className="font-continue-shopping"> Tiếp tục mua hàng </button>
          </div>
        </div>
      </div>
    );
  }
}

export default EmptyList;
