/* eslint-disable no-undef */
import UniverseCookies from '../utils/cookies';

export const HEADERS = {
  DEFAULT_HEADER: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    Referer: process.env.RAZZLE_TX_DOMAIN,
  },
  header: () => ({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    Authorization: UniverseCookies.getToken(),
    Referer: process.env.RAZZLE_TX_DOMAIN,
  }),
  jsonHeader: () => ({
    'Content-Type': 'application/json; charset=UTF-8',
    Authorization: UniverseCookies.getToken(),
    Referer: process.env.RAZZLE_TX_DOMAIN,
  }),
  file_header: () => ({
    'Content-Type': 'multipart/form-data',
    Authorization: UniverseCookies.getToken(),
    Referer: process.env.RAZZLE_TX_DOMAIN,
  }),
};

export const API_URLS = {
  CUSTOMER: {
    guestLogin: (stationCode) => ({
      endPoint: `/api/v1/customer/login/guest/${stationCode}`,
      method: 'POST',
      headers: HEADERS.DEFAULT_HEADER,
    }),
    sendPhone: (payload) => ({
      endPoint: '/api/v1/customer/login/otp',
      method: 'POST',
      headers: HEADERS.header(),
      payload,
    }),
    login: (payload) => ({
      endPoint: '/api/v1/customer/login',
      method: 'POST',
      headers: HEADERS.header(),
      payload,
    }),
  },
  PRODUCT: {
    getProducts: (params) => ({
      endPoint: '/api/v1/product-view/products',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_FILTERED_PRODUCTS',
      params,
    }),
    getProductsByStringParams: (string) => ({
      endPoint: `/api/v1/product-view/products?${string}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_PRODUCTS_BY_STRING_PARAMS',
    }),
    getProductDetail: (code) => ({
      endPoint: `/api/v1/product-view/products/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_PRODUCT_DETAIL',
    }),
    getCategories: (params) => ({
      endPoint: '/api/v1/product-view/categories',
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_CATEGORIES',
      params,
    }),
    getProductsByCode: (params) => ({
      endPoint: `/api/v1/product-view/products?category_codes=${params}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_FILTERED_PRODUCTS',
    }),
    getPromotionProducts: (params) => ({
      endPoint: '/api/v1/product-view/products/promotion',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_PROMOTION_PRODUCTS',
      params,
    }),
    getBestSellers: (params) => ({
      endPoint: '/api/v1/reporting/products/best-sellers',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_BEST-SELLERS',
      params,
    }),
  },
  HISTORY: {
    getHistories: (params) => ({
      endPoint: 'order/clients/orders',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_HISTORIES',
      params,
    }),
  },
  CART: {
    addItemToCart: (payload) => ({
      endPoint: '/api/v1/order/carts/items',
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'ADD_ITEM_TO_CART',
      payload,
    }),
    deleteItemInCart: (productCode) => ({
      endPoint: `/api/v1/order/carts/items/${productCode}`,
      method: 'DELETE',
      headers: HEADERS.jsonHeader(),
      name: 'DELETE_ITEM_IN_CART',
    }),
    getItemsInCart: () => ({
      endPoint: '/api/v1/order/carts',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_FILTERED_ORDERS',
    }),
    checkoutCart: () => ({
      endPoint: '/api/v1/order/carts/checkout',
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'CHECKOUT_CART',
    }),
    addNoteForCart: (payload) => ({
      endPoint: '/api/v1/order/carts/information',
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'ADD_NOTE',
      payload,
    }),
    getVouchers: () => ({
      endPoint: '/api/v1/order/carts/available-promotion',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_VOUCHER',
    }),
    applyVoucher: (payload) => ({
      endPoint: '/api/v1/order/carts/promotion',
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'APPLY_VOUCHER',
      payload,
    }),
  },
  ORDER: {
    getOrderDetail: (order_code) => ({
      endPoint: `/api/v1/order/clients/orders/${order_code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_ORDER_DETAIL',
    }),
    cancelOrder: (order_code) => ({
      endPoint: `/api/v1/order/clients/orders/${order_code}/cancel`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'CANCEL_ORDER',
    }),
    cancelItemInOrder: (payload) => ({
      endPoint: `/api/v1/order/clients/orders/${payload.order_code}/items/${payload.product_code}/cancel`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'CANCEL_ITEM_IN_ORDER',
    }),
  },
  STATION: {
    getStations: (params) => ({
      endPoint: '/api/v1/station/client/stations',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_ALL_STATION',
      params,
    }),
    getStationByCode: (station_code) => ({
      endPoint: `/api/v1/station/client/stations/${station_code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_STATION_DETAIL',
    }),
  },
  FAVOURITES: {
    getItemsInFavourites: () => ({
      endPoint: `customer/clients/me/favorite`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_ITEM_IN_FAVOURITE',
    }),
    addItemToFavourites: (product_code) => ({
      endPoint: `customer/clients/me/favorite`,
      method: 'POST',
      headers: HEADERS.jsonHeader(),
      name: 'ADD_ITEM_TO_FAVOURITES',
      payload: product_code,
    }),
    deleteItemInFavourites: (product_code) => ({
      endPoint: `customer/clients/me/favorite/${product_code}`,
      method: 'POST',
      headers: HEADERS.jsonHeader(),
      name: 'DELETE_ITEM_IN_FAVOURITES',
    }),
    deleteAllItemInFavourites: (payload) => ({
      endPoint: `customer/clients/me/favorite`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'DELETE_ITEM_IN_FAVOURITES',
      payload,
    }),
  },
  ACCOUNT: {
    INFORMATION: {
      getAccountInformation: () => ({
        endPoint: '/api/v1/customer/clients/me',
        method: 'GET',
        headers: HEADERS.header(),
        name: 'GET_ACCOUNT_INFORMATION',
      }),
      updateAccountInfo: (payload) => ({
        endPoint: `/api/v1/customer/clients`,
        method: 'PUT',
        headers: HEADERS.header(),
        name: 'EDIT_ACCOUNT_INFORMATION',
        payload,
      }),
    },
    ORDER: {
      getAccountOrderHistory: (params) => ({
        endPoint: '/api/v1/order/clients/orders',
        method: 'GET',
        headers: HEADERS.header(),
        name: 'GET_ACCOUNT_ORDER_HISTORY',
        params,
      }),
    },
    WISH_LIST: {
      getAccountWishList: () => ({
        endPoint: '/api/v1/customer/clients/me/favorite',
        method: 'GET',
        headers: HEADERS.header(),
        name: 'GET_ACCOUNT_WISH_LIST',
      }),
      removeWishListPorduct: (productCode) => ({
        endPoint: `/api/v1/customer/clients/me/favorite/${productCode}`,
        method: 'POST',
        headers: HEADERS.jsonHeader(),
        name: 'DELETE_WISH_LIST_PRODUCT',
      }),
      addProductToWishList: (payload) => ({
        endPoint: '/api/v1/customer/clients/me/favorite',
        method: 'POST',
        headers: HEADERS.header(),
        name: 'ADD_RPODUCT_TO_WISH_LIST',
        payload,
      }),
    },
    NOTIFICATION: {
      getAccountNotifications: (params) => ({
        endPoint: '/api/v1/notification/notifications/recipient',
        method: 'GET',
        headers: HEADERS.header(),
        name: 'GET_ACCOUNT_NOTIFICATIONS',
        params,
      }),
      setNotiReadStatus: (code) => ({
        endPoint: `/api/v1/notification/notifications/setReadStatus/${code}`,
        method: 'PUT',
        headers: HEADERS.jsonHeader(),
        name: 'SET_NOT_READ_STATUS',
      }),
    },
  },
  CONTENT: {
    getBanners: () => ({
      endPoint: '/api/v1/content/client/banners',
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_BANNER_CLIENT',
    }),
    getPopups: () => ({
      endPoint: '/api/v1/content/client/popups',
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_BANNER_CLIENT',
    }),
    getPosts: (params) => ({
      endPoint: '/api/v1/content/client/posts',
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_POSTS_CLIENT',
      params,
    }),
    getPostByCode: (code) => ({
      endPoint: `/api/v1/content/client/post/${code}`,
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_POST_DETAIL_CLIENT',
    }),
    getWebConfig: () => ({
      endPoint: '/api/v1/content/config',
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_WEB_CONFIG',
    }),
  },
  SUPPLIER: {
    getSuppliers: () => ({
      endPoint: '/api/v1/supplier/client/suppliers',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_SUPPLIER',
    }),
  },
  COLLABORATOR: {
    getCollaborator: (params) => ({
      endPoint: '/api/v1/order/client/collaborator_discount',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_ACCOUNT_NOTIFICATIONS',
      params,
    }),
  },
};
