// import { Range } from 'rc-slider';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import Lightbox from 'react-image-lightbox';
import { NotificationManager } from 'react-notifications';
// import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import StickyBox from 'react-sticky-box';

import { actions as accountActions } from '../../redux/AccountRedux';
import { actions as authActions } from '../../redux/AuthRedux';
import { actions as cartActions } from '../../redux/CartRedux';
import { actions as productActions } from '../../redux/ProductRedux';
import { getCDNImage } from '../../utils/image';
import { codeFromSlug } from '../../utils/product';
// import Breadcrumb from '../common/breadcrumb';
// import ProductListing from '../category/product-listing';
// import Supplier from '../category/supplier';
import CategoryTree from '../category/tree';
import DetailsTopTabs from '../detail-product/details-top-tabs';
import ImageZoom from '../detail-product/image-zoom';
// import SmallImages from '../detail-product/small-image';
import ProductScrollListing from '../home/product/product-scroll-listing';
import Button from './button';
import 'react-notifications/lib/notifications.css';
// import FilterBar from './common/filter-bar';
import 'rc-slider/assets/index.css';
import '../../index.scss';

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});

class DetailProduct extends Component {
  state = {
    layoutColumns: 2,
    value: [0, 748000],
    open: false,
    nav1: null,
    nav2: null,
    start: false,
    isInWishList: false,
    init: false,
    initClearWishList: false,
    isSelected: 0,
    photoIndex: 0,
    isLightboxOpen: false,
  };

  onClickButton = (value) => {
    this.setState({ currentQuantity: value });
  };
  handleChange = (event) => {
    this.setState({
      currentQuantity: Number.parseInt(event.target.value, 10),
      currentInput: event.target.value,
      isSelected: null,
    });
  };
  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  openFilter = () => {
    document.querySelector('.collection-filter').style = 'left: -15px';
  };

  closeFilter = () => {
    document.querySelector('.collection-filter').style = 'left: -370px';
  };

  onSliderChange = (value) => {
    this.setState({
      value,
    });
  };
  onSelectImage = (index) => {
    this.setState({
      photoIndex: index,
      isLightboxOpen: true,
    });
  };
  // getDerivedStateFromProps (){
  //   console.log('davaogetDerivedStateFromProps');
  //   const data = this.props.product;
  //   product = {
  //     name: data.name,
  //     supplier: data.supplier ? data.supplier.name : '',
  //     description: data.description,
  //     variants: data.images?.map((item) => ({
  //       images : item.detail_url,
  //     })),
  //     price: data.price ? data.price.price : 0,
  //     option: data.options?.map((item) => ({
  //       quantity: item.quantity,
  //       unit: item.unit,
  //     })),
  //   }
  //   this.setState({start: true});
  //   console.log('product-detail1', data.images);
  // }
  // componentWillReceiveProps(){
  //   const data = this.props.product;
  //   product = {
  //     name: data.name,
  //     supplier: data.supplier ? data.supplier.name : '',
  //     description: data.description,
  //     variants: data.images?.map((item) => ({
  //       images : item.detail_url,
  //     })),
  //     price: data.price ? data.price.price : 0,
  //     option: data.options?.map((item) => ({
  //       quantity: item.quantity,
  //       unit: item.unit,
  //     })),
  //   }
  //   console.log('product-detail2', data.images);
  // }

  static getDerivedStateFromProps = (props, state) => {
    if (!state.initClearWishList) {
      props.clearWishList();
      return { initClearWishList: true };
    }
    if (props.accountWishList) {
      let string = `page=1&page_size=20`;
      for (let i = 0; i < props.accountWishList.length; i++) {
        string += `&product_codes=${props.accountWishList[i]?.product_code}`;
      }
      if (string !== `page=1&page_size=20`) {
        props.getWishListProductInfoIfNeed(string);
      }
    }
    if (props.wishListProduct) {
      const product = props.wishListProduct.find((product) => product.code === props.product?.code);
      if (product && !state.init) {
        return { isInWishList: true, init: true };
      }
    }
    if (props.match.params.code && state.curCode !== props.match.params.code) {
      const productCode = codeFromSlug(props.match.params.code);
      props.getProductDetail(productCode);
      return { curCode: props.match.params.code };
    } else if (props.match.params.code && !state.currentQuantity) {
      return {
        currentQuantity:
          props.product?.options && props.product?.options.length ? props.product?.options[0].quantity : 1,
      };
    }
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      isInWishList: false,
    });
    this.props.getAccountWishList();
  }

  handleKeypress(e) {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }
  onSelect = (props) => {
    const categoryCode = props.key.split('/');
    this.props.history.push(`/category/${categoryCode[categoryCode.length - 1]}`);
  };

  addProductToWishList = () => {
    if (!this.props.token) {
      // e.preventDefault();
      this.props.toggleLoginModal();
    } else {
      this.setState({ isInWishList: true });
      const payload = {
        product_Code: this.props.product.code,
      };
      this.props.addProductToWishList(payload, {
        onSuccess: () => {
          NotificationManager.success('Thêm sản phẩm vào danh sách yêu thích thành công!', 'Thành công', 2000);
        },
        onError: () => {
          NotificationManager.error('Thêm sản phẩm vào danh sách yêu thích thất bại!', 'Thất bại', 2000);
        },
      });
    }
  };

  removeWishListProduct = () => {
    if (!this.props.token) {
      // e.preventDefault();
      this.props.toggleLoginModal();
    } else {
      this.setState({ isInWishList: false });
      this.props.removeWishListProduct(this.props.product.code, {
        onSuccess: () => {
          NotificationManager.success('Xóa sản phẩm khỏi danh sách yêu thích thành công!', 'Thành công', 2000);
        },
        onError: () => {
          NotificationManager.error('Xóa sản phẩm khỏi danh sách yêu thích thất bại!', 'Thất bại', 2000);
        },
      });
    }
  };

  addItemToCart = () => {
    if (!this.props.token) {
      // e.preventDefault();
      this.props.toggleLoginModal();
    } else {
      if (!this.state.currentQuantity) {
        NotificationManager.error('Chưa chọn số lượng!', 'Thất bại', 2000);
      } else {
        const payload = {
          product_code: this.props.product.code,
          quantity: this.state.currentQuantity,
        };
        this.props.addItemToCart(payload, {
          onSuccess: () => {
            NotificationManager.success('Thêm vào giỏ hàng thành công!', 'Thành công', 2000);
            // if (cbOnSuccess) {
            //   cbOnSuccess();
          },
          onError: () => {
            NotificationManager.error('Thêm vào giỏ hàng thất bại!', 'Thất bại', 2000);
          },
        });
      }
    }
  };

  checkoutItem = () => {
    if (!this.props.token) {
      // e.preventDefault();
      this.props.toggleLoginModal();
    } else {
      this.addItemToCart();
      this.props.history.push('/checkout');
    }
  };

  render() {
    const { currentQuantity, currentInput, isInWishList, isSelected, isLightboxOpen, photoIndex } = this.state;
    const data = this.props.product;

    // const products = {
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   dots: false,
    //   arrows: true,
    //   fade: true,
    // };
    // var productsnav = {
    //   slidesToShow: 3,
    //   swipeToSlide: true,
    //   arrows: false,
    //   dots: false,
    //   focusOnSelect: true,
    // };

    let variants = data?.image ? [data.image] : [];
    variants = data?.images ? variants.concat(data.images) : variants;

    const product = {
      name: data?.name,
      supplier: data?.supplier ? data.supplier.name : '',
      category: data?.category ? data.category.code : '',
      keywords: data?.keywords,
      short_description: data?.short_description,
      description: data?.description,
      related_products: data?.related_products ? data?.related_products : [],
      image: data?.image,
      variants,
      price: data?.price ? data.price.price : 0,
      selling_price: data?.price ? data?.price.selling_price : 0,
      option: data?.options?.map((item) => ({
        quantity: item.quantity,
        unit: item.unit,
      })),
      criteria: (data?.criteria || []).concat(data?.supplier?.criteria || []),
      supplierName: data?.supplier ? data?.supplier?.name : '',
      supplierIntroduction: data?.supplier ? data?.supplier?.introduction : '',
      supplierPhone_number: data?.supplier ? data?.supplier?.phone_number : '',
      supplierLink_to_web: data?.supplier ? data?.supplier?.contact.link_to_web : '',
      supplierStatus: data?.supplier ? data?.supplier?.contact.status : '',
      // supplierCriterion : data?.supplier ? data?.supplier?.contact.criteria: '',
      unit: {
        factor: data?.unit?.factor || '',
        primary: data?.unit?.primary || '',
        secondary: data?.unit?.secondary || '',
      },
    };

    const productDefaultOptions = [
      { quantity: 1, unit: product?.unit?.primary },
      { quantity: 2, unit: product?.unit?.primary },
      { quantity: 3, unit: product?.unit?.primary },
    ];

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          {product?.name ? (
            <>
              <title>Trạm Xanh | {product.name}</title>
              <meta name="description" content={product.short_description} />
              <meta
                name="keywords"
                content={`trạm xanh Trạm Xanh TRẠM XANH tram xanh tramxanh ${
                  product.keywords ? product.keywords.join(' ') : ''
                }`}
              />
              <meta name="og:title" content={product.name} />
              <meta name="og:type" content="product" />
              <meta name="og:description" content={product.short_description} />
              {product.image ? <meta property="og:image" content={getCDNImage(product.image.listing_url)} /> : null}
              <meta property="og:image:alt" content={product.name} />
            </>
          ) : null}
        </Helmet>
        {/*SEO Support End */}

        {/* <Breadcrumb title={'Collection'} /> */}

        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-3 collection-filter">
                  <StickyBox offsetTop={50} offsetBottom={20} style={{ padding: '0 20px' }}>
                    <div className="font-category-tree-header" style={{ marginBottom: '15px' }}>
                      Danh mục sản phẩm
                    </div>
                    <CategoryTree onSelect={this.onSelect} category={product.category} />
                    <a className="close-sidebar-filter" onClick={this.closeFilter} style={{ top: '-50px' }}>
                      <i className="fa fa-close fa-2x" aria-hidden="true" style={{ fontSize: 30 }} />
                    </a>
                  </StickyBox>
                  {/* <button> Bỏ Lọc </button> */}
                </div>
                <div className="col-12 col-lg-9">
                  <div className="page-main-content ">
                    <div className="row">
                      <div className="col-lg-5 col-md-4 col-12 product-thumbnail">
                        <Slider
                          // {...products}
                          // asNavFor={this.state.nav2}
                          // ref={(slider) => (this.slider1 = slider)}
                          className="product-slick">
                          {product.variants.map((image, index) => (
                            <div key={index} onClick={() => this.onSelectImage(index)} style={{ cursor: 'pointer' }}>
                              <ImageZoom image={image} />
                            </div>
                          ))}
                          {/* <div>
                            <ImageZoom image={product.variants} />
                          </div> */}
                          {/* {product.variants
                            ? (
                              <div>
                                <ImageZoom image={product.variants} />
                              </div>
                            )
                             : 
                            //product.pictures.map((vari, index) => (
                            //   <div key={index}>
                            //     <ImageZoom image={vari} />
                            //   </div>
                            // ))
                            null
                            } */}
                        </Slider>
                        {/* <SmallImages item={product} settings={productsnav} navOne={this.state.nav1} /> */}
                        <div className="product-top-filter section-t-space">
                          <div className="container-fluid p-0">
                            <div className="row">
                              <div className="col-xl-12">
                                <div className="filter-main-btn">
                                  <span onClick={this.openFilter} className="filter-btn btn btn-theme">
                                    <i className="fa fa-filter" aria-hidden="true" /> Phân nhóm
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                {/* <FilterBar onLayoutViewClicked={colmuns => this.LayoutViewClicked(colmuns)} /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-7 col-md-8 col-12 product-thumbnail "
                        style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="font-product-header">
                          <h1>{product.name}</h1>
                        </div>
                        <div className="font-supplier">{product.supplier} </div>
                        {product.short_description ? (
                          <div className="font-description">{product.short_description}</div>
                        ) : null}
                        <div style={{ marginBottom: '20px' }}>
                          <div className="font-price" style={{ display: 'inline-block' }}>
                            {product.selling_price ? formatter.format(product.selling_price) : 'Giá liên hệ'}
                          </div>
                          {product.selling_price && product.price !== product.selling_price ? (
                            <div
                              className="font-price"
                              style={{
                                textDecorationLine: 'line-through',
                                display: 'inline-block',
                                color: '#363636',
                                marginLeft: '5px',
                                fontSize: '20px',
                              }}>
                              {formatter.format(product.price)}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        {product.selling_price ? (
                          <>
                            <div className="font-quantity">Chọn số lượng:</div>
                            <div className="" style={{ marginBottom: 20 }}>
                              {product.option?.length
                                ? product.option.map((item, index) => (
                                    <Button
                                      onClick={() => {
                                        this.onClickButton(index);
                                        this.setState({ currentQuantity: item.quantity, isSelected: index });
                                      }}
                                      key={`${index}-${item.quantity}`}
                                      quantity={item.quantity}
                                      unit={item.unit}
                                      currentQuantity={currentQuantity}
                                      index={index}
                                      isSelected={isSelected}
                                    />
                                  ))
                                : productDefaultOptions.map((item, index) => (
                                    <Button
                                      onClick={() => {
                                        // this.onClickButton(index);
                                        this.setState({ currentQuantity: item.quantity, isSelected: index });
                                      }}
                                      key={`${index}-${item.quantity}`}
                                      quantity={item.quantity}
                                      unit={item.unit}
                                      currentQuantity={currentQuantity}
                                      index={index}
                                      isSelected={isSelected}
                                    />
                                  ))}
                              {currentInput && currentInput === currentQuantity ? (
                                <input
                                  onKeyDown={this.handleKeypress}
                                  min="1"
                                  step="1"
                                  type="number"
                                  onClick={this.handleChange}
                                  className="quantity-button2"
                                  placeholder="Nhập SL"
                                  style={{ textAlign: 'center', width: '97px' }}
                                />
                              ) : (
                                <input
                                  onKeyDown={this.handleKeypress}
                                  min="1"
                                  step="1"
                                  type="number"
                                  onClick={this.handleChange}
                                  className="quantity-button"
                                  placeholder="Nhập SL"
                                  style={{ textAlign: 'center', width: '97px' }}
                                />
                              )}
                            </div>
                          </>
                        ) : null}

                        <div>
                          {product.selling_price ? (
                            <>
                              <button className="add-to-cart-button" onClick={this.addItemToCart}>
                                thêm vào giỏ
                              </button>
                              <button className="buy-now-button" onClick={this.checkoutItem}>
                                mua ngay
                              </button>
                            </>
                          ) : null}
                          <a
                            // href="javascript:void(0)"
                            title="Add to Wishlist"
                            onClick={isInWishList ? this.removeWishListProduct : this.addProductToWishList}>
                            <i className="fa fa-heart fa-2x" style={{ color: isInWishList ? '#ED1C24' : '#717171' }} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <DetailsTopTabs product={product} />
                    </div>
                  </div>
                </div>
                {product.related_products.length ? (
                  <>
                    <div
                      className="font-category-tree-header flush-left"
                      style={{ marginTop: '50px', marginBottom: '30px' }}>
                      Sản phẩm liên quan
                    </div>
                    <div className="col-sm-12">
                      <ProductScrollListing
                        colSize={this.state.layoutColumns}
                        relatedProductsCode={product.related_products}
                      />
                    </div>
                  </>
                ) : null}
              </div>
              <div>{/* <ProductListing colSize={this.state.layoutColumns} /> */}</div>
              {isLightboxOpen && (
                <Lightbox
                  mainSrc={product.variants[photoIndex].detail_url}
                  nextSrc={product.variants[(photoIndex + 1) % product.variants.length].detail_url}
                  prevSrc={
                    product.variants[(photoIndex + product.variants.length - 1) % product.variants.length].detail_url
                  }
                  onCloseRequest={() => this.setState({ isLightboxOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + product.variants.length - 1) % product.variants.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % product.variants.length,
                    })
                  }
                />
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  product: state.productReducer.product,
  productsInCategory: state.productReducer.productsInCategory,
  accountWishList: state.accountReducer.accountWishList.list,
  wishListProduct: state.accountReducer.accountWishList.wishListProducts,
  token: state.accountReducer.token,
});
const mapDispatchToProps = (dispatch) => ({
  getProductDetail: (id) => dispatch(productActions.getProductDetail(id)),
  getProductDetailIfNeed: (id) => dispatch(productActions.getProductDetailIfNeed(id)),
  getAccountWishList: () => dispatch(accountActions.getAccountWishList()),
  getWishListProductInfo: (string) => dispatch(accountActions.getWishListProductInfo(string)),
  getWishListProductInfoIfNeed: (string) => dispatch(accountActions.getWishListProductInfoIfNeed(string)),
  addProductToWishList: (payload, meta) => dispatch(accountActions.addProductToWishList(payload, meta)),
  removeWishListProduct: (productCode, meta) => dispatch(accountActions.removeWishListProduct(productCode, meta)),
  clearWishList: () => dispatch(accountActions.clearWishList()),
  addItemToCart: (payload, meta) => dispatch(cartActions.addItemToCart(payload, meta)),
  toggleLoginModal: () => dispatch(authActions.toggleLoginModal()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DetailProduct);
