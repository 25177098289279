import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../constants/colors';
import { slugPath } from '../../../utils/product';

// import { toast } from 'react-toastify';

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});
const button1 = {
  height: '30px',
  background: 'red',
  borderRadius: '4px',
  marginLeft: '10px',
  width: '80px',
  color: 'white',
  textTransform: 'uppercase',
};
const button2 = {
  height: '30px',
  background: COLORS.primary,
  borderRadius: '4px',
};
const input = {
  height: '30px',
  textAlign: 'center',
  width: '70%',
  background: '#D9F2E5',
  border: 'none',
};
const group = {
  justifyContent: 'unset',
  marginTop: '10px',
};
const icon = {
  marginRight: '5px',
  color: 'white',
};
// const margin = {
//   marginTop: '10px', marginBottom: '10px'
// }
const total = {
  opacity: 'unset',
};
class Cart extends Component {
  state = {};
  render() {
    const { products } = this.props;
    return (
      <>
        <div className="col-12 col-md-8 cart-left">
          <div className="border-box">
            {products.map((item) => (
              <div
                key={`cart-item-${item.code}`}
                style={{ display: 'flex', padding: '10px 0', borderBottom: '1px solid #e3e3e3' }}>
                <div className="col-3" style={{ paddingRight: 0 }}>
                  <Link to={`/detail-product/${slugPath(item)}`}>
                    <img className="img-fluid" src={item.pictures[0]} />
                  </Link>
                </div>
                <div className="col-9">
                  <div className="font-product margin-bottom-10px">
                    <Link to={`/detail-product/${slugPath(item)}`} className="font-product">
                      {item.name}
                    </Link>
                  </div>
                  <div className="font-category-of-product margin-bottom-10px"> {item.category}</div>
                  <div className="font-price"> {formatter.format(item.price)}</div>
                  <div className="qty-box">
                    <div className="input-group" style={group}>
                      <div style={{ display: 'flex' }}>
                        <button style={button2} onClick={() => this.props.updateCartItem(item.qty - 1, item.code)}>
                          <i className="fa fa-minus" style={icon} />
                        </button>
                        <input type="text" name="quantity" value={item.qty} readOnly style={input} />
                        <button style={button2} onClick={() => this.props.updateCartItem(item.qty + 1, item.code)}>
                          <i className="fa fa-plus" style={icon} />
                        </button>
                        <button style={button1} onClick={() => this.props.removeFromCart(item.code)}>
                          Xoá
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-md-4 cart-right">
          <div
            className="border-box"
            style={{
              justifyContent: 'flex-start',
              display: 'flex',
              padding: '15px 0',
            }}>
            <div className="col-5 font-product" style={total}>
              Tổng tiền
            </div>
            <div className="col-7 font-price">{formatter.format(this.props.totalAmount)}</div>
          </div>
          <Link to="/checkout">
            <button style={{ color: 'white' }} className="font-continue-shopping shopping-button">
              Tiến hành đặt hàng
            </button>
          </Link>
          <Link to="/">
            <button
              style={{ color: 'white', background: '#ED1C24' }}
              className="full-size font-continue-shopping shopping-button">
              Tiếp tục mua hàng
            </button>
          </Link>
        </div>
      </>
    );
  }
}

export default Cart;
