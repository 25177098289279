import React from 'react';
import { Route, Switch } from 'react-router-dom';
// eslint-disable-next-line import/order
// lưu ý không thay đổi thứ tự của 2 file scss bên dưới
import './index.scss';
import './components/common/index.scss';

import Layout from './app-layout';
import Cart from './components/cart/CartPage';
import Checkout from './components/cart/CheckoutPage';
import DetailOrder from './components/cart/OrderPage';
import Success from './components/cart/order-success';
import Account from './screens/account/account';
import DetailNotification from './screens/account/notification/detail-notification';
import Advisory from './screens/advisory-post';
import BestSellers from './screens/best-sellers';
import Category from './screens/category/index';
// import CategoryDetail from './screens/category/Category-detail';
import Contact from './screens/contact';
import DetailProduct from './screens/detail-product/index';
import Introduction from './screens/footer-page/introduction';
import Home from './screens/home/home';
import Installation from './screens/installation';
import Login from './screens/login/login';
import Posts from './screens/post';
import DetailPost from './screens/post/detail-post';
import Promotion from './screens/promotion';
import Search from './screens/search';
import Videos from './screens/video';

class App extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Layout>
            {/* <Route path="/category" component={Category} /> */}
            <Route path="/category/:code" component={Category} />
            <Route path="/account" component={Account} />
            <Route path="/login" component={Login} />
            <Route path="/detail-product/:code" component={DetailProduct} />
            <Route path="/detail-notification" component={DetailNotification} />
            <Route path="/introduction" component={Introduction} />
            <Route path="/cart" component={Cart} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/success" component={Success} />
            <Route path="/detail-order/:code" component={DetailOrder} />
            <Route exact path="/" component={Home} />
            <Route path="/promotion" component={Promotion} />
            <Route path="/best-sellers" component={BestSellers} />
            <Route path="/posts" component={Posts} />
            <Route path="/advisory-posts" component={Advisory} />
            <Route path="/videos" component={Videos} />
            <Route path="/detail-post/:code" component={DetailPost} />
            <Route path="/search" component={Search} />
            <Route path="/contact" component={Contact} />
            <Route path="/cai-dat" component={Installation} />
          </Layout>
        </Switch>
      </>
    );
  }
}

export default App;
