/* eslint-disable import/no-extraneous-dependencies */

import { createStore, applyMiddleware } from 'redux';
// import { createLogger } from 'redux-logger';
// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';

import reducers from '../redux/index';

const configureStore = (preloadedState) => {
  const composeWithDevTools = require('redux-devtools-extension').composeWithDevTools;
  const store = createStore(reducers, preloadedState, composeWithDevTools(applyMiddleware(thunk)));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../redux/index', () => {
      const nextRootReducer = require('../redux/index').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;

// let store;

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['authReducer']
// }

// const persistedReducer = persistReducer(persistConfig, reducers)

// // eslint-disable-next-line no-undef
// if (process.env.NODE_ENV === 'development') {
//   const composeWithDevTools = require('redux-devtools-extension').composeWithDevTools;
//   const logger = createLogger({
//     collapsed: true,
//     titleFormatter: (action) =>
//       `[${action.meta && action.meta.prefix && action.meta.prefix.join('] [')}] ${action.type}`,
//   });
//   store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, logger)));
// } else {
//   const composeWithDevTools = require('redux-devtools-extension').composeWithDevTools;
//   store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
// }
// const persistor = persistStore(store);

// export { persistor, store };
