import React, { Component } from 'react';
// import { NotificationManager } from 'react-notifications';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';


import { actions as accountActions } from '../../../redux/AccountRedux';
import DetailHistory from '../history/detail-history';
import EmptyHistory from '../history/empty-history';

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageCount: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { meta } = nextProps;
    if (meta.total) {
      return { pageCount: Math.ceil(meta.total / 4) };
    }
    return null;
  }

  componentDidMount = () => {
  }

  handlePageClick = (data) => {
    this.props.getAccountOrderHistory({ page: data.selected + 1, page_size: 4 });
  };

  render() {
    const { orderHistory } = this.props;
    return (
      <div>
        <div className="font-header" style={{ marginBottom: '50px' }}>
          Lịch sử mua hàng
        </div>
        
        { orderHistory.length ? 
          (
            <div>
              {orderHistory.map(item => (
              <DetailHistory invoice={item} />
              ))}
              <div className={'pagination-box'}>
                <ReactPaginate
                  previousLabel={'←'}
                  nextLabel={'→'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}/>
              </div>  
            </div>
          )
        : (<EmptyHistory />)}
      </div>
    );
  }
}

const mapStateToProps = (state) =>
({
  orderHistory: state.accountReducer.accountOrderHistory.list,
  meta: state.accountReducer.accountOrderHistory.meta,
})

const mapDispatchToProps = (dispatch) => ({
  getAccountOrderHistory: (params) => dispatch(accountActions.getAccountOrderHistory(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
