import React, { Component } from 'react';
import '../../index.scss';
class Button extends Component {
  state = { currentQuantity: 0, value: false };

  render() {
    return (
      <div style={{display: 'inline-block'}}>
        {this.props.isSelected === this.props.index ? (
          <button onClick={this.props.onClick} className="quantity-button2">
            {this.props.quantity}
            {" "}
            {this.props.unit}
          </button>
        ) : (
          <button onClick={this.props.onClick} className="quantity-button">
            {this.props.quantity}
            {" "}
            {this.props.unit}
          </button>
        )}
      </div>
    );
  }
}

export default Button;
