import React, { Component } from 'react';
import { connect } from 'react-redux';

class FlyButtons extends Component {
  state = { currentQuantity: 0, value: false };

  render() {
    const { stationDetail } = this.props;
    const stationPhone = stationDetail?.phone_number || '0862772966';

    return (
      <div className="site__call_and_mail_actions">
        <a
          className="calling__action"
          href={`tel:${stationPhone}`}
          data-toggle="popover"
          title="Liên hệ qua điện thoại">
          <span>
            <i className="fa fa-phone faa-shake animated" />
          </span>
        </a>
        <a
          href={'https://zalo.me/0862772966'}
          data-toggle="popover"
          title="Liên hệ qua Zalo"
          style={{ borderRadius: 'none' }}>
          <span>
            <img
              className="fa"
              aria-hidden="true"
              src="/assets/images/icon/zalo.svg"
              alt="Zalo"
              style={{ width: 70, marginTop: -15, marginLeft: -5 }}
            />
          </span>
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stationDetail: state.stationReducer.station_detail,
});

export default connect(mapStateToProps, null)(FlyButtons);
