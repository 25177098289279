import { combineReducers } from 'redux';

import { reducer as accountReducer } from './AccountRedux';
import { reducer as authReducer } from './AuthRedux';
import { reducer as cartReducer } from './CartRedux';
import { reducer as categoriesReducer } from './CategoriesRedux';
import { reducer as contentReducer } from './ContentRedux';
import { reducer as favouriteReducer } from './FavouriteRedux';
import { reducer as historyReducer } from './HistoryRedux';
import { reducer as orderReducer } from './OrderRedux';
import { reducer as productReducer } from './ProductRedux';
import { reducer as searchReducer } from './SearchRedux';
import { reducer as stationReducer } from './StationRedux';
import { reducer as supplierReducer } from './SupplierRedux';

export default combineReducers({
  authReducer,
  orderReducer,
  productReducer,
  categoriesReducer,
  cartReducer,
  historyReducer,
  stationReducer,
  searchReducer,
  favouriteReducer,
  accountReducer,
  contentReducer,
  supplierReducer,
  // regionReducer,
});
