import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';

import { COLORS } from '../../constants/colors';
class CategoryTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openFilter: false,
      limit: 3,
      selectedSupplier: '',
    };
  }

  componentDidMount() {
  }

  displayMore = () => {
    this.setState({
      limit: this.props.suppliers.length,
    });
  };

  displayLess = () => {
    this.setState({
      limit: 3,
    });
  };

  onSelectSupplier = (supplierCode) => {
    this.props.onSelectSupplier(supplierCode);
  };

  render() {
    if (!this.props.suppliers || !this.props.suppliers.length) {
      return null;
    }
    const allObj = {
      id : 'all',
      code: 'all',
      name: 'Tất cả',
      introduction: 'all',
    };
    const suppliers = [...this.props.suppliers];
    suppliers.unshift(allObj);

    return (
      <div style={{ display: 'flex', flexWrap: 'nowrap', flexGrow: 1 }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <InfiniteScroll dataLength={this.state.limit}>
            {suppliers.slice(0, this.state.limit).map(item => (
              <div 
                className="font-category-tree"
                style={{ marginBottom: '20px', cursor: 'pointer', color: item.code === this.props.selectedSupplier ? COLORS.primary : '' }}
                onClick={() => {
                  this.onSelectSupplier(item.code);
                }}>
                {' '}
                {item.name}{' '}
              </div>
            ))}
          </InfiniteScroll>
          <div style={{ display: suppliers.length <= 3 ? 'none' : '' }}>
            <div className="view-more-button" onClick={this.displayMore} style={{ cursor: 'pointer', display: this.state.limit > 3 ? 'none' : '' }}>
              Xem Thêm
              <img style={{ width: '5%', marginLeft: '5px', cursor: 'pointer' }} src="/assets/images/icon/downArrow.png" />
            </div>
            <div className="view-more-button" onClick={this.displayLess} style={{ cursor: 'pointer', display: this.state.limit < this.props.suppliers.length ? 'none' : '' }}>
              Thu gọn
              <img style={{ width: '5%', marginLeft: '5px', cursor: 'pointer', transform: 'rotate(180deg)' }} src="/assets/images/icon/downArrow.png" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  suppliers: state.supplierReducer.suppliers
})

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTree);
