import Breadcrumb from 'components/common/breadcrumb';
import moment from 'moment';
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { actions as contentActions } from '../../redux/ContentRedux';
import { getCDNImage } from '../../utils/image';
import PostList from '../post/post-list';

class PostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageCount: 0,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const { meta } = nextProps;
    if (meta.total) {
      return { pageCount: Math.ceil(meta.total / 8) };
    }
    return null;
  }

  componentDidMount = () => {
    this.props.getPosts({ page: 1, page_size: 16, type: 'advisory' });
  };

  handlePageClick = (data) => {
    this.props.getPosts({ page: data.selected + 1, page_size: 8, type: 'advisory' });
  };

  render() {
    const postList = this.props.posts?.map((item) => ({
      code: item?.code,
      title: item?.title,
      slug: item?.slug,
      date: item?.updated_at ? moment(item?.updated_at).format('DD/MM/YYYY') : '',
      img: item?.cover_image ? getCDNImage(item?.cover_image) : '',
      shortDesc: item?.short_description,
    }));

    return (
      <>
        <Breadcrumb title="Chuyên gia giải đáp" />
        <section className="pt-4 section-b-space blog-page ratio2_3">
          <div className="container">
            {postList?.length > 0 ? (
              <>
                <div className="row">
                  <div classMame="col">
                    <PostList postList={postList} />
                  </div>
                </div>
                <div className={'pagination-box'} style={{ position: 'relative', marginTop: '50px' }}>
                  <ReactPaginate
                    previousLabel={'←'}
                    nextLabel={'→'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  />
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                  {/* <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" /> */}
                  <h3>Không có bài viết nào!!</h3>
                  <Link to={`/`} className="btn btn-solid">
                    Về trang chủ
                  </Link>
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  posts: state.contentReducer.post.list,
  meta: state.contentReducer.post.meta,
});

const mapDispatchToProps = (dispatch) => ({
  getPosts: (params) => dispatch(contentActions.getPosts(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostPage);
