import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// Import custom components
import { actions as authActions } from '../../../redux/AuthRedux';
import { actions as cartActions } from '../../../redux/CartRedux';
import { actions as stationActions } from '../../../redux/StationRedux';
import { getBrowserWindow } from '../../../utils/browserWindow';
import CartContainer from './../../../containers/CartContainer';
import LogoImage from './common/logo';
import NavBar from './common/navbar';
// import SideBar from './common/sidebar';
import TopBar from './common/topbar';

class HeaderOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // isLoading: false,
      open: true,
      searchText: '',
    };
  }
  /*=====================
         Pre loader
         ==========================*/
  componentDidMount() {
    const window = getBrowserWindow();
    if (window) window.addEventListener('scroll', this.handleScroll);
    setTimeout(function () {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 500);
    this.props.initStationFromCookie();
    this.props.getItemsInCart();
  }
  componentWillUnmount() {
    const window = getBrowserWindow();
    if (window) window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 300) {
      if (window.innerWidth < 576) {
        document.getElementById('sticky').classList.remove('fixed');
      } else document.getElementById('sticky').classList.add('fixed');
    } else {
      document.getElementById('sticky').classList.remove('fixed');
    }
  };

  openNav() {
    const openmyslide = document.getElementById('mySidenav');
    if (openmyslide) {
      openmyslide.classList.add('open-side');
    }
  }
  openSearch() {
    document.getElementById('search-overlay').style.display = 'block';
  }

  closeSearch() {
    document.getElementById('search-overlay').style.display = 'none';
  }

  // load = () => {
  //   this.setState({ isLoading: true });
  //   fetch().then(() => {
  //     // deal with data fetched
  //     this.setState({ isLoading: false });
  //   });
  // };

  removeFromCart = (code) => {
    this.props.removeFromCart(code);
  };

  showLoginModal = (e) => {
    if (!this.props.token) {
      e.preventDefault();
      this.props.toggleLoginModal();
    }
  };

  render() {
    const cartList = this.props.cartItems?.map((item) => ({
      id: item?.product?.code,
      code: item?.product?.code,
      slug: item?.product?.slug,
      name: item?.product?.name,
      pictures: [item?.product?.image?.thumbnail_url],
      qty: item?.quantity,
      price: item?.product?.price?.selling_price,
    }));

    return (
      <div>
        <header id="sticky" className="sticky">
          <div className="mobile-fix-option" />
          <TopBar />
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu">
                  <div className="menu-left">
                    {/* <div className="navbar">
                      <a href={null} onClick={this.openNav}>
                        <div className="bar-style">
                          {' '}
                          <i className="fa fa-bars sidebar-bar" aria-hidden="true" />
                        </div>
                      </a> */}
                    {/*SideBar Navigation Component*/}
                    {/* <SideBar />
                    </div> */}
                    <div className="brand-logo">
                      <LogoImage logo={this.props.logoName} />
                      {this.props.showH1 && <h1 style={{ display: 'none' }}>Trạm Xanh</h1>}
                    </div>
                  </div>
                  <div className="menu-right pull-right">
                    {/*Top Navigation Bar Component*/}
                    <NavBar />
                    <div>
                      <div className="icon-nav">
                        <ul>
                          <li className="onhover-div mobile-search">
                            <div>
                              <img
                                src={`/assets/images/icon/search.png`}
                                onClick={this.openSearch}
                                className="img-fluid"
                                alt=""
                              />
                              <i className="fa fa-search" onClick={this.openSearch} />
                            </div>
                          </li>
                          <li
                            className={`onhover-div ${
                              this.props.token ? 'mobile-setting-logged-in' : 'mobile-setting'
                            }`}>
                            <div>
                              <Link to={`/account/wishlists`} onClick={this.showLoginModal}>
                                <img
                                  src={`/assets/images/icon/heart.png`}
                                  className="img-fluid"
                                  alt=""
                                  width="25px"
                                  height="25px"
                                />
                                <i className="fa fa-heart-o" />
                              </Link>
                            </div>
                          </li>
                          {/*Header Cart Component */}
                          <CartContainer
                            cartList={cartList}
                            totalAmount={this.props.total_amount}
                            removeFromCart={this.removeFromCart}
                            showLoginModal={this.showLoginModal}
                            token={this.props.token}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="search-overlay" style={{ backgroundColor: 'black', opacity: '0.85' }} className="search-overlay">
          <div>
            <span className="closebtn" onClick={this.closeSearch} title="Close Overlay">
              ×
            </span>
            <div className="overlay-content">
              <div className="container">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <LogoImage logo="logoApp.jpg" />
                  <div
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '20px',
                      marginTop: '20px',
                      color: 'white',
                    }}>
                    Tìm kiếm sản phẩm
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Nhập mã hoặc tên sản phẩm"
                          onChange={(e) => this.setState({ searchText: e.target.value })}
                          value={this.state.searchText}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={(e) => {
                          if (this.state.searchText) {
                            e.preventDefault();
                            this.props.history.push(`/search?s=${this.state.searchText}`);
                            this.setState({ searchText: '' });
                            this.closeSearch();
                          } else e.preventDefault();
                        }}>
                        <i className="fa fa-search" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  total_amount: state.cartReducer.total_amount,
  cartItems: state.cartReducer.items,
  token: state.accountReducer.token,
});

const mapDispatchToProps = (dispatch) => ({
  getItemsInCart: () => dispatch(cartActions.getItemsInCart()),
  removeFromCart: (code) => dispatch(cartActions.deleteItemInCart(code)),
  toggleLoginModal: () => dispatch(authActions.toggleLoginModal()),
  initStationFromCookie: () => dispatch(stationActions.initStationFromCookie()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderOne));
