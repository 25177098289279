import React, { Component } from 'react';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import Slider from 'react-slick';

// import { actions } from '../../../redux/ProductRedux';
// import { addToCart, addToWishlist, addToCompare } from '../../../actions';
import ProductListItem from '../../category/product-list-item';

class ProductScrollListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 10,
      hasMoreItems: true,
      relatedProductsInfo: [],
    };
  }

  static getDerivedStateFromProps = (props) => {
    if (props.allProducts && props.relatedProductsCode) {
      const relatedProductsInfo = [];
      props.relatedProductsCode.forEach((code) => {
        const res = props.allProducts.find((product) => product.code === code);
        if (res) {
          relatedProductsInfo.push(res);
        }
      });
      return { relatedProductsInfo };
    }
    if (props.allProducts && props.relatedProducts) {
      return { relatedProductsInfo: props.relatedProducts };
    }
    return null;
  };

  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const data = this.state.relatedProductsInfo?.map((row) => ({
      name: row?.name,
      slug: row?.slug,
      // category: null,
      rating: 5,
      variants: row?.images?.map((item) => ({
        images: item.listing_url,
      })),
      discount: row?.price.sale_off,
      price: row?.price?.selling_price || 0,
      old_price: row?.price?.price || 0,
      category: row?.category.name,
      unit: row?.unit?.primary,
      code: row?.code,
      image: row?.image ? row?.image.listing_url : '',
      image_name: row.image ? row.image.name : '',
      image_alt: row.image ? row.image.alt : '',
    }));

    const { addToCart, symbol, addToWishlist, addToCompare } = this.props;

    return (
      <div>
        <Slider {...settings}>
          {data?.slice(0, this.state.limit).map((product, index) => (
            <div
              className={`${
                this.props.colSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : `col-lg-${this.props.colSize}`
              }`}
              key={index}>
              <ProductListItem
                product={product}
                symbol={symbol}
                onAddToCompareClicked={() => addToCompare(product)}
                onAddToWishlistClicked={() => addToWishlist(product)}
                onAddToCartClicked={addToCart}
                key={index}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allProducts: state.productReducer.allProducts,
  productsInCategory: state.productReducer.productsInCategory,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductScrollListing);
