import { isBrowserWindow } from '../utils/browserWindow';
import { PREFIX } from './config';

export const isCallingApi = (action) =>
  // only browser has calling to prevent rehydrate fetching status
  isBrowserWindow() && action.meta.prefix && action.meta.prefix.includes(PREFIX.API_CALLING);

export const isSuccessfulApiCall = (action) =>
  action.meta.prefix && action.meta.prefix.includes(PREFIX.API_CALLED_SUCCESS);

export const isFailedApiCall = (action) => action.meta.prefix && action.meta.prefix.includes(PREFIX.API_CALLED_FAILURE);
