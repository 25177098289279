export const ORDER_STATUS = {
  cancelled: 'Đã Huỷ',
  processing: 'Đặt hàng',
  completed: 'Hoàn Thành',
  new: 'Mới',
  returned: 'Trả Lại',
  received: 'Đã nhận',
  checkout: 'Chờ xử lý',
  packed: 'Đóng gói',
  shipping: 'Đang giao hàng',
  shipped: 'Đã giao hàng',
};

export const SYSTEM_STATUS = {
  category: 'Nhóm hàng',
  product: 'Sản phẩm',
  home: 'Trang chủ',
  promotion: 'Khuyến mại',
};
