import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { Link, withRouter } from 'react-router-dom';
// import { components } from 'react-select';

import { COLORS } from '../../../../constants/colors';
import { actions } from '../../../../redux/AuthRedux';
import { getBrowserWindow } from '../../../../utils/browserWindow';
import UniverseCookies from '../../../../utils/cookies';
// import StationSearch from '../../../formItems/StationSearch';
import StationModal from './StationModal';

import './header.scss';

const window = getBrowserWindow();
class TopBar extends Component {
  constructor(props) {
    super(props);

    const token = UniverseCookies.getToken();
    const stationCode = UniverseCookies.getStationCode();

    this.state = {
      openStationForm: !token && !stationCode,
      otpCode: '',
      phone: '',
      loginFormStep: 1,
      loggedIn: false,
      showErrorPhone: '',
      showErrorOTP: '',
    };
  }

  componentDidMount() {
    const token = UniverseCookies.getToken();
    const stationCode = UniverseCookies.getStationCode();

    if (!token && stationCode) {
      // when web has default station code ( Tram Xanh Food )
      this.props.guestLogin(stationCode);
    }
  }

  onOpenModal = () => {
    this.props.toggleLoginModal();
  };

  goToLoginStep = (step) => {
    this.setState({ loginFormStep: step, showErrorOTP: '', showErrorPhone: '' });
  };

  clearErrorMsg = () => {
    const { showErrorOTP, showErrorPhone } = this.state;
    if (showErrorOTP || showErrorPhone) {
      this.setState({ showErrorOTP: '', showErrorPhone: '' });
    }
  };

  requestOTPCode = () => {
    const re = new RegExp(/^(0)[1-9]([0-9]{8}[0-9]?)\b/);
    if (this.state.phone) {
      if (!re.test(this.state.phone)) {
        this.setState({ showErrorPhone: 'Số điện thoại sai định dạng !' });
      } else {
        this.goToLoginStep(2);
        this.props.sendPhone(this.state.phone, {
          onSuccess: () => {
            // this.goToLoginStep(2);
          },
          onFailure: () => {
            this.goToLoginStep(1);
            this.setState({ showErrorPhone: 'Có lỗi khi gửi mã OTP, hãy thử lại!' });
          },
        });
      }
    } else {
      this.setState({ showErrorPhone: 'Bạn chưa điền số điện thoại!' });
    }
  };

  confirmOTP = () => {
    if (this.state.otpCode) {
      this.props.login(
        { phone: this.state.phone, otp: this.state.otpCode },
        {
          onSuccess: (authUser) => {
            this.setState({ loginFormStep: 1, loggedIn: true });
            if (!authUser?.station_code) {
              this.onOpenStationForm();
            } else if (!authUser?.name) {
              this.props.history.push({ pathname: '/account/information', state: { newAccount: true } });
              // window.location.reload();
            }
          },
          onFailure: () => {
            NotificationManager.error('Có lỗi khi đăng nhập, hãy kiểm tra lại mã OTP!', 'Thất bại', 2000);
            // this.onCloseModal();
          },
        },
      );
    } else {
      this.setState({ showErrorOTP: 'Bạn chưa điền mã Otp!' });
    }
  };

  resendOtp = () => {
    // this.onCloseModal();
    this.goToLoginStep(3);
  };
  handleChangeOTP = (e) => {
    this.setState({ otpCode: e });
    this.clearErrorMsg();
  };
  onCloseModal = () => {
    if (this.props.showLoginModal) {
      this.props.toggleLoginModal();
      this.goToLoginStep(1);
      this.clearErrorMsg();
      this.setState({ phone: '', otpCode: '' });
    }
  };

  onOpenStationForm = () => {
    this.setState({ openStationForm: true });
  };
  onCloseStationForm = () => {
    this.setState({ openStationForm: false });
    this.onCloseModal();
  };
  onChangePhoneNumber = (e) => {
    this.setState({ phone: e.target.value });
    this.clearErrorMsg();
  };

  logOut = () => {
    this.props.logOut();
    this.props.history.push('/');
  };

  onSubmitPhone = (e) => {
    e.preventDefault();
    this.requestOTPCode();
  };

  onSubmitOTP = (e) => {
    if (e.key === 'Enter') {
      this.confirmOTP();
    }
  };

  render() {
    const { loginFormStep, showErrorPhone, showErrorOTP } = this.state;
    const { token, stationDetail, showLoginModal } = this.props;

    const stationPhone = stationDetail?.phone_number || '086.277.2966 - 0358.746.399';

    return (
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="header-contact">
                <ul>
                  {/* <li>Chào mừng bạn đến với Trạm Xanh</li> */}
                  {stationDetail?.name ? (
                    <li>
                      <a href={'#'} onClick={this.onOpenStationForm}>
                        <i className="fa fa-map-marker" aria-hidden="true" style={{ fontSize: 20 }} />
                        {stationDetail.name}
                      </a>
                    </li>
                  ) : null}
                  <li>
                    <i className="fa fa-phone" aria-hidden="true" style={{ color: '#ff4c3b', fontSize: 20 }} />
                    <a href={`tel:${stationPhone}`}>{stationPhone}</a>
                  </li>
                  <li>
                    <a href={'https://www.facebook.com/tramvattuthongminh'} _target="blank">
                      <i className="fa fa-facebook" aria-hidden="true" style={{ color: '#4267B2', fontSize: 20 }} />
                    </a>
                  </li>
                  <li>
                    <a href={'https://www.youtube.com/channel/UCDvu50NeJKZTQ5TMqc5StyQ'} _target="blank">
                      <i className="fa fa-youtube-play" aria-hidden="true" style={{ color: '#FF0000', fontSize: 20 }} />
                    </a>
                  </li>
                  <li>
                    <a href={'https://zalo.me/0862772966'} _target="blank">
                      <img
                        className="fa"
                        aria-hidden="true"
                        src="/assets/images/icon/zalo.svg"
                        alt="Zalo"
                        style={{ width: 24, marginTop: -6 }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 text-right">
              {!token ? (
                <div className="login-text">
                  <a
                    data-toggle="modal"
                    data-target="#sizemodal"
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                    onClick={this.onOpenModal}>
                    <i className="fa fa-user" aria-hidden="true" style={{ marginRight: 7 }} />
                    Đăng nhập
                  </a>
                </div>
              ) : (
                <ul className="header-dropdown" style={{ marginRight: '15px' }}>
                  <li className="onhover-dropdown mobile-account">
                    <Link
                      to={`/account/information`}
                      data-lng="en"
                      className="dat"
                      style={{ display: token ? '' : 'none' }}>
                      <i className="fa fa-user" aria-hidden="true" />
                      {this.props.accountInformation ? this.props.accountInformation.name : 'Tài khoản'}
                    </Link>
                    <ul className="onhover-show-div">
                      <li>
                        <a
                          data-toggle="modal"
                          data-target="#sizemodal"
                          style={{ display: token ? '' : 'none' }}
                          onClick={this.logOut}>
                          Đăng xuất
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div>
          {showLoginModal && !token ? (
            <>
              <Modal open={loginFormStep === 1} onClose={this.onCloseModal}>
                <div className="modal-width-default" style={{ width: '100%', height: '100%' }}>
                  <div className="headerFormLogin">
                    <div className="headerFormLogin-item">Đăng Nhập</div>{' '}
                  </div>
                  <div className="form-group center-form-phone">
                    <div className="inputOtp">
                      <label htmlFor="phone" style={{ fontSize: '18px' }}>
                        Nhập số điện thoại
                      </label>
                    </div>
                    <div className="inputForPhone">
                      <form onSubmit={this.onSubmitPhone}>
                        <div className="input-icons">
                          <i className="fa fa-phone icon" />
                          <input
                            autoFocus
                            onChange={this.onChangePhoneNumber}
                            className="input-field"
                            type="text"
                            maxLength="10"
                          />
                        </div>
                      </form>
                      <button onClick={this.requestOTPCode} className="submit-button">
                        Gửi Mã Đăng Nhập
                      </button>
                    </div>
                  </div>
                </div>
                {showErrorPhone ? (
                  <div
                    style={{
                      color: 'red',
                      marginTop: '45px',
                      textAlign: 'center',
                      fontSize: '17px',
                      fontFamily: 'Helvetica',
                    }}>
                    {showErrorPhone}
                  </div>
                ) : null}
              </Modal>
              <Modal open={loginFormStep === 2} onClose={this.onCloseModal}>
                <div className="modal-width-default" style={{ width: '100%', height: '100%' }}>
                  <div className="headerFormLogin">
                    <div className="headerFormLogin-item">Đăng Nhập</div>{' '}
                  </div>
                  <div className="form-group center-form-phone">
                    <div className="inputOtp">
                      <label htmlFor="phone">
                        <span>Nhập OTP</span>{' '}
                      </label>
                    </div>
                    <div onKeyPress={this.onSubmitOTP} className="otpCode center-form-phone">
                      <OtpInput
                        shouldAutoFocus
                        onChange={this.handleChangeOTP}
                        numInputs={6}
                        separator={<span style={{ width: '4px' }}> </span>}
                        value={this.state.otpCode}
                        containerStyle={{ width: '100%' }}
                        focusStyle={{ borderColor: COLORS.primary }}
                        inputStyle={
                          {
                            // // width: '56px',
                            // maxWidth: '100%',
                            // // height: '60px',
                            // flex: 1,
                            // marginRight: '6px',
                            // fontSize: '20px',
                            // background: '#F6F6F8',
                            // border: '1px solid #D2D2D2',
                            // borderRadius: '4px',
                          }
                        }
                      />
                    </div>
                    <button onClick={this.confirmOTP} className="submit-button center-form-phone">
                      Nhập mã
                    </button>
                    <button
                      onClick={this.resendOtp}
                      style={{
                        width: '68%',
                        marginTop: '60px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        backgroundColor: 'white',
                        border: 'none',
                      }}>
                      <span className="linkResend" style={{ borderBottom: '1px solid black' }}>
                        Gửi lại mã xác nhận
                      </span>
                    </button>
                  </div>
                </div>
                {showErrorOTP ? (
                  <div
                    style={{
                      color: 'red',
                      marginTop: '45px',
                      textAlign: 'center',
                      fontSize: '17px',
                      fontFamily: 'Helvetica',
                    }}>
                    {showErrorOTP}
                  </div>
                ) : null}
              </Modal>
              <Modal open={loginFormStep === 3} onClose={this.onCloseModal}>
                <div className="modal-width-default" style={{ width: '100%', height: '100%' }}>
                  <div className="headerFormLogin">
                    <div className="headerFormLogin-item">Đăng Nhập</div>{' '}
                  </div>
                  <div className="form-group center-form-phone">
                    <div className="inputOtp">
                      <label htmlFor="phone">
                        <span>Nếu bạn chưa nhận được mã</span>{' '}
                      </label>
                    </div>
                    <div className="input-resend">
                      <p style={{ float: 'left', marginTop: '25px', marginRight: '14px' }}>
                        <img src={`/assets/images/icon/loginPhone.png`} height="51px" width="50px" border="1px" />
                      </p>
                      <p style={{ fontSize: '14px', marginBottom: '5px', marginTop: '25px' }}>
                        Kiểm Tra Số Điện Thoại Di Động{' '}
                      </p>
                      <p style={{ fontSize: '14px' }}>
                        <em style={{ color: COLORS.primary }}>{this.state.phone}</em> Hoặc{' '}
                        <button
                          className="btn btn-link"
                          style={{
                            color: COLORS.primary,
                            fontSize: '14px',
                            padding: 0,
                            lineHeight: 'inherit',
                            verticalAlign: 'baseline',
                          }}
                          onClick={() => this.goToLoginStep(1)}>
                          {' '}
                          Đổi số điện thoại
                        </button>
                      </p>
                    </div>
                    <div className="input-resend">
                      <p style={{ float: 'left', marginRight: '14px' }}>
                        <img src={`/assets/images/icon/loginMail.png`} height="50px" width="50px" border="1px" />
                      </p>
                      <p style={{ fontSize: '14px', marginBottom: '5px', marginTop: '8px' }}>
                        Kiểm Tra Lại Tin Nhắn SMS{' '}
                      </p>
                      <p style={{ fontSize: '14px' }}>
                        <button
                          className="btn btn-link"
                          style={{
                            color: COLORS.primary,
                            fontSize: '14px',
                            padding: 0,
                            lineHeight: 'inherit',
                            verticalAlign: 'baseline',
                          }}
                          onClick={() => this.goToLoginStep(2)}>
                          {' '}
                          Nhập Mã
                        </button>
                      </p>
                    </div>
                    <button
                      onClick={this.requestOTPCode}
                      // style={{ marginTop: '30px' }}
                      className="submit-button center-form-resend">
                      Gửi Lại Mã
                    </button>
                  </div>
                </div>
              </Modal>
            </>
          ) : null}
          {window ? <StationModal isOpen={this.state.openStationForm} onCancel={this.onCloseStationForm} /> : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  otpCode: state.authReducer.otp,
  authUser: state.authReducer.authUser,
  token: state.accountReducer.token,
  accountInformation: state.accountReducer.accountInformation,
  stationDetail: state.stationReducer.station_detail,
  // don't show login when go to page /cai-dat
  showLoginModal: state.authReducer.showLoginModal,
});

const mapDispatchToProps = (dispatch) => ({
  guestLogin: (stationCode, meta) => {
    dispatch(actions.guestLogin(stationCode, meta));
  },
  sendPhone: (payload, meta) => {
    dispatch(actions.sendPhone(payload, meta));
  },
  login: (payload, meta) => {
    dispatch(actions.login(payload, meta));
  },
  logOut: () => dispatch(actions.logOut()),
  toggleLoginModal: () => dispatch(actions.toggleLoginModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBar));
