import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { API_CALLING, API_CALLED_SUCCESS, API_CALLED_FAILURE, SEARCH } = PREFIX;
const _types = typesWithPrefix(SEARCH);
const TYPES = {
  GET_PRODUCTS: _types('GET_PRODUCTS'),
  CHANGE_PAGE: _types('CHANGE_PAGE'),
  CHANGE_TEXT_SEARCH: _types('CHANGE_TEXT_SEARCH'),
  CHANGE_PRICE_TO: _types('CHANGE_PRICE_TO'),
  CHANGE_CATEGORY_SEARCH: _types('CHANGE_CATEGORY_SEARCH'),
  CHANGE_PRICE_FROM: _types('CHANGE_PRICE_FROM'),
};

export const actions = {
  gettingProducts: () => ({
    type: TYPES.GET_PRODUCTS,
    meta: { prefix: [SEARCH, API_CALLING] },
  }),
  getProductsSuccess: payload => ({
    type: TYPES.GET_PRODUCTS,
    payload,
    meta: {
      prefix: [SEARCH, API_CALLED_SUCCESS],
    },
  }),
  getProductsFailure: payload => ({
    type: TYPES.GET_PRODUCTS,
    payload,
    meta: {
      prefix: [SEARCH, API_CALLED_FAILURE],
    },
  }),
  // getProducts: meta => async (dispatch, getState) => {
  //   const { productReducer } = getState();
  //   const params = meta && 'page' in meta ? meta : productReducer.meta;
  //   const api = API_URLS.PRODUCT.getFilteredProducts(params);
  //   dispatch(actions.gettingProducts);
  //   const { response, error } = await apiCall(api);

  //   if (!error && response.status === 200) {
  //     if (response.data.data !== null) {
  //       dispatch(actions.getProductsSuccess({ meta, data: response.data.data }));
  //     }
  //   } else {
  //     dispatch(actions.getProductsFailure({ meta }));
  //   }
  // },
  getProducts: (query) => async (dispatch, getState) => {
    const { searchReducer } = getState();
    const meta = searchReducer.products.meta;
    const pagination = meta.pagination;
    const baseParams = {
      category_codes: query?.categoryCode,
      supplier_codes: query?.supplierCode,
      search: query?.searchText,
      page: query?.page || pagination.current,
      page_size: query?.pageSize || pagination.pageSize,
    };
    const api = API_URLS.PRODUCT.getProducts(baseParams);
    dispatch(actions.gettingProducts());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.getProductsSuccess({ query, data: response.data }));
    } else {
      dispatch(actions.getProductsFailure({ query }));
    }
  },

  getProductsIfNeed: (filterOption) => (dispatch, getState) => {
    const { searchReducer } = getState();
    const isFetching = searchReducer.products.isFetching;
    const didInvalidate = searchReducer.products.didInvalidate;
    const meta = searchReducer.products.meta;
    const pagination = meta.pagination;
    const query = meta.query;
    if (filterOption.supplierCode === 'all'){
      filterOption.supplierCode = '';
    }
    if (filterOption.categoryCode === 'all'){
      filterOption.categoryCode = '';
    }
    const isQueryChanged =
      pagination.page !== filterOption.page ||
      query.searchText !== filterOption.searchText ||
      query.categoryCode !== filterOption.categoryCode ||
      query.supplierCode !== filterOption.supplierCode;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getProducts(filterOption));
    }
  },

  changePage: () => ({
    type: TYPES.CHANGE_PAGE,
    meta: { prefix: [SEARCH] },
  }),
  changeTextSearch: text => async dispatch => {
    if (text !== '') {
      dispatch({
        type: TYPES.CHANGE_TEXT_SEARCH,
        payload: text,
      });
    }
  },
  changePriceTo: price_to => async dispatch => {
    if (price_to !== '') {
      dispatch({
        type: TYPES.CHANGE_PRICE_TO,
        payload: price_to,
      });
    }
  },
  changePriceFrom: price_from => async dispatch => {
    if (price_from !== '') {
      dispatch({
        type: TYPES.CHANGE_PRICE_FROM,
        payload: price_from,
      });
    }
  },
  changeCategorySearch: categories => async dispatch => {
    if (categories !== '') {
      dispatch({
        type: TYPES.CHANGE_CATEGORY_SEARCH,
        payload: categories,
      });
    }
  },
};

const initialState = {
  products: {
    list: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 16,
        total: 0,
      },
      query: {
        search: ''
      },
    },
    isFetching: false,
    didInvalidate: true,
  },
  isFetching: false,
};

export const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TYPES.GET_PRODUCTS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const {
            query,
            data: { data, page, page_size, total },
          } = action.payload;
          draft.products.list = data !== null ? data : [];
          draft.products.meta.pagination.pageSize = page_size;
          draft.products.meta.pagination.page = page;
          draft.products.meta.pagination.total = total;
          draft.products.meta.query = {
            searchText: query?.searchText,
            categoryCode: query?.categoryCode,
            supplierCode: query?.supplierCode,
          };
          draft.products.isFetching = false;
          draft.products.didInvalidate = false; 
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.products.didInvalidate = false;
        }
        break;
      case TYPES.CHANGE_PAGE:
        draft.meta.page = draft.meta.page + 1;
        break;
      case TYPES.CHANGE_TEXT_SEARCH:
        draft.meta.search = action.payload;
        break;
      case TYPES.CHANGE_PRICE_TO:
        draft.meta.price_to = action.payload;
        break;
      case TYPES.CHANGE_PRICE_FROM:
        draft.meta.price_from = action.payload;
        break;
      case TYPES.CHANGE_CATEGORY_SEARCH:
        draft.meta.categories = action.payload;
        break;
      default:
        return draft;
    }
  });
