import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as accountActions } from '../../../redux/AccountRedux';
import EmptyList from './empty-wish-list';
import Product from './product-wishlists';
class WishLists extends Component {
  state = {
    init: false,
  };

  static getDerivedStateFromProps = (props) => {
    // if ((props.accountWishList && !state.init && props.accountWishList?.length !== state.accountWishList?.length)) {
    //   let string = `page=1&page_size=20`;
    //   for (let i = 0; i < props.accountWishList.length; i++) {
    //     string += `&product_codes=${props.accountWishList[i]?.product_code}`;
    //   }
    //   if (string !== `page=1&page_size=20`){
    //     props.getWishListProductInfo(string);
    //     return { init: true, accountWishList: [...props.accountWishList] };
    //   }
    // }
    if (props.accountWishList) {
      let string = `page=1&page_size=20`;
      for (let i = 0; i < props.accountWishList.length; i++) {
        string += `&product_codes=${props.accountWishList[i]?.product_code}`;
      }
      if (string !== `page=1&page_size=20`) {
        props.getWishListProductInfoIfNeed(string);
      }
    }

    return null;
  };

  componentDidMount = () => {
    this.props.getAccountWishList();
  };

  // refreshPage = () => {
  //   this.setState({ init: false });
  // }

  render() {
    return (
      <div>
        <div className="font-header" style={{ marginBottom: '50px' }}>
          Danh sách yêu thích
        </div>
        {this.props.wishListProduct.length ? (
          <div>
            {this.props.wishListProduct.map((item) => (
              <Product product={item} />
            ))}
          </div>
        ) : (
          <EmptyList />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accountWishList: state.accountReducer.accountWishList.list,
  wishListProduct: state.accountReducer.accountWishList.wishListProducts,
});

const mapDispatchToProps = (dispatch) => ({
  getAccountWishList: () => dispatch(accountActions.getAccountWishList()),
  getWishListProductInfo: (string) => dispatch(accountActions.getWishListProductInfo(string)),
  getWishListProductInfoIfNeed: (string) => dispatch(accountActions.getWishListProductInfoIfNeed(string)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WishLists);
