import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { actions as accountActions } from '../../../../redux/AccountRedux';
import { actions as categoryActions } from '../../../../redux/CategoriesRedux';
import { actions as productActions } from '../../../../redux/ProductRedux';
import { actions as stationActions } from '../../../../redux/StationRedux';
import { actions as supplierActions } from '../../../../redux/SupplierRedux';
import { getWindowWidth } from '../../../../utils/browserWindow';
import UniverseCookies from '../../../../utils/cookies';
import { slugPath } from '../../../../utils/product';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClose: { right: '0px' },
    };
  }

  componentDidMount() {
    const windowWidth = getWindowWidth();
    if (windowWidth < 750) {
      this.setState({ navClose: { right: '-410px' } });
    }
    if (windowWidth < 1199) {
      this.setState({ navClose: { right: '-300px' } });
    }
    this.props.getMenuCategories();
    this.props.getAllProducts();
    this.props.getCategories({});
    this.props.getStations({ page: 5, page_size: -1 });
    this.props.getAccountInformation();
    this.props.getAccountOrderHistory({ page: 1, page_size: 4 });
    this.props.getSuppliers();
  }

  openNav = () => {
    this.setState({ navClose: { right: '0px' } });
  };
  closeNav = () => {
    const windowWidth = getWindowWidth();
    if (windowWidth < 1199) {
      this.setState({ navClose: { right: '-410px' } });
    }
  };

  onMouseEnterHandler() {
    const windowWidth = getWindowWidth();
    if (windowWidth > 1199) {
      document.querySelector('#main-menu').classList.add('hover-unset');
    }
  }

  handleSubmenu = (event) => {
    if (event.target.classList.contains('sub-arrow')) return;

    if (event.target.nextElementSibling.classList.contains('opensubmenu'))
      event.target.nextElementSibling.classList.remove('opensubmenu');
    else {
      document.querySelectorAll('.nav-submenu').forEach(function (value) {
        value.classList.remove('opensubmenu');
      });
      document.querySelector('.mega-menu-container').classList.remove('opensubmenu');
      event.target.nextElementSibling.classList.add('opensubmenu');
    }
  };

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains('sub-arrow')) return;

    if (event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
      event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu');
    else {
      document.querySelectorAll('.menu-content').forEach(function (value) {
        value.classList.remove('opensubmegamenu');
      });
      event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu');
    }
  };

  render() {
    const stationCode = UniverseCookies.getStationCode();
    return (
      <div>
        <div className="main-navbar">
          <div id="mainnav">
            <div className="toggle-nav" onClick={this.openNav}>
              <i className="fa fa-bars sidebar-bar" />
            </div>
            <ul className="nav-menu" style={this.state.navClose}>
              <li className="back-btn" onClick={this.closeNav}>
                <div className="mobile-back text-right">
                  <span>Đóng</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                </div>
              </li>
              <li className="cat-onhover-dropdown">
                <Link to={'/search'} onClick={this.closeNav} className="nav-link" style={{ cursor: 'default' }}>
                  Danh mục sản phẩm
                </Link>
                <ul className="onhover-show-div">
                  {this.props.horizontalMenuCategories?.map((category, index) => (
                    <li key={`${index}-${category.code}`}>
                      <Link to={`/category/${slugPath(category)}`} onClick={this.closeNav}>
                        {category.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              {
                // Trạm Xanh Food
                stationCode === '101021000' ? (
                  <li>
                    <Link to={'/category/vi-moi-truong-xanh-05020000'} onClick={this.closeNav} className="nav-link">
                      Vật tư xử lý môi trường
                    </Link>
                  </li>
                ) : null
              }
              {/* <li>
                <Link to={'/category/010000000'} onClick={this.closeNav} className="nav-link">
                  Combo Sản phẩm
                </Link>
              </li> */}
              <li>
                <Link to={'/advisory-posts'} onClick={this.closeNav} className="nav-link">
                  Chuyên gia giải đáp
                </Link>
              </li>
              <li>
                <Link to={'/videos'} onClick={this.closeNav} className="nav-link">
                  Video
                </Link>
              </li>
              <li>
                <Link to={'/posts'} onClick={this.closeNav} className="nav-link">
                  Tin tức
                </Link>
              </li>
              <li>
                <Link to={'/contact'} onClick={this.closeNav} className="nav-link">
                  Liên hệ
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  horizontalMenuCategories: state.categoriesReducer.horizontalMenuCategories,
  stationDetail: state.stationReducer.station_detail,
});

const mapDispatchToProps = (dispatch) => ({
  getMenuCategories: () => dispatch(categoryActions.getMenuCategories()),
  getAllProducts: () => dispatch(productActions.getAllProducts()),
  getCategories: () => dispatch(categoryActions.getCategories()),
  getStations: ({ params }) => dispatch(stationActions.getStations(params)),
  getAccountInformation: () => dispatch(accountActions.getAccountInformation()),
  getAccountOrderHistory: (params) => dispatch(accountActions.getAccountOrderHistory(params)),
  getSuppliers: () => dispatch(supplierActions.getSuppliers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
