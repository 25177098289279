export const codeFromSlug = (slug) => {
  let maybeCode = slug;
  if (maybeCode && maybeCode.lastIndexOf('-') >= 0) {
    maybeCode = maybeCode.slice(maybeCode.lastIndexOf('-') + 1);
  }

  return maybeCode;
};

export const slugPath = (prod) => (prod.slug ? `${prod.slug}-${prod.code}` : prod.code);

export const flattenCategories = (list, results) => {
  results.length = results.length || 0; // eslint-disable-line no-param-reassign
  if (list && list.length) {
    list.forEach((cat) => {
      if (!results[cat.code]) {
        results[cat.code] = { ...cat }; // eslint-disable-line no-param-reassign
        results.length += 1; // eslint-disable-line no-param-reassign
        if (cat.children) {
          flattenCategories(cat.children, results);
        }
      }
    });
  }
};
