export const PREFIX = {
  AUTH: 'AUTH',
  APP: 'APP',
  SUPPLIERS: 'SUPPLIERS',
  SUPPLIER: 'SUPPLIER',
  PRODUCTS: 'PRODUCTS',
  SEARCH: 'SEARCH',
  PRODUCT: 'PRODUCT',
  CATEGORIES: 'CATEGORIES',
  CART: 'CART',
  ORDER: 'ORDER',
  HISTORY: 'HISTORY',
  CHART: 'CHART',
  ACCOUNT: 'ACCOUNT',
  POST: 'POST',
  API: 'API',
  STATION: 'STATION',
  PRODUCT_UNIT: 'PRODUCT_UNIT',
  REGION: 'REGION',
  FAVOURITES: 'FAVOURITES',
  API_CALLING: 'API_CALLING',
  API_CALLED_SUCCESS: 'API_CALLED_SUCCESS',
  API_CALLED_FAILURE: 'API_CALLED_FAILURE',
  ROLE: 'ROLE',
  CONTENT: 'CONTENT',
};

export const typesWithPrefix = (prefix) => (key) => `${prefix}_${key}`;
