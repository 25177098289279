import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
import { actions as accountActions } from './AccountRedux';

const { API_CALLING, API_CALLED_SUCCESS, API_CALLED_FAILURE, ORDER } = PREFIX;
const _types = typesWithPrefix(ORDER);
const TYPES = {
  GET_ORDER_DETAIL: _types('GET_ORDER_DETAIL'),
  CANCEL_ITEM_IN_ORDER: _types('CANCEL_ITEM_IN_ORDER'),
  CANCEL_ORDER: _types('CANCEL_ORDER'),
};

export const actions = {
  //CANCEL ORDER
  cancelingOrder: () => ({
    type: TYPES.CANCEL_ORDER,
    meta: { prefix: [ORDER, API_CALLING] },
  }),
  cancelOrderSuccess: payload => ({
    type: TYPES.CANCEL_ORDER,
    payload,
    meta: { prefix: [ORDER, API_CALLED_SUCCESS] },
  }),

  cancelOrderFailure: () => ({
    type: TYPES.CANCEL_ORDER,
    meta: { prefix: [ORDER, API_CALLED_FAILURE] },
  }),

  cancelOrder: (order_code, meta) => async dispatch => {
    const api = API_URLS.ORDER.cancelOrder(order_code);
    dispatch(actions.cancelingOrder());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.cancelOrderSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
      // dispatch(actions.getOrderDetail(order_code));
      dispatch(accountActions.getAccountOrderHistory({page: 1, page_size: 4}));
    } else {
      dispatch(actions.cancelOrderFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
    return { response, error };
  },

  //REMOVE ORDER ITEM
  cancelingItemInOrder: () => ({
    type: TYPES.CANCEL_ITEM_IN_ORDER,
    meta: { prefix: [ORDER, API_CALLING] },
  }),
  cancelItemInOrderSuccess: payload => ({
    type: TYPES.CANCEL_ITEM_IN_ORDER,
    payload,
    meta: { prefix: [ORDER, API_CALLED_SUCCESS] },
  }),

  cancelItemInOrderFailure: () => ({
    type: TYPES.CANCEL_ITEM_IN_ORDER,
    meta: { prefix: [ORDER, API_CALLED_FAILURE] },
  }),

  cancelItemInOrder: (payload, meta) => async dispatch => {
    const api = API_URLS.ORDER.cancelItemInOrder(payload);
    dispatch(actions.cancelingItemInOrder());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(actions.cancelItemInOrderSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
      dispatch(actions.getOrderDetail(payload.order_code));
    } else {
      dispatch(actions.cancelItemInOrderFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
    return { response, error };
  },

  //GET ORDER DETAIL
  gettingOrderDetail: () => ({
    type: TYPES.GET_ORDER_DETAIL,
    meta: { prefix: [ORDER, API_CALLING] },
  }),
  getOrderDetailSuccess: payload => ({
    type: TYPES.GET_ORDER_DETAIL,
    payload,
    meta: {
      prefix: [ORDER, API_CALLED_SUCCESS],
    },
  }),
  getOrderDetailFailure: payload => ({
    type: TYPES.GET_ORDER_DETAIL,
    payload,
    meta: {
      prefix: [ORDER, API_CALLED_FAILURE],
    },
  }),
  getOrderDetail: payload => async dispatch => {
    const api = API_URLS.ORDER.getOrderDetail(payload);
    dispatch(actions.gettingOrderDetail);
    const { response, error } = await apiCall(api);

    if (!error && response.status === 200) {
      dispatch(
        actions.getOrderDetailSuccess({
          data: response.data.data,
        }),
      );
    } else {
      dispatch(actions.getOrderDetailFailure({}));
    }
  },

  //CHANGE PAGE
  changePage: () => ({
    type: TYPES.CHANGE_PAGE,
    meta: { prefix: [ORDER] },
  }),
};

const initialState = {
  order: {},
  isFetching: false,
};

export const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TYPES.GET_ORDER_DETAIL:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { data } = action.payload;
          draft.order = data;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.histories = {};
          draft.isFetching = false;
        }
        break;
      case TYPES.CANCEL_ITEM_IN_ORDER:
      case TYPES.CANCEL_ORDER:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action) || isFailedApiCall(action)) {
          draft.isFetching = false;
        }
        break;
      default:
        return draft;
    }
  });
