import React, { Component } from 'react';
import '../../../index.scss';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
// import Select, { components } from 'react-select';
import 'react-notifications/lib/notifications.css';

import StationSearch from '../../../components/formItems/StationSearch';
import { COLORS } from '../../../constants/colors';
import './form.css';
import { actions as accountActions } from '../../../redux/AccountRedux';
import { actions as stationActions } from '../../../redux/StationRedux';

const field1 = {
  alignSelf: 'center',
};
const field2 = {
  height: '40px',
  width: '100%',
};
const field3 = { display: 'flex', marginBottom: '30px' };
const field4 = { width: '30px', height: '15px' };
const field5 = { height: '40px', background: COLORS.primary, borderRadius: '4px', color: 'white', border: 'none' };
const field6 = { color: 'red', marginLeft: '10px', paddingTop: '9px' };

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false,
      accountInformation: {},
      emailError: '',
      referralCodeError: '',
      nameError: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { accountInformation } = props;
    if (accountInformation && accountInformation.phone && !state.init) {
      if (!accountInformation.name) {
        state.nameError = 'Hãy nhập họ và tên !';
      }
      return { accountInformation: { ...accountInformation }, init: true, state };
    }
    return null;
  }

  // componentDidMount = () => {
  //   const { accountInformation } = this.state;
  // }

  changeName = (e) => {
    e.preventDefault();
    const { accountInformation } = this.state;
    if (!e.target.value) {
      accountInformation.name = e.target.value;
      this.setState({ nameError: 'Hãy nhập họ và tên !' });
    } else {
      accountInformation.name = e.target.value;
      this.setState({ accountInformation, nameError: '' });
    }
  };

  changePhone = (e) => {
    const re = new RegExp(/^(0)[1-9]([0-9]{8}[0-9]?)\b/);
    e.preventDefault();
    const { accountInformation } = this.state;
    if (!re.test(e.target.value)) {
      accountInformation.phone = e.target.value;
      this.setState({ phoneError: 'SĐT sai định dạng !' });
    }
    // if (!e.target.value){
    //   this.setState({ phoneError: 'Hãy nhập SĐT !'})
    // }
    if (re.test(e.target.value) && e.target.value) {
      accountInformation.phone = e.target.value;
      this.setState({ accountInformation, phoneError: '' });
    }
  };

  changeMail = (e) => {
    const re = /^[a-z][a-z0-9]*([_\.][a-z0-9]+)*@[a-z0-9]+(\.[a-z0-9]+)+$/;
    e.preventDefault();
    const { accountInformation } = this.state;
    if (!re.test(e.target.value)) {
      accountInformation.email = e.target.value;
      this.setState({ emailError: 'Email sai định dạng !' });
    }
    if (!e.target.value) {
      this.setState({ emailError: '' });
    }
    if (re.test(e.target.value) && e.target.value) {
      accountInformation.email = e.target.value;
      this.setState({ accountInformation, emailError: '' });
    }
  };

  changeStation = (value) => {
    const { accountInformation } = this.state;
    accountInformation.station_code = value.value;
    this.setState({ accountInformation });
  };

  changeAddress = (e) => {
    e.preventDefault();
    const { accountInformation } = this.state;
    accountInformation.address = e.target.value;
    accountInformation.default_shipping_address = e.target.value;
    this.setState({ accountInformation });
  };

  changeGender = (e) => {
    const { accountInformation } = this.state;
    if (!e.target.value) {
      accountInformation.gender = e.target.value;
      this.setState({ genderError: 'Hãy chọn giới tính !' });
    } else {
      accountInformation.gender = e.target.value;
      this.setState({ accountInformation, genderError: '' });
    }
  };

  changeRefferallCode = (e) => {
    const re = /^(0)[1-9]([0-9]{8}[0-9]?)\b/;
    e.preventDefault();
    const { accountInformation } = this.state;
    if (!re.test(e.target.value)) {
      accountInformation.referral_code = e.target.value;
      this.setState({ referralCodeError: 'Mã giới thiệu sai định dạng !' });
    }
    if (!e.target.value) {
      this.setState({ referralCodeError: '' });
    }
    if (re.test(e.target.value) && e.target.value) {
      accountInformation.referral_code = e.target.value;
      this.setState({ accountInformation, referralCodeError: '' });
    }
  };

  submitForm = () => {
    const { accountInformation, nameError, phoneError, emailError, referralCodeError, genderError } = this.state;
    const Payload = {
      address: accountInformation.address,
      default_shipping_address: accountInformation.default_shipping_address,
      email: accountInformation.email,
      gender: accountInformation.gender,
      phone: accountInformation.phone || '0900000000',
      referral_code: accountInformation.referral_code,
      station_code: accountInformation.station_code,
      name: accountInformation.name,
    };
    if (!nameError && !phoneError && !emailError && !referralCodeError && !genderError) {
      this.props.updateAccountInfo(Payload, {
        onSuccess: () => {
          NotificationManager.success('Cập nhật thông tin thành công!', 'Thành công', 2000);
          if (this.props.location?.state?.newAccount) {
            this.props.history.goBack();
          }
        },
        onError: () => {
          NotificationManager.error('Cập nhật thông tin thất bại!', 'Thất bại', 2000);
        },
      });
    }
  };

  render() {
    const { accountInformation, nameError, phoneError, emailError, referralCodeError } = this.state;
    const userStation = this.props.stationList?.find((item) => item.code === accountInformation.station_code);

    return (
      <div className="form-info-user">
        <div className="font-header" style={{ marginBottom: '50px' }}>
          Thông tin cá nhân
        </div>
        <div className="row personal-form" style={field3}>
          <div className="col-sm-3 font-range" style={field1}>
            Họ và tên *
          </div>
          <div className="col-sm-9" style={field1}>
            <input
              type="text"
              name="accountName"
              className="font-range"
              value={accountInformation.name}
              style={{ ...field2, borderColor: nameError ? 'red' : '' }}
              onChange={this.changeName}
            />
            <div style={{ ...field6, display: nameError !== '' ? '' : 'none' }}>{nameError}</div>
          </div>
        </div>
        <div className="row" style={field3}>
          <div className="col-sm-3 font-range" style={field1}>
            Số điện thoại *
          </div>
          <div className="col-sm-9" style={field1}>
            <input
              type="number"
              className="font-range"
              style={{ ...field2, borderColor: phoneError ? 'red' : '' }}
              value={accountInformation.phone}
              disabled
            />
            <div style={{ ...field6, display: phoneError !== '' ? '' : 'none' }}>{phoneError}</div>
          </div>
        </div>
        <div className="row" style={field3}>
          <div className="col-sm-3 font-range" style={field1}>
            Email
          </div>
          <div className="col-sm-9" style={field1}>
            <input
              className="font-range"
              style={{ ...field2, borderColor: emailError ? 'red' : '' }}
              value={accountInformation.email}
              onChange={this.changeMail}
            />
            <div style={{ ...field6, display: emailError !== '' ? '' : 'none' }}>{emailError}</div>
          </div>
        </div>
        <div className="row station-row" style={field3}>
          <div className="col-sm-3 font-range" style={field1}>
            Trạm nhận hàng *
          </div>
          <div className="col-sm-9" style={field1}>
            <StationSearch
              className="station-search"
              classNamePrefix="station-search"
              isSearchable
              selectedValue={{ value: userStation?.code, label: userStation?.name }}
              onChange={this.changeStation}
            />
          </div>
        </div>
        <div className="row" style={field3}>
          <div className="col-sm-3 font-range" style={field1}>
            Giới tính
          </div>
          <div className="col-sm-9">
            <div style={{ display: 'flex' }}>
              <input
                className="center1"
                type="radio"
                value="male"
                name="gender"
                style={field4}
                checked={accountInformation.gender === 'male'}
                onChange={this.changeGender}
              />
              Nam
              <div style={{ width: '25px' }} />
              <input
                type="radio"
                value="female"
                name="gender"
                style={{ ...field4, color: 'red' }}
                checked={accountInformation.gender === 'female'}
                onChange={this.changeGender}
              />
              Nữ
            </div>
            {/* <div style={{ ...field6, display: genderError !== '' ? '' : 'none' }}>{genderError}</div> */}
          </div>
        </div>
        <div className="row" style={field3}>
          <div className="col-sm-3 font-range" style={field1}>
            Địa chỉ ship
          </div>
          <div className="col-sm-9" style={field1}>
            <input
              className="font-range"
              style={field2}
              value={accountInformation.default_shipping_address || accountInformation.address}
              onChange={this.changeAddress}
            />
          </div>
        </div>
        <div className="row" style={field3}>
          <div className="col-sm-3 font-range" style={field1}>
            Mã giới thiệu
          </div>
          <div className="col-sm-9" style={field1}>
            <input
              type="number"
              className="font-range"
              style={{ ...field2, borderColor: referralCodeError ? 'red' : '' }}
              value={accountInformation.referral_code}
              onChange={this.changeRefferallCode}
            />
            <div style={{ ...field6, display: referralCodeError !== '' ? '' : 'none' }}>{referralCodeError}</div>
          </div>
        </div>
        <div className="row" style={field3}>
          <div className="col-sm-3 font-range" style={field1} />
          <div className="col-sm-9" style={field1}>
            <button
              disabled={!!(phoneError || nameError || emailError || referralCodeError || !accountInformation.name)}
              className="font-range"
              style={{
                ...field5,
                cursor:
                  phoneError || nameError || emailError || referralCodeError || !accountInformation.name
                    ? 'not-allowed'
                    : '',
              }}
              onClick={this.submitForm}>
              {' '}
              Cập nhập thông tin{' '}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stationList: state.stationReducer.stations,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccountInfo: (payload, meta) => dispatch(accountActions.updateAccountInfo(payload, meta)),
  getStations: ({ params }) => dispatch(stationActions.getStations(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
