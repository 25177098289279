import produce from 'immer';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { API_CALLING, API_CALLED_SUCCESS, API_CALLED_FAILURE, PRODUCT } = PREFIX;
const _types = typesWithPrefix(PRODUCT);
const TYPES = {
  GET_PRODUCTS: _types('GET_PRODUCTS'),
  GET_ALL_PRODUCTS: _types('GET_ALL_PRODUCTS'),
  GET_PRODUCTS_IN_CATEGORY: _types('GET_PRODUCTS_IN_CATEGORY'),
  CHANGE_PAGE: _types('CHANGE_PAGE'),
  INVALIDATE_PRODUCTS_LIST: _types('INVALIDATE_PRODUCTS_LIST'),
  GET_PRODUCT_DETAIL: _types('GET_PRODUCT_DETAIL'),
  GET_PROMOTION_PRODUCT: _types('GET_PROMOTION_PRODUCT'),
  GET_BEST_SELLERS: _types('GET_BEST_SELLERS'),
  CLEAR_BEST_SELLERS: _types('CLEAR_BEST_SELLERS'),
};

export const actions = {
  // lấy danh sách sản phẩm thông thường
  gettingProducts: () => ({
    type: TYPES.GET_PRODUCTS,
    meta: { prefix: [PRODUCT, API_CALLING] },
  }),
  getProductsSuccess: (payload) => ({
    type: TYPES.GET_PRODUCTS,
    payload,
    meta: {
      prefix: [PRODUCT, API_CALLED_SUCCESS],
    },
  }),
  getProductsFailure: (payload) => ({
    type: TYPES.GET_PRODUCTS,
    payload,
    meta: {
      prefix: [PRODUCT, API_CALLED_FAILURE],
    },
  }),
  getProducts: (params, mode) => async (dispatch, getState) => {
    const { productReducer } = getState();
    // const params = {
    //   page: meta.page || productReducer.meta
    // }
    // const params = meta && meta.page ? meta : productReducer.meta;
    const api = API_URLS.PRODUCT.getProducts(params);
    dispatch(actions.gettingProducts());
    const { response, error } = await apiCall(api);
    const { data } = response?.data ? response?.data : '';
    const page = params && params.page ? params.page : productReducer.meta.page;
    const page_size = params && params.page_size ? params.page_size : productReducer.meta.page_size;
    if (!error && response.status === 200) {
      if (data !== null) {
        switch (mode) {
          case 'CATEGORY':
            dispatch(
              actions.getProductsInCategorySuccess({
                page,
                page_size,
                data,
              }),
            );
            break;
          case 'DEFAULT':
            dispatch(actions.getProductsSuccess({ page, page_size, data }));
            break;
          default:
            dispatch(actions.getProductsSuccess({ page, page_size, data }));
        }
      }
    } else {
      switch (mode) {
        case 'CATEGORY':
          dispatch(actions.getProductsInCategoryFailure({}));
          break;
        case 'DEFAULT':
          dispatch(actions.getProductsFailure({}));
          break;
        default:
          dispatch(actions.getProductsFailure({}));
      }
    }
  },
  getProductsIfNeed: (meta, mode) => async (dispatch, getState) => {
    const { productReducer } = getState();
    const isFetchingList = productReducer.isFetchingList;
    const didInvalidateList = productReducer.didInvalidateList;
    if (!isFetchingList && didInvalidateList) {
      dispatch(actions.getProducts(meta, mode));
    }
  },

  // GET ALL PRODUCTS
  gettingAllProducts: () => ({
    type: TYPES.GET_ALL_PRODUCTS,
    meta: { prefix: [PRODUCT, API_CALLING] },
  }),
  getAllProductsSuccess: (payload) => ({
    type: TYPES.GET_ALL_PRODUCTS,
    payload,
    meta: {
      prefix: [PRODUCT, API_CALLED_SUCCESS],
    },
  }),
  getAllProductsFailure: (payload) => ({
    type: TYPES.GET_ALL_PRODUCTS,
    payload,
    meta: {
      prefix: [PRODUCT, API_CALLED_FAILURE],
    },
  }),
  getAllProducts: () => async (dispatch, getState) => {
    const params = { page: -1, page_size: -1 };
    const api = API_URLS.PRODUCT.getProducts(params);
    dispatch(actions.gettingAllProducts());
    const { response, error } = await apiCall(api);
    const { data } = response?.data ? response.data : {};
    if (!error && response.status === 200) {
      if (data !== null) {
        dispatch(actions.getAllProductsSuccess(data));
      }
    } else {
      dispatch(actions.getAllProductsFailure({}));
    }
  },

  // lấy danh sách sản phẩm theo nhóm
  gettingProductsInCategory: () => ({
    type: TYPES.GET_PRODUCTS_IN_CATEGORY,
    meta: { prefix: [PRODUCT, API_CALLING] },
  }),
  getProductsInCategorySuccess: (payload) => ({
    type: TYPES.GET_PRODUCTS_IN_CATEGORY,
    payload,
    meta: {
      prefix: [PRODUCT, API_CALLED_SUCCESS],
    },
  }),
  getProductsInCategoryFailure: (payload) => ({
    type: TYPES.GET_PRODUCTS_IN_CATEGORY,
    payload,
    meta: {
      prefix: [PRODUCT, API_CALLED_FAILURE],
    },
  }),

  getProductByCatCode: (query) => async (dispatch, getState) => {
    const { productReducer } = getState();
    const meta = productReducer.categoryProducts.meta;
    const pagination = meta.pagination;
    const baseParams = {
      category_codes: query?.categoryCode,
      supplier_codes: query?.supplierCode,
      page: query?.page || pagination.current,
      page_size: query?.pageSize || pagination.pageSize,
    };
    const api = API_URLS.PRODUCT.getProducts(baseParams);
    dispatch(actions.gettingProductsInCategory());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(
        actions.getProductsInCategorySuccess({
          query,
          data: response.data,
        }),
      );
    } else {
      dispatch(actions.getProductsInCategoryFailure({}));
    }
  },

  getProductByCatCodeIfNeed: (filterOption) => async (dispatch, getState) => {
    const { productReducer } = getState();
    const isFetching = productReducer.categoryProducts.isFetching;
    const didInvalidate = productReducer.categoryProducts.didInvalidate;
    const meta = productReducer.categoryProducts.meta;
    const query = meta.query;
    const pagination = meta.pagination;
    const isQueryChanged =
      pagination.page !== filterOption.page ||
      query.categoryCode !== filterOption.categoryCode ||
      query.supplierCode !== filterOption.supplierCode;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getProductByCatCode(filterOption));
    }
  },

  changePage: () => ({
    type: TYPES.CHANGE_PAGE,
    meta: { prefix: [PRODUCT] },
  }),

  //GET PRODUCT DETAIL
  getProductDetail: (code) => async (dispatch) => {
    dispatch(actions.gettingProductDetail());
    const api = API_URLS.PRODUCT.getProductDetail(code);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const data = response.data;
      if (data !== null) {
        dispatch(actions.getProductDetailSuccess(data));
      }
    } else {
      dispatch(actions.getProductDetailFailure);
    }
  },
  getProductDetailIfNeed: (product_code) => (dispatch, getState) => {
    const { productReducer } = getState();
    const { page, isFetchingDetail, didInvalidateList } = productReducer;
    if (!isFetchingDetail && didInvalidateList) {
      dispatch(actions.getProductDetail(product_code));
    }
  },

  gettingProductDetail: () => ({
    type: TYPES.GET_PRODUCT_DETAIL,
    meta: { prefix: [PRODUCT, API_CALLING] },
  }),
  getProductDetailSuccess: (payload) => ({
    type: TYPES.GET_PRODUCT_DETAIL,
    meta: { prefix: [PRODUCT, API_CALLED_SUCCESS] },
    payload,
  }),
  getProductDetailFailure: () => ({
    type: TYPES.GET_PRODUCT_DETAIL,
    meta: { prefix: [PRODUCT, API_CALLED_FAILURE] },
  }),

  // * PROMOTION PRODUCT *//
  //GET PROMOTION PRODUCT
  getPromotionProducts: (params) => async (dispatch) => {
    dispatch(actions.gettingPromotionProducts());
    const api = API_URLS.PRODUCT.getPromotionProducts(params);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const data = response.data;
      if (data !== null) {
        dispatch(actions.getPromotionProductsSuccess(data));
      }
    } else {
      dispatch(actions.getPromotionProductsFailure);
    }
  },

  gettingPromotionProducts: () => ({
    type: TYPES.GET_PROMOTION_PRODUCT,
    meta: { prefix: [PRODUCT, API_CALLING] },
  }),
  getPromotionProductsSuccess: (payload) => ({
    type: TYPES.GET_PROMOTION_PRODUCT,
    meta: { prefix: [PRODUCT, API_CALLED_SUCCESS] },
    payload,
  }),
  getPromotionProductsFailure: () => ({
    type: TYPES.GET_PROMOTION_PRODUCT,
    meta: { prefix: [PRODUCT, API_CALLED_FAILURE] },
  }),

  // *BEST SELLERS* //
  //GET BEST SELLERS
  getBestSellers: (params) => async (dispatch) => {
    dispatch(actions.gettingBestSellers());
    const api = API_URLS.PRODUCT.getBestSellers(params);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const data = response.data;
      if (data !== null) {
        dispatch(actions.getBestSellersSuccess(data));
      }
    } else {
      dispatch(actions.getBestSellersFailure);
    }
  },

  gettingBestSellers: () => ({
    type: TYPES.GET_BEST_SELLERS,
    meta: { prefix: [PRODUCT, API_CALLING] },
  }),
  getBestSellersSuccess: (payload) => ({
    type: TYPES.GET_BEST_SELLERS,
    meta: { prefix: [PRODUCT, API_CALLED_SUCCESS] },
    payload,
  }),
  getBestSellersFailure: () => ({
    type: TYPES.GET_BEST_SELLERS,
    meta: { prefix: [PRODUCT, API_CALLED_FAILURE] },
  }),

  //CLEAR BEST SELLERS
  clearBestSellers: () => ({
    type: TYPES.CLEAR_BEST_SELLERS,
    meta: { prefix: [PRODUCT, API_CALLED_FAILURE] },
  }),
};

const initialState = {
  products: [],
  allProducts: [],
  product: {},
  isFetchingList: false,
  isFetchingDetail: false,
  didInvalidateList: true,
  meta: {
    page: 1,
    page_size: 10,
    search: '',
  },
  //category products
  categoryProducts: {
    list: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 16,
        total: 0,
      },
      query: {
        categoryCode: '',
      },
    },
    isFetching: false,
    didInvalidate: true,
  },
  //promotion products
  promotionProducts: {
    list: [],
    meta: {
      page: null,
      pageSize: null,
      total: null,
    },
    isFetching: false,
    didInvalidate: true,
  },
  //best sellers
  bestSellers: {
    list: [],
    meta: {
      page: null,
      pageSize: null,
      total: null,
    },
    isFetching: false,
    didInvalidate: true,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPES.GET_PRODUCTS:
        if (isCallingApi(action)) {
          draft.isFetchingList = true;
        } else if (isSuccessfulApiCall(action)) {
          const { page, page_size, data } = action.payload;
          draft.products = data !== null ? data : [];
          draft.meta.page = page;
          draft.meta.page_size = page_size;
          draft.isFetchingList = false;
          draft.didInvalidateList = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetchingList = false;
          draft.didInvalidateList = false;
        }
        break;

      case TYPES.GET_ALL_PRODUCTS:
        if (isCallingApi(action)) {
          draft.isFetchingList = true;
        } else if (isSuccessfulApiCall(action)) {
          const data = action.payload;
          draft.allProducts = data !== null ? data : [];
          draft.isFetchingList = false;
          draft.didInvalidateList = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetchingList = false;
          draft.didInvalidateList = false;
        }
        break;

      case TYPES.GET_PRODUCTS_IN_CATEGORY:
        if (isCallingApi(action)) {
          draft.categoryProducts.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const {
            query,
            data: { data, page, page_size, total },
          } = action.payload;
          draft.categoryProducts.list = data !== null ? data : [];
          draft.categoryProducts.meta.pagination.pageSize = page_size;
          draft.categoryProducts.meta.pagination.page = page;
          draft.categoryProducts.meta.pagination.total = total;
          draft.categoryProducts.meta.query = {
            categoryCode: query?.categoryCode,
            supplierCode: query?.supplierCode,
          };
          draft.categoryProducts.isFetching = false;
          draft.categoryProducts.didInvalidate = false;
        } else if (isFailedApiCall(action)) {
          draft.categoryProducts.isFetching = false;
          draft.categoryProducts.didInvalidate = false;
        }
        break;

      case TYPES.CHANGE_PAGE:
        draft.meta.page = draft.meta.page + 1;
        break;

      case TYPES.GET_PRODUCT_DETAIL:
        if (isCallingApi(action)) {
          draft.isFetchingList = true;
        } else if (isSuccessfulApiCall(action)) {
          const { data } = action.payload;
          if (data !== null) {
            draft.product = data;
          }
          draft.isFetchingList = false;
          draft.didInvalidateList = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetchingList = false;
          draft.didInvalidateList = false;
        }
        break;

      case TYPES.GET_PROMOTION_PRODUCT:
        if (isCallingApi(action)) {
          draft.promotionProducts.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { page, page_size, data, total } = action.payload;
          draft.promotionProducts.list = data !== null ? data : [];
          draft.promotionProducts.meta.page = page;
          draft.promotionProducts.meta.pageSize = page_size;
          draft.promotionProducts.meta.total = total;
          draft.promotionProducts.isFetching = false;
          draft.promotionProducts.didInvalidate = false;
        } else if (isFailedApiCall(action)) {
          draft.promotionProducts.isFetching = false;
          draft.promotionProducts.didInvalidate = false;
        }
        break;

      case TYPES.GET_BEST_SELLERS:
        if (isCallingApi(action)) {
          draft.bestSellers.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { page, page_size, data, total } = action.payload;
          draft.bestSellers.list = data !== null ? data : [];
          draft.bestSellers.meta.page = page;
          draft.bestSellers.meta.pageSize = page_size;
          draft.bestSellers.meta.total = total;
          draft.bestSellers.isFetching = false;
          draft.bestSellers.didInvalidate = false;
        } else if (isFailedApiCall(action)) {
          draft.bestSellers.isFetching = false;
          draft.bestSellers.didInvalidate = false;
        }
        break;

      case TYPES.CLEAR_BEST_SELLERS:
        draft.bestSellers.list = [];
        break;

      default:
        return draft;
    }
  });
