import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';

import { actions as accountActions } from '../../../redux/AccountRedux';
import { actions as cartActions } from '../../../redux/CartRedux';
import 'react-notifications/lib/notifications.css';
const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});
class Product extends Component {
  state = {};

  addItemToCart = () => {
    const payload = {
      product_code: this.props.product.code,
      quantity: 1,
    };
    this.props.addItemToCart(payload, {
      onSuccess: () => {
        NotificationManager.success('Thêm vào giỏ hàng thành công!', 'Thành công', 2000);
      },
      onError: () => {
        NotificationManager.error('Thêm vào giỏ hàng thất bại!', 'Thất bại', 2000);
      },
    });
  };

  removeWishListProduct = () => {
    this.props.removeWishListProduct(this.props.product.code, {
      onSuccess: () => {
        NotificationManager.success('Xóa sản phẩm khỏi danh sách yêu thích thành công!', 'Thành công', 2000);
        this.props.getAccountWishList();
        // this.props.refresh();
      },
      onError: () => {
        NotificationManager.error('Xóa sản phẩm khỏi danh sách yêu thích thất bại!', 'Thất bại', 2000);
      },
    });
  };

  render() {
    const { product } = this.props;
    return (
      <div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-sm-3">
            <img src={product.image.listing_url} style={{ width: '100%', height: '100%' }} />
          </div>
          <div className="col-sm-9">
            <div className="font-product" style={{ marginBottom: '10px' }}>
              {' '}
              {product.name}
            </div>
            <div className="font-category-of-product" style={{ marginBottom: '10px' }}>
              {' '}
              {product.category.name}
            </div>
            <div className="font-price1">{formatter.format(product.price.price)}</div>
            <div
              style={{
                position: 'absolute',
                bottom: '0px',
              }}>
              <div className="row">
                <div>
                  {' '}
                  <button className="add-to-cart-button2" onClick={this.addItemToCart}>
                    Thêm vào giỏ
                  </button>
                </div>
                <div style={{ marginRight: '10px' }} />
                <div>
                  {' '}
                  <button className="delete-cart-button" onClick={this.removeWishListProduct}>
                    Xoá
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ border: '1px solid #E3E3E3', marginBottom: '20px' }} />
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addItemToCart: (payload, meta) => dispatch(cartActions.addItemToCart(payload, meta)),
  getAccountWishList: () => dispatch(accountActions.getAccountWishList()),
  removeWishListProduct: (productCode, meta) => dispatch(accountActions.removeWishListProduct(productCode, meta)),
});

export default connect(null, mapDispatchToProps)(Product);
