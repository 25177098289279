// import lodash from 'lodash';
import React, { Component } from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { actions as authActions } from '../../redux/AuthRedux';
const style2 = {
  marginRight: '15px',
  marginLeft: '15px',
};
const style3 = {
  dispaly: 'flex',
  flexDirection: 'column',
  height: '55%',
  width: '100%',
};
const style4 = { height: '20%', display: 'flex', alignItems: 'center' };
const iconStyle = {
  marginRight: '20px',
  marginLeft: '20px',
};
const lineStyle = {
  border: '1px solid #F2F2F2',
  marginRight: '20px',
  marginLeft: '20px',
};
class Sidebar extends Component {
  state = {
    acitve: 0,
    pathName: '',
  };

  componentDidMount = () => {
    this.setState({ pathName: this.props.history.location.pathname });
  };

  logOut = () => {
    this.props.history.push('/');
    this.props.logOut();
  };

  onSelect = () => {
    this.props.history.listen((location) => {
      this.setState({ pathName: location.pathname });
      if (this.props.closeSidebar) {
        this.props.closeSidebar();
      }
    });
  };

  render() {
    const { isCollaborator } = this.props;
    return (
      <>
        <div style={{ display: 'flex', height: '25%', width: '100%' }}>
          <div className="center1" style={{ marginLeft: '15px' }}>
            <Avatar size={40} round="20px" src="/assets/images/profile_default_icon.png" style={style2} />
          </div>
          <div className="font-below-category center1 "> {this.props.accountInformation.name}</div>
        </div>
        <div style={lineStyle}> </div>
        <div className="col-sm-12" style={style3}>
          <Link to="/account/information" style={style4} className="link" onClick={this.onSelect}>
            <i
              className="fa fa-user-circle fa-2x"
              aria-hidden="true"
              style={{ ...iconStyle, color: this.state.pathName === '/account/information' ? '#ED1C24' : '#717171' }}
            />
            <div
              className="font-range"
              style={{ color: this.state.pathName === '/account/information' ? '#ED1C24' : '' }}>
              Thông tin cá nhân
            </div>
          </Link>

          <Link to="/account/notification" style={style4} onClick={this.onSelect}>
            <i
              className="fa fa-bell fa-2x"
              aria-hidden="true"
              style={{ ...iconStyle, color: this.state.pathName === '/account/notification' ? '#ED1C24' : '#717171' }}
            />
            <div
              className="font-range"
              style={{ color: this.state.pathName === '/account/notification' ? '#ED1C24' : '' }}>
              Thông báo
            </div>
          </Link>
          <Link to="/account/wishlists" style={style4} onClick={this.onSelect}>
            <i
              className="fa fa-heart fa-2x"
              aria-hidden="true"
              style={{ ...iconStyle, color: this.state.pathName === '/account/wishlists' ? '#ED1C24' : '#717171' }}
            />
            <div
              className="font-range"
              style={{ color: this.state.pathName === '/account/wishlists' ? '#ED1C24' : '' }}>
              Danh sách yêu thích
            </div>
          </Link>
          <Link to="/account/history" style={style4} onClick={this.onSelect}>
            <i
              className="fa fa-clock-o fa-2x"
              aria-hidden="true"
              style={{
                marginRight: '21px',
                marginLeft: '22px',
                color: this.state.pathName === '/account/history' ? '#ED1C24' : '#717171',
              }}
            />
            <div className="font-range" style={{ color: this.state.pathName === '/account/history' ? '#ED1C24' : '' }}>
              Lịch sử mua hàng
            </div>
          </Link>
          {isCollaborator ? (
            <Link to="/account/discount" style={style4} onClick={this.onSelect}>
              <i
                className="fa fa-money fa-2x"
                aria-hidden="true"
                style={{
                  marginRight: '17px',
                  marginLeft: '19px',
                  color: this.state.pathName === '/account/discount' ? '#ED1C24' : '#717171',
                }}
              />
              <div
                className="font-range"
                style={{ color: this.state.pathName === '/account/discount' ? '#ED1C24' : '' }}>
                Chiết khấu
              </div>
            </Link>
          ) : (
            <></>
          )}
        </div>
        <div style={lineStyle}> </div>
        <a style={{ ...style4, textDecoration: 'none', cursor: 'pointer', marginLeft: '15px' }} onClick={this.logOut}>
          <i className="fa fa-sign-out fa-2x" aria-hidden="true" style={iconStyle} />
          <div className="font-range">Đăng xuất</div>
        </a>
        <a className="close-sidebar-filter" onClick={this.props.closeSidebar}>
          <i className="fa fa-close fa-2x" aria-hidden="true" style={{ fontSize: 30 }} />
        </a>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accountInformation: state.accountReducer.accountInformation,
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(authActions.logOut()),
  invalidateLog: () => dispatch(authActions.invalidateLog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
