import { debounce } from 'lodash';
import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { Link, withRouter } from 'react-router-dom';

import { actions as accountActions } from '../../../redux/AccountRedux';
import { actions as cartActions } from '../../../redux/CartRedux';
import { slugPath } from '../../../utils/product';
import Voucher from './voucher';

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});

class Order extends Component {
  state = {
    open: false,
    note: '',
    shippingAddress: '',
    initAddress: false,
    initNote: false,
    currentVoucher: '',
    initVoucher: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { note, shipping_address, vouchers, gift_code } = props;
    if (shipping_address && !state.initAddress) {
      return { shippingAddress: shipping_address, initAddress: true };
    }
    if (note && !state.initNote) {
      return { note, initNote: true };
    }
    if (vouchers && !state.initVoucher && gift_code) {
      const voucher = vouchers.find((item) => item.promotion_discount?.gift_code === gift_code);
      return { currentVoucher: voucher, initNote: true };
    }
    return null;
  }

  componentDidMount = () => {
    this.props.getVouchers();
    this.setState({ note: this.props.note });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  applyVoucher = (voucher) => {
    const payload = {
      gift_code: voucher.promotion_discount?.gift_code,
    };
    this.setState({ currentVoucher: voucher, open: false });
    NotificationManager.success('Sử dụng mã giảm giá thành công!', 'Thành công', 2000);
    this.props.applyVoucher(payload);
  };

  addNote = (payload) => {
    this.props.addNoteForCart(payload);
  };

  addNoteThrottled = debounce((payload) => {
    this.addNote(payload);
  }, 1000);

  handleInputNote = (e) => {
    this.setState({ note: e.target.value });
    const payload = {
      note: e.target.value,
      shipping_address: this.state.shippingAddress,
    };
    this.addNoteThrottled(payload);
  };

  handleInputAddress = (e) => {
    this.setState({ shippingAddress: e.target.value });
    const payload = {
      note: this.state.note,
      shipping_address: e.target.value,
    };
    this.addNoteThrottled(payload);
  };

  checkoutCart = () => {
    const { shippingAddress } = this.state;
    if (!shippingAddress) {
      NotificationManager.error('Bạn cần nhập địa chỉ giao hàng!', 'Thất bại', 2000);
    } else {
      const { accountInformation, updateAccountInfo } = this.props;
      if (!accountInformation.default_shipping_address) {
        const updateAccountPayload = {
          address: shippingAddress,
          default_shipping_address: shippingAddress,
          email: accountInformation.email,
          gender: accountInformation.gender,
          phone: accountInformation.phone,
          referral_code: accountInformation.referral_code,
          station_code: accountInformation.station_code,
          name: accountInformation.name,
        };

        updateAccountInfo(updateAccountPayload);
      }
      this.props.checkoutCart({
        onSuccess: () => {
          this.props.history.push('/success');
        },
        onError: () => {
          NotificationManager.error('Có lỗi khi đặt đơn hàng, hãy thử lại!', 'Thất bại', 2000);
        },
      });
    }
  };

  render() {
    // const userStation = this.props.stationList?.find((item) => ( item.code === this.props.accountInformation?.station_code ));
    const giftProduct = this.props.allProducts.find((item) => item.code === this.props.gift_product);

    return (
      <div className="container" style={{ maxWidth: 1200, marginBottom: '50px' }}>
        <div className="row">
          <div className="col-12">
            <div className="font-header" style={{ marginBottom: '50px' }}>
              Đặt hàng
            </div>
          </div>
          <div className="col-12 col-md-8 cart-left">
            <div className="row">
              {/* <div className="col-12">
                <div className="font-order-header" style={{ marginBottom: '20px' }}>
                  Trạm nhận hàng
                </div>
                <div className="border-box" style={{ width: '100%', height: '135px' }}>
                  <div style={{ display: 'flex' }}>
                    <div className="col-2" style={{ height: '135px' }}>
                      <img
                        className="vertical-center"
                        src="/assets/images/Station.png"
                        style={{ maxWidth: '80px', width: '100%' }}
                      />
                    </div>
                    <div className="col-10">
                      <div className="vertical-center" style={{ marginLeft: '20px' }}>
                        <div className="font-below-category" style={{ marginBottom: '10px' }}>
                          {userStation?.name}
                        </div>
                        <div className="font-range">{userStation?.address?.text}, {userStation?.address?.district?.name}, {userStation?.address?.city?.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-12" style={{ marginTop: '20px' }}>
                <div className="font-order-header" style={{ marginBottom: '20px' }}>
                  Chọn mã giảm giá
                </div>
                <div className="border-box" style={{ width: '100%', height: '135px' }}>
                  <div style={{ display: 'flex' }}>
                    <div className="col-2" style={{ height: '135px' }}>
                      <img
                        className="vertical-center"
                        src="/assets/images/Voucher.png"
                        style={{ maxWidth: '80px', width: '100%' }}
                      />
                    </div>
                    <div className="col-10">
                      <div className="vertical-center" style={{ position: 'relative', marginLeft: 20, height: 44 }}>
                        {this.state.currentVoucher ? (
                          <div
                            className="font-below-category vertical-center"
                            style={{ display: 'inline-block', marginRight: 25 }}>
                            {this.state.currentVoucher?.promotion_discount?.gift_code} -{' '}
                            {this.state.currentVoucher?.name}
                          </div>
                        ) : (
                          <div
                            className="font-below-category vertical-center"
                            style={{ display: 'inline-block', marginRight: 25 }}>
                            {this.props.vouchers?.length ? 'Chọn voucher giảm giá' : 'Hiện không có mã giảm giá'}
                          </div>
                        )}
                        {this.props.vouchers?.length ? (
                          <button
                            style={{
                              border: 'none',
                              background: 'transparent',
                              height: '20px',
                              position: 'absolute',
                              right: 0,
                            }}
                            onClick={this.onOpenModal}>
                            <img src="/assets/images/RightArrow.png" />
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12" style={{ marginTop: '20px' }}>
                <div className="font-order-header" style={{ marginBottom: '20px' }}>
                  Địa chỉ
                </div>
                <div className="border-box" style={{ width: '100%', height: '135px' }}>
                  <div style={{ display: 'flex' }}>
                    <div className="col-2" style={{ height: '135px' }}>
                      <img
                        className="vertical-center"
                        src="/assets/images/Station.png"
                        style={{ maxWidth: '80px', width: '100%' }}
                      />
                      <div
                        style={{
                          border: '1px solid #0CA3FF',
                          opacity: '0.2',
                          position: 'absolute',
                          right: '-18px',
                          height: '60%',
                          top: '20%',
                        }}
                      />
                    </div>
                    <div className="col-10">
                      <div className="vertical-center" style={{ position: 'relative', marginLeft: 20 }}>
                        <input
                          style={{ width: '100%', border: 'none', marginLeft: '10px', fontStyle: 'italic' }}
                          placeholder="Nhập địa chỉ"
                          required
                          onChange={this.handleInputAddress}
                          value={this.state.shippingAddress}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12" style={{ marginTop: '20px' }}>
                <div className="font-order-header" style={{ marginBottom: '20px' }}>
                  Viết ghi chú
                </div>
                <div className="border-box" style={{ width: '100%', height: '135px' }}>
                  <div style={{ display: 'flex' }}>
                    <div className="col-2" style={{ height: '135px' }}>
                      <img
                        className="vertical-center"
                        src="/assets/images/Note.png"
                        style={{ maxWidth: '80px', width: '85%' }}
                      />
                      <div
                        style={{
                          border: '1px solid red',
                          opacity: '0.2',
                          position: 'absolute',
                          right: '-18px',
                          height: '60%',
                          top: '20%',
                        }}
                      />
                    </div>
                    <div className="col-10">
                      <div className="vertical-center" style={{ position: 'relative', marginLeft: 20 }}>
                        <input
                          style={{ width: '100%', border: 'none', marginLeft: '10px', fontStyle: 'italic' }}
                          placeholder="Thêm ghi chú"
                          onChange={this.handleInputNote}
                          value={this.state.note}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 cart-right" style={{ marginTop: '48px' }}>
            <div className="border-box">
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div className="col-7"> Tiền hàng:</div>
                <div className="col-5 font-product" style={{ color: '#ED1C24', textAlign: 'right' }}>
                  {' '}
                  {formatter.format(this.props.total_amount)}
                </div>
              </div>
              {giftProduct ? (
                <div style={{ display: 'flex', marginTop: '10px' }}>
                  <div className="col-5"> Tặng sản phẩm:</div>
                  <Link
                    to={`/detail-product/${slugPath(giftProduct)}`}
                    className="col-7 font-product"
                    style={{ color: '#D1871B', textAlign: 'right' }}>
                    {giftProduct?.name}
                  </Link>
                </div>
              ) : (
                <div style={{ display: 'flex', marginTop: '10px' }}>
                  <div className="col-7"> Khuyến mại:</div>
                  <div className="col-5 font-product" style={{ color: '#D1871B', textAlign: 'right' }}>
                    {formatter.format(this.props.total_amount - this.props.payment_amount)}
                  </div>
                </div>
              )}
              {/* <div style={{ display: 'flex', marginTop: '10px' }}>
                <div className="col-sm-7"> Phí ship:</div>
                <div className="col-sm-5 font-product" style={{ color: COLORS.primary, textAlign: 'right' }}>
                  <em>Xác định sau</em>
                </div>
              </div> */}
              <div style={{ border: '1px solid #F2F2F2', width: '100%', marginTop: '10px', marginBottom: '10px' }} />
              <div style={{ display: 'flex', marginBottom: '10px' }}>
                <div className="col-5"> Tổng thanh toán:</div>
                <div className="col-7 font-price text-right">{formatter.format(this.props.payment_amount)}</div>
              </div>
            </div>
            <div>
              <button
                style={{ color: 'white' }}
                className="full-size font-continue-shopping shopping-button"
                onClick={this.checkoutCart}>
                Tiến hành đặt hàng
              </button>
            </div>
            <div>
              <Link to="/cart">
                <button
                  style={{ color: 'white', background: '#ED1C24' }}
                  className="full-size font-continue-shopping shopping-button">
                  Xem giỏ hàng
                </button>
              </Link>
            </div>
            {this.props.vouchers?.length ? (
              <Modal open={this.state.open} onClose={this.onCloseModal}>
                <div style={{ width: '100%', height: '100%' }}>
                  <div
                    className="center1 font-category-tree-header"
                    style={{ marginTop: '20px', textTransform: 'capitalize' }}>
                    {' '}
                    Voucher{' '}
                  </div>
                  <div className="modifyBorder" style={{ marginTop: '20px', marginBottom: '20px' }} />
                  <div style={{ height: '92%', overflowY: 'scroll' }}>
                    {this.props.vouchers?.map((voucher) => (
                      <Voucher
                        applyVoucher={this.applyVoucher}
                        voucher={voucher}
                        allProducts={this.props.allProducts}
                        currentVoucher={this.state.currentVoucher}
                      />
                    ))}
                  </div>
                </div>
              </Modal>
            ) : null}
          </div>
          <div className="col-12" style={{ marginTop: 30, fontSize: 20, color: '#309345' }}>
            <small>
              <i>
                <span style={{ textDecoration: 'underline', marginRight: 5 }}>Lưu ý:</span>
                Quý khách vui lòng Click vào nút ĐẶT HÀNG để gửi đơn hàng cho Trạm Xanh. Nhân viên công ty sẽ liên hệ
                lại để chốt đơn, xác nhận phương thức thanh toán và vận chuyển.
              </i>
            </small>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stationList: state.stationReducer.stations,
  accountInformation: state.accountReducer.accountInformation,
  total_amount: state.cartReducer.total_amount,
  payment_amount: state.cartReducer.payment_amount,
  vouchers: state.cartReducer.vouchers,
  note: state.cartReducer.note,
  shipping_address: state.cartReducer.shipping_address,
  allProducts: state.productReducer.allProducts,
  gift_code: state.cartReducer.gift_code,
  gift_product: state.cartReducer.gift_product,
});

const mapDispatchToProps = (dispatch) => ({
  addItemToCart: (payload) => dispatch(cartActions.addItemToCart(payload)),
  getItemsInCart: () => dispatch(cartActions.getItemsInCart()),
  removeFromCart: (code) => dispatch(cartActions.deleteItemInCart(code)),
  getVouchers: () => dispatch(cartActions.getVouchers()),
  applyVoucher: (payload) => dispatch(cartActions.applyVoucher(payload)),
  addNoteForCart: (payload) => dispatch(cartActions.addNoteForCart(payload)),
  checkoutCart: (meta) => dispatch(cartActions.checkoutCart(meta)),
  updateAccountInfo: (payload) => dispatch(accountActions.updateAccountInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Order));
