function isBrowserWindow() {
  return typeof window !== 'undefined';
}

function getBrowserWindow() {
  return isBrowserWindow() ? window : false;
}

function getWindowWidth() {
  return isBrowserWindow() ? window.innerWidth : 1200;
}

function getUrlString(location) {
  const window = getBrowserWindow();
  if (window) {
    return window.location.href;
  }

  if (location && location.pathname) {
    return `${process.env.RAZZLE_TX_DOMAIN}${location.pathname}${location.search || ''}`;
  }

  return process.env.RAZZLE_TX_DOMAIN;
}

export { getBrowserWindow, isBrowserWindow, getWindowWidth, getUrlString };
