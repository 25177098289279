// import { Range } from 'rc-slider';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
// import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import '../category/tree.css';
// import { actions as productActions} from '../../redux/ProductRedux';
import { COLORS } from '../../constants/colors';
import { actions as categoryActions } from '../../redux/CategoriesRedux';
import { actions as searchActions } from '../../redux/SearchRedux';
import { getUrlString } from '../../utils/browserWindow';
import ProductListing from './product-listing';
import Supplier from './supplier';
import CategoryTree from './tree';

class SearchPage extends Component {
  state = {
    layoutColumns: 3,
    searchText: '',
    categoryCode: '',
    supplierCode: '',
    searchTextDraft: '',
    resetCatTree: false,
    isMobileFilterOpened: false,
    curPage: 1,
  };

  componentDidMount = () => {
    const urlString = getUrlString(this.props.location);
    const url = new URL(urlString);
    const searchText = url.searchParams.get('s') || '';
    const supplierCode = url.searchParams.get('supplier') ? url.searchParams.get('supplier') : 'all';
    const categoryCode = url.searchParams.get('category') ? url.searchParams.get('category') : 'all';
    this.setState({ searchText, searchTextDraft: searchText, supplierCode, categoryCode });

    this.props.getCategories();
  };

  static getDerivedStateFromProps = (props, state) => {
    // if(state.searchText){
    props.getProductsBySearchIfNeed({
      page: state.curPage,
      pageSize: 16,
      searchText: state.searchText,
      categoryCode: state.categoryCode,
      supplierCode: state.supplierCode,
    });
    // }

    return null;
  };

  openFilter = () => {
    document.querySelector('.collection-filter').style = 'left: -15px';
    this.setState({ isMobileFilterOpened: true });
  };

  closeFilter = () => {
    if (this.state.isMobileFilterOpened) {
      document.querySelector('.collection-filter').style = 'left: -370px';
      this.setState({ isMobileFilterOpened: false });
    }
  };

  onSelectCategory = (props) => {
    const categoryCodeTree = props.key.split('/');
    const categoryCode = categoryCodeTree[categoryCodeTree.length - 1];
    if (this.state.supplierCode) {
      this.props.history.push(
        `/search?s=${this.state.searchText}&category=${categoryCode}&supplier=${this.state.supplierCode}`,
      );
      this.setState({ categoryCode, resetCatTree: false });
    } else {
      if (categoryCode === 'all') {
        this.props.history.push(`/search?s=${this.state.searchText}`);
        this.setState({ categoryCode: 'all', resetCatTree: false });
      } else {
        this.props.history.push(`/search?s=${this.state.searchText}&category=${categoryCode}`);
        this.setState({ categoryCode, resetCatTree: false });
      }
    }
    this.closeFilter();
  };

  onSelectSupplier = (supplierCode) => {
    if (this.state.categoryCode) {
      this.props.history.push(
        `/search?s=${this.state.searchText}&category=${this.state.categoryCode}&supplier=${supplierCode}`,
      );
      this.setState({ supplierCode });
    } else {
      if (supplierCode === 'all') {
        this.props.history.push(`/search?s=${this.state.searchText}`);
        this.setState({ supplierCode: 'all' });
      } else {
        this.props.history.push(`/search?s=${this.state.searchText}&supplier=${supplierCode}`);
        this.setState({ supplierCode });
      }
    }

    this.closeFilter();
  };

  handlePageClick = (data) => {
    this.setState({ curPage: data.selected + 1 });
  };

  render() {
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>Trạm Xanh | Danh sách sản phẩm</title>
          <meta name="description" content="Trạm Xanh - Vật tư nông nghiệp" />
        </Helmet>
        {/*SEO Support End */}

        {/* <Breadcrumb title={'Collection'} /> */}

        <section className="section-b-space">
          <div className="collection-wrapper" style={{ marginBottom: '70px' }}>
            <div className="container">
              <div className="row">
                <div className="col-xl-3">
                  <form>
                    <div className="form-group" style={{ display: 'flex', flexFlow: 'not-wrap' }}>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Nhập mã hoặc tên sản phẩm"
                        onChange={(e) => this.setState({ searchTextDraft: e.target.value })}
                        value={this.state.searchTextDraft}
                        style={{ display: 'inline-block', borderRadius: '4px 0px 0px 4px' }}
                      />
                      <button
                        type="submit"
                        className="btn-primary"
                        style={{
                          padding: '14px 28px',
                          border: 'none',
                          backgroundColor: COLORS.primary,
                          display: 'inline-block',
                          borderRadius: '0px 4px 4px 0px',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            searchText: this.state.searchTextDraft,
                            categoryCode: '',
                            supplierCode: '',
                            resetCatTree: true,
                          });
                          this.props.history.push(`/search?s=${this.state.searchTextDraft}`);
                        }}>
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 collection-filter">
                  <StickyBox offsetTop={20} offsetBottom={20} style={{ padding: '0 20px' }}>
                    <div className="font-category-tree-header" style={{ marginBottom: '15px' }}>
                      Danh mục sản phẩm
                    </div>
                    <div>
                      <CategoryTree
                        onSelect={this.onSelectCategory}
                        match={this.props.match}
                        resetCatTree={this.state.resetCatTree}
                      />
                    </div>

                    <div className="font-category-tree-header" style={{ marginBottom: '10px', marginTop: '20px' }}>
                      Theo nhà cung cấp
                    </div>
                    <Supplier onSelectSupplier={this.onSelectSupplier} selectedSupplier={this.state.supplierCode} />
                    <a className="close-sidebar-filter" onClick={this.closeFilter} style={{ top: '-40px' }}>
                      <i className="fa fa-close fa-2x" aria-hidden="true" style={{ fontSize: 30 }} />
                    </a>
                  </StickyBox>
                </div>
                <div className="collection-content col">
                  <div className="page-main-content ">
                    <div className="">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="top-banner-wrapper">
                            <div className="font-category-tree-header" style={{ marginBottom: '15px' }}>
                              <h1>Kết quả tìm kiếm</h1>
                            </div>
                          </div>
                          <div className="collection-product-wrapper">
                            <div className="product-top-filter">
                              <div className="container-fluid p-0">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="filter-main-btn">
                                      <span onClick={this.openFilter} className="filter-btn btn btn-theme">
                                        <i className="fa fa-filter" aria-hidden="true" /> Bộ lọc
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="row">
                                  <div className="col-12">
                                    <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)} />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <ProductListing
                              colSize={this.state.layoutColumns}
                              products={this.props.products}
                              handlePageClick={this.handlePageClick}
                              meta={this.props.meta}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  products: state.searchReducer.products.list,
  meta: state.searchReducer.products.meta,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: () => {
    dispatch(categoryActions.getCategories());
  },
  getProductsBySearch: (parmas) => {
    dispatch(searchActions.getProducts(parmas));
  },
  getProductsBySearchIfNeed: (parmas) => {
    dispatch(searchActions.getProductsIfNeed(parmas));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchPage));
